import TableComp from "../../components/ui/Dashboard/TableComp";
import Navbar from "../../components/ui/Navbar";
import PagesTemp from "../PagesTemp/PagesTemp";
import styles from "./order.module.css";
import Loader from "../../components/ui/Loader/Loader";
import { useState, useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import { withStyles } from "@mui/styles";
import {
	ordersTable,
	searchOrder,
	setStatus as setStatusAPI,
} from "../../utils/api/utilAPI";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AssignRider from "../../components/form/Orders/AssignRider";
import { statusMap, statusColor } from "../../utils/constants";
import { Outlet, useLocation } from "react-router-dom";
import default_pfp from "../../assets/default_pfp.png";
import Loading_2 from "../../assets/Loading_2.gif";
import { addressPrinter, getFullName, orderRow } from "../../utils/functions";
import StarCount from "../../components/ui/StarCount/StarCount";
import { socket } from "../../utils/socket/socket";
import { Button } from "@mui/material";

const Orders = (props) => {
	const navigate = useNavigate();
	const location = useLocation();

	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	const selector = useSelector((state) => state.authReducer.user);
	const pageSelector = useSelector((state) => state.pageReducer.pageSize);

	// const [totalOrders, setTotalOrders] = useState(0);
	// const [isFirst, setIsFirst] = useState(true);

	const [orderSearch, setOrderSearch] = useState("");
	const [filter, setFilter] = useState("Status: All");

	const [page, setPage] = useState(1);
	const [pageLimit, setPageLimit] = useState(15);
	const [status, setStatus] = useState(0);
	const [totalPages, setTotalPages] = useState(1);

	const [tableData, setTableData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const [openAssign, setOpenAssign] = useState(false);
	const [riderOrder, setRiderOrder] = useState(null);

	const [socketAuth, setSocketAuth] = useState(false);

	const [loadedImages, setLoadedImages] = useState([]);

	const handleImageLoad = (index) => {
		setLoadedImages((prevLoadedImages) => [...prevLoadedImages, index]);
	};

	const makeTable = (data, totalCount) => {
		setTotalPages(Math.ceil(totalCount / pageLimit) || 1);
		let newTable = [];
		data.forEach((item, index) => {
			newTable.push(orderRow(item));
		});
		setTableData(newTable);
	};

	const handleChange = () => {
		searchOrder({ orderId: orderSearch }).then((res) => {
			if (res && res?.status != 401) {
				makeTable(res?.data?.data);
			} else {
				localStorage.removeItem("userData");
			}
		});
	};

	const setOrderStatus = (A, B) => {
		// only being used to change between 7 and 8. no one other than Admin sees this change...
		setStatusAPI({ orderId: A, orderStatus: B }).then((res) => {
			const updatedTable = tableData.map((oldObj) => {
				return oldObj.orderID !== res?.data?.data?.orderId
					? oldObj
					: orderRow(res?.data?.data);
			});
			setTableData(updatedTable);
		});
	};

	useEffect(() => {
		console.log("SOCKET TURNED ON");

		function onSocketAuth() {
			setSocketAuth(true);
		}

		function onSocketOrders() {
			fetchOrders();
			console.log("SOCKET TRIGGERED");
		}

		// socket.on("auth", onSocketAuth);
		socket.on("ordersUpdate", onSocketOrders);

		return () => {
			// socket.off("auth", onSocketAuth);
			socket.off("ordersUpdate", onSocketOrders);
		};
	}, []);

	useEffect(() => {
		if (!location.pathname.includes("order_detail")) {
			setIsLoading(true);
			fetchOrders();
		}
	}, [page, pageLimit, status]);

	const fetchOrders = () => {
		ordersTable({
			page,
			pageLimit,
			status,
		})
			.then((res) => {
				if (res && res?.status != 401) {
					makeTable(res?.data?.data?.orderData, res?.data?.data?.totalOrders);
				} else {
					localStorage.removeItem("userData");
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const labels = [
		"Order id",
		// "Service Type",
		"Items/Weight",
		"Amount",
		"Date Booked",
		"Pick-up Date & Time",
		"Delivery Date & Time",
		"Client name",
		"Contact Number",
		"Assigned Rider",
		"Status",
	];

	return location.pathname === "/orders" ? (
		isLoading ? (
			<div
				style={{
					height: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<div style={{ height: "10%", aspectRatio: "1" }}>
					<Loader />
				</div>
			</div>
		) : (
			<div className={styles.fullPage}>
				<div className={styles.card}>
					<div className={styles.header}>
						<>Orders</>
						<input
							className={styles.orderInput}
							type="text"
							placeholder="Search by name and Order ID"
							value={orderSearch}
							onKeyUp={(e) => {
								if (e.key === "Enter") {
									if (orderSearch !== "") handleChange();
									else fetchOrders();
								}
							}}
							onChange={(e) => {
								setOrderSearch(e.target.value);
							}}
						/>
						<button
							className={styles.searchButton}
							onClick={() => {
								if (orderSearch !== "") handleChange();
								else fetchOrders();
							}}
						>
							Search
						</button>
						<select
							value={status}
							onChange={(e) => setStatus(e.target.value)}
							className={styles.dropdown}
							style={{ color: `${statusColor[status]}` }}
						>
							{statusMap.map((item, index) => {
								if (index != 2) {
									return (
										<option
											style={{ color: `${statusColor[index]}` }}
											key={index}
											value={index}
										>
											{item}
										</option>
									);
								}
							})}
						</select>
					</div>
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								{labels.map((label, index) => (
									<TableCell
										key={index}
										style={{
											backgroundColor: "#F2F2F2",
											fontFamily: "Inter Tight",
											fontWeight: 500,
											fontSize: pageSelector ? "0.675rem" : "0.55rem",
											lineHeight: pageSelector ? "20px" : "10px",
											color: "#848484",
											border: "1px solid #CCCCCC",
										}}
									>
										{label}
									</TableCell>
								))}
							</TableHead>
							<TableBody>
								{tableData?.map((row, index) => (
									<TableRow
										key={index}
										hover={true}
										sx={{
											cursor: "pointer",
											".MuiTableCell-root": {
												fontFamily: "Inter Tight",
												fontWeight: 400,
												fontSize: pageSelector ? "0.675rem" : "0.55rem",
												lineHeight: pageSelector ? "20px" : "10px",
												color: "#1C1C1C",
												border: "1px solid #F2F2F2",
											},
										}}
										onClick={() => {
											navigate(`/orders/order_detail/${row.orderID}`);
										}}
									>
										<TableCell sx={{ width: "12%" }}>{row.orderID}</TableCell>
										{/* <TableCell>
											{row.serviceType === "laundry"
												? "Laundry"
												: "Dry Cleaning"}
										</TableCell> */}
										<TableCell>{row.itemsWeight}</TableCell>
										<TableCell>{row.amount}</TableCell>
										<TableCell>{row.dateBooked}</TableCell>
										<TableCell>{row.pickUpDate}</TableCell>
										<TableCell>{row.deliveryDate}</TableCell>
										<TableCell>{row.client}</TableCell>
										<TableCell>{row.contact}</TableCell>
										<TableCell
											onClick={(e) => {
												e.stopPropagation();
												if (
													typeof row.assignRider === "boolean" &&
													row.assignRider
												) {
													setRiderOrder({
														orderID: row.orderID,
														orderStatus: row.orderStatus,
													});
													setOpenAssign(true);
												}
											}}
										>
											{typeof row.assignRider === "object" ? (
												<div className={styles.rider}>
													<div className={styles.riderImage}>
														<img
															style={{
																borderRadius: "50%",
																width: "100%",
																height: "100%",
																objectFit: "cover",
															}}
															src={
																loadedImages.includes(index)
																	? row.assignRider.profileImage
																		? row.assignRider.profileImage
																		: default_pfp
																	: Loading_2
															}
															alt="Rider Profile Picture"
															onLoad={() => {
																handleImageLoad(index);
															}}
														/>
													</div>
													{getFullName(row.assignRider)}
												</div>
											) : (
												<div
													style={{
														color: row.assignRider ? "#0993cf" : "#848484",
														textDecoration: row.assignRider
															? "underline"
															: "none",
													}}
												>
													{row.assignRider || row.orderStatus === 7
														? "Assign Rider"
														: "Deleted Rider"}
												</div>
											)}
										</TableCell>
										{row.orderStatus !== 7 && row.orderStatus !== 8 ? (
											<TableCell>
												<span
													style={{ color: `${statusColor[row.orderStatus]}` }}
												>
													{statusMap[row.orderStatus]}
												</span>
											</TableCell>
										) : (
											<TableCell
												onClick={(e) => {
													e.stopPropagation();
												}}
											>
												<select
													className={styles.dropdownStatus}
													style={{ color: `${statusColor[row.orderStatus]}` }}
													value={row.orderStatus}
													onChange={(e) =>
														setOrderStatus(row.orderID, e.target.value)
													}
												>
													<option
														value={7}
														style={{ color: `${statusColor[7]}` }}
													>
														{statusMap[7]}
													</option>
													<option
														value={8}
														style={{ color: `${statusColor[8]}` }}
													>
														{statusMap[8]}
													</option>
												</select>
											</TableCell>
										)}
										{/* <TableCell>
											<StarCount count={row.orderRating} />
										</TableCell> */}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<div className={styles.pagination_container}>
						<Select
							IconComponent={KeyboardArrowDownIcon}
							displayEmpty
							value={pageLimit}
							onChange={(e) => {
								setPage(1);
								setPageLimit(e.target.value);
							}}
							sx={{
								width: "4rem",
								maxHeight: pageSelector ? "2.5rem" : "2rem",
								borderRadius: "8px",

								fontFamily: "Inter Tight",
								fontWeight: "500",
								fontSize: "0.675rem",
								lineHeight: pageSelector ? "20px" : "10px",
								color: "#848484",
								".MuiInputBase-input": { paddingRight: "0" },
								".MuiSelect-icon": { color: "#0993cf" },
							}}
						>
							<MenuItem value={12}>12</MenuItem>
							<MenuItem value={15}>15</MenuItem>
							<MenuItem value={20}>20</MenuItem>
						</Select>
						<Pagination
							sx={{
								fontFamily: "Inter Tight",
								fontWeight: "500",
								fontSize: pageSelector ? "0.675rem" : "0.55rem",
								lineHeight: pageSelector ? "20px" : "10px",
								color: "#848484",
							}}
							page={page}
							onChange={(e, p) => {
								setPage(p);
							}}
							count={totalPages}
							variant="outlined"
							shape="rounded"
						/>
					</div>
					<AssignRider
						orderDetails={riderOrder}
						modal={openAssign}
						setModal={setOpenAssign}
						handleFetchData={fetchOrders}
					/>
					{/* <Outlet/> */}
				</div>
			</div>
		)
	) : (
		<Outlet />
	);
};
export default Orders;
