import React, { useState, useEffect } from "react";
import { Modal, Backdrop, Fade, Button, Radio } from "@mui/material";
import styles from "./servicesmodal.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { addServiceCategory } from "../../../utils/api/utilAPI";
import { removeExtraSpaces } from "../../../utils/functions";

const AddServiceCategory = (props) => {
	const [name, setName] = useState("");
	const [slider, setSlider] = useState("others"); // dryCleaning : others

	const handleNameSubmit = () => {
		addServiceCategory({
			categoryName: removeExtraSpaces(name),
			// serviceType: slider,
			serviceType: "dryCleaning",
		}).finally(() => {
			props.handleFetchData();
		});
		handleClose();
	};

	const handleClose = () => {
		props.setModal(false);
	};

	return (
		<div>
			<Modal
				open={props.modal}
				onClose={handleClose}
				closeAfterTransition
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Fade in={props.modal}>
					<div className={styles.fullModal}>
						<div className={styles.header}>
							<div>Add Service Category</div>
							<CloseRoundedIcon
								sx={{ cursor: "pointer" }}
								onClick={handleClose}
							/>
						</div>
						<div className={styles.body}>
							<div className={styles.bodyFirst}>Name</div>
							<input
								className={styles.inputBar}
								type="text"
								placeholder="Enter service name"
								value={name}
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
							{/* <div
								style={{
									marginTop: "4%",
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									gap: "2%",
								}}
							>
								<button
									className={styles.button}
									style={{
										backgroundColor: `${
											slider === "dryCleaning" ? "#0993cf" : "#FFFFFF"
										}`,
										color: `${
											slider === "dryCleaning" ? "#ffffff" : "#848484"
										}`,
										border: `${
											slider === "dryCleaning" ? "none" : "1px solid #cccccc"
										}`,
									}}
									onClick={() => setSlider("dryCleaning")}
								>
									Laundry/Dry Cleaning
								</button>
								<button
									className={styles.button}
									style={{
										backgroundColor: `${
											slider === "dryCleaning" ? "#FFFFFF" : "#0993cf"
										}`,
										color: `${
											slider === "dryCleaning" ? "#848484" : "#ffffff"
										}`,
										border: `${
											slider === "dryCleaning" ? "1px solid #cccccc" : "none"
										}`,
									}}
									onClick={() => setSlider("others")}
								>
									Others
								</button>
							</div> */}
							<div
								style={{
									marginTop: "4%",
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									gap: "2%",
								}}
							>
								<button
									className={styles.button}
									style={{
										backgroundColor: "#FFFFFF",
										color: "#848484",
										border: "1px solid #cccccc",
									}}
									onClick={handleClose}
								>
									Cancel
								</button>
								<button
									className={styles.button}
									style={{
										backgroundColor: "#0993cf",
										color: "#ffffff",
										border: "none",
									}}
									onClick={handleNameSubmit}
								>
									Save category
								</button>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		</div>
	);
};

export default AddServiceCategory;
