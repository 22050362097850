import React, { useState } from "react";
import { Modal, Fade } from "@mui/material";
import styles from "./servicesmodal.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { addSubCategory } from "../../../utils/api/utilAPI";
import { removeExtraSpaces } from "../../../utils/functions";

const AddSubCategory = (props) => {
	const [name, setName] = useState("");
	const [price, setPrice] = useState(0);

	const handleNameSubmit = () => {
		addSubCategory({
			serviceName: props.selectCategory.serviceType,
			// serviceName: "others",
			categoryId: props.selectCategory.categoryId,
			categoryName: props.selectCategory.categoryName,
			clothType: removeExtraSpaces(name),
			clothPrice: price,
		}).finally(() => {
			props.handleFetchData();
			props.handleFetchSubData();
		});
		handleClose();
	};

	const handleClose = () => {
		props.setModal(false);
	};

	return (
		<div>
			<Modal
				open={props.modal}
				onClose={handleClose}
				closeAfterTransition
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Fade in={props.modal}>
					<div className={styles.fullModal}>
						<div className={styles.header}>
							<div>Add Sub-Category</div>
							<CloseRoundedIcon
								sx={{ cursor: "pointer" }}
								onClick={handleClose}
							/>
						</div>
						<div className={styles.body}>
							<div className={styles.bodyFirst}>Name</div>
							<input
								className={styles.inputBar}
								type="text"
								placeholder="Enter sub-category name"
								value={name}
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
							{props.selectCategory.serviceType !== "laundry" && (
								<>
									<div className={styles.bodyFirst}>Price</div>
									<input
										className={styles.inputBar}
										type="text"
										placeholder="Enter sub-category price for dry cleaning"
										value={price}
										onChange={(e) => {
											setPrice(e.target.value);
										}}
									/>
								</>
							)}
							<div
								style={{
									marginTop: "4%",
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									gap: "2%",
								}}
							>
								<button
									className={styles.button}
									style={{
										backgroundColor: "#FFFFFF",
										color: "#848484",
										border: "1px solid #cccccc",
									}}
									onClick={handleClose}
								>
									Cancel
								</button>
								<button
									className={styles.button}
									style={{
										backgroundColor: "#0993cf",
										color: "#ffffff",
										border: "none",
									}}
									onClick={handleNameSubmit}
								>
									Save sub-category
								</button>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		</div>
	);
};

export default AddSubCategory;
