import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./welcome.module.css";
import WelcomeTemplate from "./WelcomeTemplate";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	FORGOT_PASSWORD_PATH,
	LANDING_PATH,
	SIGNUP_PATH,
} from "../../utils/constants";
import { loginUser, forgotPassword } from "../../utils/api/authAPI";
import {
	loginRequest,
	loginSuccess,
	updateProfileData,
	sessionTimeout,
	logoutSuccess,
} from "../../utils/redux/features/authSlice";
import { logInFirebase } from "../../utils/firestore/firebase-config";
import Loader from "../../components/ui/Loader/Loader";

const WelcomeComp = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [isError, setIsError] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const selector = useSelector((state) => state.authReducer.user);
	const pageSelector = useSelector((state) => state.pageReducer.pageSize);
	const dispatch = useDispatch();

	const handleEmailChange = (e) => {
		const newEmail = e.target.value;
		setEmail(newEmail);

		// Validate email format
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (newEmail !== "") setIsEmailValid(emailRegex.test(newEmail));
		else setIsEmailValid(true);
	};

	const togglePasswordVisibility = () => {
		setShowPassword((curr) => !curr);
	};

	const toastHandler = (message) => {
		setIsError(true);

		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

		setTimeout(() => {
			setIsError(false);
		}, 3000);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setSubmitLoading(true);

		if (email === "" || !isEmailValid) {
			toastHandler("Invalid Email");
			setSubmitLoading(false);
			return;
		}
		if (password === "") {
			toastHandler("Please enter password");
			setSubmitLoading(false);
			return;
		}
		loginUser({
			email,
			password,
			device_token: localStorage.getItem("FIREBASE_TOKEN"),
		})
			.then((res) => {
				if (res?.status === 200) {
					dispatch(loginSuccess(res?.data?.data));
					localStorage.setItem(
						"userData",
						JSON.stringify({
							email: res.data?.data?.email?.mail,
							accessToken: res.data?.data?.accessToken,
							firstname: res.data?.data?.firstname,
							lastname: res.data?.data?.lastname,
							profileImage: res.data?.data?.profileImage,
							userType: res?.data?.data?.userType,
						})
					);
				} else {
					// setIsError(true);

					// toast.error("Incorrect email or password", {
					// 	position: toast.POSITION.TOP_RIGHT,
					// });

					// setTimeout(() => {
					// 	setIsError(false);
					// }, 3000);

					// Your account has been blocked, please contact super admin for support! UPDATE ERROR MESSAGE

					if (email !== "" && isEmailValid && password !== "") {
						toastHandler("Incorrect email or password");
						setSubmitLoading(false);
						return;
					}
				}
			})
			.finally(() => {
				setSubmitLoading(false);
			});
	};

	return (
		<WelcomeTemplate
			inputComp={
				<div className={styles.welcomeComp}>
					<div className={styles.topText}>
						<div className={styles.welcome}>Welcome to StarClean</div>
						<div className={styles.welcomeMessage}>
							Enter your email address and password to sign in to your account
						</div>
					</div>
					<form onSubmit={handleSubmit} className={styles.formMain}>
						<Box
							className={isError ? styles.shake : ""}
							sx={{
								borderRadius: "8px",
								height: pageSelector ? "3rem" : "2rem",
								fontSize: pageSelector ? "0.875rem" : "0.65rem",
							}}
						>
							<OutlinedInput
								sx={{
									borderRadius: "8px",
									height: pageSelector ? "3rem" : "2rem",
									fontSize: pageSelector ? "0.875rem" : "0.65rem",
								}}
								InputProps={{
									sx: {
										borderRadius: "8px",
									},
								}}
								fullWidth
								placeholder="Email address"
								error={!isEmailValid}
								id="email"
								value={email}
								onChange={handleEmailChange}
							/>
						</Box>
						{/* <input
							className={`${isError ? styles.shake : ""} ${
								styles.emailAddressInput
							}`}
							placeholder="Email address"
							value={email}
							onChange={handleEmailChange}
						/> */}
						<Box
							className={isError ? styles.shake : ""}
							sx={{
								borderRadius: "8px",
								height: pageSelector ? "3rem" : "2rem",
								fontSize: pageSelector ? "0.875rem" : "0.65rem",
							}}
						>
							<OutlinedInput
								sx={{
									borderRadius: "8px",
									height: pageSelector ? "3rem" : "2rem",
									fontSize: pageSelector ? "0.875rem" : "0.65rem",
								}}
								id="password"
								type={showPassword ? "text" : "password"}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={togglePasswordVisibility}
											onMouseDown={(e) => e.preventDefault()}
											edge="end"
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
								fullWidth
								placeholder="Password"
								value={password}
								onChange={(e) => {
									setPassword(e.target.value);
								}}
							/>
						</Box>
						<div
							className={styles.forgotPassword}
							style={{ color: isEmailValid ? "#0993cf" : "#848484" }}
							onClick={() => {
								if (email !== "" && isEmailValid) {
									navigate(FORGOT_PASSWORD_PATH, {
										state: {
											email: email,
										},
									});
								} else {
									toastHandler("Please enter an appropriate email first.");
								}
							}}
						>
							forgot password?
						</div>
						<Button
							type="submit"
							sx={{
								height: pageSelector ? "3rem" : "2rem",
								maxWidth: "100%",
								backgroundColor: "#0993CF",
								marginTop: "5%",
								textTransform: "none",
								fontSize: pageSelector ? "1.25rem" : "0.6rem",
								fontFamily: "Inter Tight, sans-serif",
								fontWeight: "700",
								lineHeight: "24px",
								borderRadius: "8px",
							}}
							variant="contained"
						>
							{submitLoading ? (
								<div
									style={{
										height: "100%",
										aspectRatio: "1",
									}}
								>
									<Loader />
								</div>
							) : (
								<>Sign in</>
							)}
						</Button>
					</form>
				</div>
			}
		/>
	);
};

export default WelcomeComp;
