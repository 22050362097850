import TableComp from "../../components/ui/Dashboard/TableComp";
import Navbar from "../../components/ui/Navbar";
import PagesTemp from "../PagesTemp/PagesTemp";
import styles from "./user.module.css";
import Loader from "../../components/ui/Loader/Loader";
import { useState, useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import { withStyles } from "@mui/styles";
import {
	fetchActive,
	fetchInactive,
	searchUser,
	userDetails,
} from "../../utils/api/utilAPI";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AssignRider from "../../components/form/Orders/AssignRider";
import { statusMap, statusColor } from "../../utils/constants";
import { Outlet, useLocation } from "react-router-dom";
import default_pfp from "../../assets/default_pfp.png";
import Loading_2 from "../../assets/Loading_2.gif";
import { addressPrinter, getFullName, orderRow } from "../../utils/functions";
import StarCount from "../../components/ui/StarCount/StarCount";
import { socket } from "../../utils/socket/socket";
import { Button } from "@mui/material";
import { Avatar, CircularProgress } from "@mui/material";

const User = (props) => {
	const navigate = useNavigate();
	const location = useLocation();

	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	const selector = useSelector((state) => state.authReducer.user);
	const pageSelector = useSelector((state) => state.pageReducer.pageSize);

	// const [totalOrders, setTotalOrders] = useState(0);
	// const [isFirst, setIsFirst] = useState(true);

	const [userSearch, setUserSearch] = useState("");
	const [filter, setFilter] = useState("Status: All");

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(15);
	const [status, setStatus] = useState("active");
	const [totalPages, setTotalPages] = useState(1);

	const [tableData, setTableData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const [openAssign, setOpenAssign] = useState(false);
	const [riderOrder, setRiderOrder] = useState(null);

	// const [socketAuth, setSocketAuth] = useState(false);

	const [loadedImages, setLoadedImages] = useState([]);

	const handleImageLoad = (index) => {
		setLoadedImages((prevLoadedImages) => [...prevLoadedImages, index]);
	};

	const makeTable = (data, totalCount) => {
		console.log({ data, totalCount });
		setTotalPages(Math.ceil(totalCount / pageSize) || 1);
		let newTable = [];
		data.map((item, index) => {
			newTable.push(item);
		});
		setTableData(newTable);
	};

	const handleSearch = () => {
		setIsLoading(true);
		setPage(1);
		setPageSize(15);
		if (status === "active") {
			if (userSearch === "") {
				activeUsers(1, 15);
			} else {
				searchUsersFunc();
			}
		} else {
			if (userSearch === "") {
				inactiveUsers(1, 15);
			} else {
				searchUsersFunc();
			}
		}
	};

	const handleStatus = (val) => {
		setIsLoading(true);
		setPage(1);
		setPageSize(15);
		setUserSearch("");

		if (val === "active") activeUsers(1, 15);
		else inactiveUsers(1, 15);

		// if (val === "all") {
		// 	allSubAdmins(1, 15);
		// } else {
		// 	statusSubAdmins(val, 1, 15);
		// }
	};

	// const handleBlock = (adminId, isBlocked) => {
	// 	setIsLoading(true);
	// 	blockAdmins({ adminId, isBlocked })
	// 		.then((res) => {
	// 			console.log("BLOCK ADMIN: ", res);
	// 		})
	// 		.finally(() => {
	// 			// just hit the same API endpoint again
	// 			if (adminSearch === "" && status === "all") {
	// 				allSubAdmins();
	// 			} else if (adminSearch !== "") {
	// 				searchUsersFunc();
	// 			} else {
	// 				statusSubAdmins();
	// 			}
	// 		});
	// };

	const activeUsers = (p = page, pS = pageSize) => {
		fetchActive({
			page: p,
			pageSize: pS,
		})
			.then((res) => {
				// if (res && res?.status != 401) {
				// 	makeTable(res?.data, res?.data?.length);
				// } else if (res?.status === 401) {
				// 	localStorage.removeItem("userData");
				// }
				if (res && res?.status !== 401) {
					makeTable(res?.data?.data, res?.data?.data?.length);
				}
				// userDetails({ userId: res?.data?.data[0]._id });
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const inactiveUsers = (p = page, pS = pageSize) => {
		fetchInactive({
			page: p,
			pageSize: pS,
		})
			.then((res) => {
				// if (res && res?.status != 401) {
				// 	makeTable(res?.data, res?.data?.length);
				// } else if (res?.status === 401) {
				// 	localStorage.removeItem("userData");
				// }
				if (res && res?.status !== 401) {
					makeTable(res?.data?.data, res?.data?.data?.length);
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const searchUsersFunc = (
		p = page,
		pS = pageSize,
		s = status === "inactive"
	) => {
		searchUser({
			name: userSearch,
			page: p,
			pageSize: pS,
			userStatus: s,
		})
			.then((res) => {
				makeTable(res?.data?.data);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		setIsLoading(true);
		if (status === "active") {
			if (userSearch === "") {
				activeUsers();
			} else searchUsersFunc();
		} else {
			if (userSearch === "") {
				inactiveUsers();
			} else searchUsersFunc();
		}

		// if (userSearch === "" && status === "active") {
		// 	activeUsers();
		// }
		// else if (userSearch !== "") {
		// 	searchUsersFunc();
		// } else {
		// 	statusSubAdmins();
		// }
	}, [page, pageSize, status]);

	const labels = ["Name", "Email", "Phone No.", "Bookings"];

	return location.pathname === "/users" ? (
		<div className={styles.fullPage}>
			<div className={styles.card}>
				<div className={styles.header}>
					<>Users</>
					<input
						className={styles.orderInput}
						type="text"
						placeholder="Search by name"
						value={userSearch}
						onKeyUp={(e) => {
							if (e.key === "Enter") {
								handleSearch();
							}
						}}
						onChange={(e) => {
							setUserSearch(e.target.value);
						}}
					/>
					<button
						className={styles.searchButton}
						onClick={() => {
							handleSearch();
						}}
					>
						Search
					</button>
					<select
						value={status}
						onChange={(e) => {
							setStatus(e.target.value);
							handleStatus(e.target.value);
						}}
						className={styles.dropdown}
						style={{ color: `${statusColor[status]}` }}
					>
						<option
							style={{ color: "#0993CF" }}
							key={"active"}
							value={"active"}
						>
							Active
						</option>
						<option
							style={{ color: "#E73F3F" }}
							key={"inactive"}
							value={"inactive"}
						>
							Inactive
						</option>
						{/* {statusMap.map((item, index) => {
								if (index != 2) {
									return (
										<option
											style={{ color: `${statusColor[index]}` }}
											key={index}
											value={index}
										>
											{item}
										</option>
									);
								}
							})} */}
					</select>
				</div>
				{isLoading ? (
					<div
						style={{
							width: "100%",
							height: "8rem",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<CircularProgress />
					</div>
				) : (
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								{labels.map((label, index) => (
									<TableCell
										key={index}
										style={{
											backgroundColor: "#F2F2F2",
											fontFamily: "Inter Tight",
											fontWeight: 500,
											fontSize: pageSelector ? "0.675rem" : "0.55rem",
											lineHeight: pageSelector ? "20px" : "10px",
											color: "#848484",
											border: "1px solid #CCCCCC",
										}}
									>
										{label}
									</TableCell>
								))}
							</TableHead>
							<TableBody>
								{tableData?.map((item, index) => (
									<TableRow
										key={index}
										hover={true}
										sx={{
											cursor: "pointer",
											".MuiTableCell-root": {
												fontFamily: "Inter Tight",
												fontWeight: 400,
												fontSize: pageSelector ? "0.675rem" : "0.55rem",
												lineHeight: pageSelector ? "20px" : "10px",
												color: "#1C1C1C",
												border: "1px solid #F2F2F2",
											},
										}}
										onClick={() => {
											navigate(`/users/user_detail/${item._id}`);
										}}
									>
										<TableCell
											sx={{
												width: "20%",
											}}
										>
											<div
												style={{
													display: "flex",
													alignItems: "center",
												}}
											>
												<Avatar
													src={item.profileImage}
													sx={{
														height: "28px",
														width: "28px",
														fontSize: "14px",
														marginRight: "0.5rem",
													}}
												>
													{`${item.firstname[0].toUpperCase()}${item.lastname[0].toUpperCase()}`}
												</Avatar>
												<span
													style={{ display: "block", textAlign: "center" }}
												>{`${item.firstname} ${item.lastname}`}</span>
											</div>
										</TableCell>
										<TableCell>{item.email.mail}</TableCell>
										<TableCell>
											<>{`${item.country_code}-${item.phone?.mobileNumber}`}</>
										</TableCell>
										<TableCell>{item.orderCount}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
				<div className={styles.pagination_container}>
					<Select
						IconComponent={KeyboardArrowDownIcon}
						displayEmpty
						value={pageSize}
						onChange={(e) => {
							setPage(1);
							setPageSize(e.target.value);
						}}
						sx={{
							width: "4rem",
							maxHeight: pageSelector ? "2.5rem" : "2rem",
							borderRadius: "8px",
							fontFamily: "Inter Tight",
							fontWeight: "500",
							fontSize: "0.675rem",
							lineHeight: pageSelector ? "20px" : "10px",
							color: "#848484",
							".MuiInputBase-input": { paddingRight: "0" },
							".MuiSelect-icon": { color: "#0993cf" },
						}}
					>
						<MenuItem value={12}>12</MenuItem>
						<MenuItem value={15}>15</MenuItem>
						<MenuItem value={20}>20</MenuItem>
					</Select>
					<Pagination
						sx={{
							fontFamily: "Inter Tight",
							fontWeight: "500",
							fontSize: pageSelector ? "0.675rem" : "0.55rem",
							lineHeight: pageSelector ? "20px" : "10px",
							color: "#848484",
						}}
						page={page}
						onChange={(e, p) => {
							setPage(p);
						}}
						count={totalPages}
						variant="outlined"
						shape="rounded"
					/>
				</div>
				{/* <AssignRider
						orderDetails={riderOrder}
						modal={openAssign}
						setModal={setOpenAssign}
						handleFetchData={activeUsers}
					/> */}
			</div>
		</div>
	) : (
		<Outlet />
	);
};
export default User;
