import React, { useState, useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styles from "./Main.module.css";
import profilePic from "../../assets/default_pfp.png";
import Navbar from "../../components/ui/Navbar";
import Profile from "../../components/form/Main/Profile";
import { useDispatch, useSelector } from "react-redux";
import { logoutSuccess } from "../../utils/redux/features/authSlice";
import TimeOutPop from "../../components/ui/Main/TimeOutPop";

const Main = (props) => {
	const [currURL, setCurrURL] = useState("");
	const location = useLocation();
	const selector = useSelector((state) => state.authReducer.user);
	const popUpSelector = useSelector((state) => state.popUpReducer.bool);
	const dispatch = useDispatch();

	const [popperOpen, setPopperOpen] = useState(false);

	const parentRef = useRef(null);
	const popperRef = useRef(null);

	const [timeoutPop, setTimeoutPop] = useState(popUpSelector);

	useEffect(() => setTimeoutPop(popUpSelector), [popUpSelector]);

	const handleLogout = () => {
		dispatch(logoutSuccess());
		localStorage.setItem("userData", null);
	};

	const handleToggle = () => {
		setPopperOpen((prev) => !prev);
	};

	const handleClickOutside = (event) => {
		if (
			parentRef.current &&
			!parentRef.current.contains(event.target) &&
			popperRef.current &&
			!popperRef.current.contains(event.target)
		) {
			setPopperOpen(false);
		}
	};

	const title = () => {
		if (currURL.slice(0, 21).includes("/orders/order_detail/")) {
			return <>Order Details</>;
		} else if (currURL.slice(0).includes("update")) return <>Riders</>;
		else if (currURL.slice(0).includes("service"))
			return <>Service Management</>;
		else if (currURL.slice(0).includes("users/user_detail"))
			return <>User Details</>;
		else return <>{currURL.slice(1, 2).toUpperCase() + currURL.slice(2)}</>;
	};

	useEffect(() => {
		setCurrURL(location.pathname);
	}, [location]);

	useEffect(() => {
		// console.log("timeoutPop: ", timeoutPop, "popUpSelector: ", popUpSelector);

		// setTimeoutPop(popUpSelector);

		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	return (
		<div className={styles.horizon}>
			<div className={styles.navbar}>
				<Navbar />
			</div>
			<div className={styles.main_page}>
				<div className={styles.header_main}>
					<div className={styles.header_title}>{title()}</div>
					<div className={styles.userSpace}>
						<div
							ref={parentRef}
							style={{ cursor: "pointer" }}
							className={styles.header_profile}
							onClick={handleToggle}
						>
							<>{selector.firstname + " " + selector.lastname}</>
							<div
								style={{
									height: "100%",
								}}
							>
								<img
									style={{
										width: "100%",
										height: "100%",
										objectFit: "cover", // Maintain aspect ratio and cover the container
										borderRadius: "50%",
									}}
									src={
										selector.profileImage !== ""
											? selector.profileImage
											: profilePic
									}
									alt="Profile Picture"
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.outletContainer}>
					<Outlet />
				</div>
				<Profile
					ref={popperRef}
					open={popperOpen}
					setOpen={setPopperOpen}
					anchorEl={parentRef.current}
				/>
			</div>
			{timeoutPop && <TimeOutPop modal={timeoutPop} setModal={setTimeoutPop} />}
		</div>
	);
};
export default Main;
