import instance from "./axiosConfig";
export async function wrappedFetch(url, method, data, headers) {
	let config = {
		url,
		method,
		headers: {},
		data,
	};
	if (headers) config.headers = headers;
	try {
		let x = await instance(config);
		console.log("RESPONSE IN WRAPPED FETCH: ", x);
		return x;
	} catch (err) {
		console.log("ERROR IN WRAPPED FETCH", err);
		throw err;
		// throw new Error("Error caught in urlfuncs");
	}
}
export async function wrappedGet(url, method, params) {
	let config = {
		url,
		method,
		headers: {},
		params,
	};

	try {
		return await instance(config);
	} catch (err) {
		throw new Error("Error caught in urlfuncs");
	}
}
