import { useState, useEffect } from "react";
import Navbar from "../../components/ui/Navbar";
import PagesTemp from "../PagesTemp/PagesTemp";
import styles from "./service.module.css";
import Loader from "../../components/ui/Loader/Loader";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import {
	getCategoryData,
	getSubCategoryData,
	editLaundryPrice,
	editSubCategory,
} from "../../utils/api/utilAPI";
import EditCategoryName from "../../components/form/Services/EditCategoryName";
import HideCategory from "../../components/form/Services/HideCategory";
import AddServiceCategory from "../../components/form/Services/AddServiceCategory";
import AddSubCategory from "../../components/form/Services/AddSubCategory";
import HideSubCategory from "../../components/form/Services/HideSubCategory";
import { Skeleton } from "@mui/material";
import NameEdit from "../../components/ui/Service/NameEdit";
import { isValidNumber, removeExtraSpaces } from "../../utils/functions";
import { toast, ToastContainer } from "react-toastify";

const Service = () => {
	const [loading, setLoading] = useState(true);
	const [subCatLoading, setSubCatLoading] = useState(false);

	const [editPrice, setEditPrice] = useState(false);
	const [tempPrice, setTempPrice] = useState(null);

	const [editName, setEditName] = useState("");
	const [editCategoryName, setEditCategoryName] = useState(null);
	const [openEditName, setOpenEditName] = useState(false);
	const [hideCategory, setHideCategory] = useState("");
	const [openHideCategory, setOpenHideCategory] = useState(false);
	const [openAddService, setOpenAddService] = useState(false);
	const [openAddSub, setOpenAddSub] = useState(false);
	const [hideSubCategory, setHideSubCategory] = useState(null); // {categoryName, subCategoryName}
	const [openHideSub, setOpenHideSub] = useState(false);

	// categories
	const [dryArr, setDryArr] = useState([]);
	const [otherArr, setOtherArr] = useState([]);
	const [laundryArr, setLaundryArr] = useState([]);

	const [showOtherArr, setShowOtherArr] = useState(false); // not used anymore
	// const [showCatArr, setShowCatArr] = useState(false); // the heck?
	const [showDryArr, setShowDryArr] = useState(false);
	const [showLaundryArr, setShowLaundryArr] = useState(false);

	const [laundryWeightPrice, setLaundryWeightPrice] = useState(0);

	const [subArr, setSubArr] = useState([]);
	const [newSubArr, setNewSubArr] = useState([]);
	const [laundrySubArr, setLaundrySubArr] = useState([]);

	const [subCatChangeLoad, setSubCatChangeLoad] = useState(false); // use when save is first triggered

	const [manageSelect, setManageSelect] = useState(null); // cannot have a service without any name

	const fetchServices = () => {
		setLoading(true);
		getCategoryData()
			.then((res) => {
				setDryArr(res?.data?.data.categoryData);
				setLaundryWeightPrice(res?.data?.data.laundryPrice);
				// setOtherArr(res?.data?.data.othersCategoryData);
				setLaundryArr(res?.data?.data.laundryCategoryData);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchSubcategory = () => {
		setSubCatLoading(true);
		setSubArr([]);
		setNewSubArr([]);
		getSubCategoryData({
			categoryName: manageSelect && manageSelect.categoryName,
			serviceType: manageSelect && manageSelect.serviceType,
		})
			.then((res) => {
				if (res?.data?.data?.categoryData) {
					const temp = res?.data?.data?.categoryData;
					temp.map((item, index) => {
						setSubArr((curr) => {
							return [
								...curr,
								{
									_id: item._id,
									clothType: item.clothType,
									clothPrice: item.clothPrice,
								},
							];
						});
						setNewSubArr((curr) => {
							return [
								...curr,
								{
									clothType: item.clothType,
									clothPrice: item.clothPrice,
								},
							];
						});
					});
				}
			})
			.finally(() => {
				setSubCatLoading(false);
			});
	};

	const toastHandler = (type) => {
		if (type === 1) {
			toast.error("Please ensure all prices are numeric!");
		} else if (type === 2) {
			toast.error("Rates can only be up to $1000.00.");
		}
	};

	const handleSubCatChange = () => {
		// do one more price to handle mexico prices! and that's it!
		setSubCatChangeLoad(true);
		let subCatChanges = [];
		newSubArr.map((item, index) => {
			if (!isValidNumber(newSubArr[index].clothPrice)) {
				toastHandler(1);
				return;
			}
			if (newSubArr[index].clothPrice > 1000) {
				toastHandler(2);
				return;
			}
			if (
				newSubArr[index].clothType != subArr[index].clothType ||
				newSubArr[index].clothPrice != subArr[index].clothPrice
			) {
				subCatChanges.push({
					_id: subArr[index]._id,
					newName: removeExtraSpaces(newSubArr[index].clothType),
					newPrice: newSubArr[index].clothPrice,
				});
			}
		});
		editSubCategory(subCatChanges).finally(() => {
			fetchSubcategory();
		});
	};

	useEffect(() => {
		fetchServices();
	}, []);

	useEffect(() => {
		fetchSubcategory();
	}, [manageSelect]);

	const handleStringChange = (index, newVal) => {
		const tempArr = [...newSubArr];
		tempArr[index].clothType = newVal;
		setNewSubArr(tempArr);
	};

	const handlePriceChange = (index, newVal) => {
		const tempArr = [...newSubArr];
		tempArr[index].clothPrice = newVal;
		setNewSubArr(tempArr);
	};

	return loading ? (
		<div
			style={{
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div style={{ height: "10%", aspectRatio: "1" }}>
				<Loader />
			</div>
		</div>
	) : (
		<div className={styles.backGround}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div className={styles.title}>Services</div>
				<div
					onClick={() => {
						if (!manageSelect) {
							setOpenAddService(true);
						}
					}}
					className={`${styles.addService} ${manageSelect && styles.greyed}`}
				>
					Add Service Category
				</div>
			</div>
			<div
				style={{
					height: "92%",
					display: "flex",
					flexDirection: "row",
					gap: "14px",
				}}
			>
				<div className={styles.services}>
					<div className={styles.card} style={{ marginBottom: "2%" }}>
						{editPrice ? (
							<>
								<div className={styles.laundryTitle}>
									<div
										className={`${styles.laundryFont} ${
											manageSelect && styles.greyed
										}`}
									>
										Laundry Service
									</div>
									<div
										style={{
											fontWeight: "500",
											fontSize: "14px",
											lineHeight: "20px",
											color: "#0993CF",
											textDecoration: "underline",
											cursor: "pointer",
										}}
										onClick={() => {
											editLaundryPrice({ pricePerPound: tempPrice });
											setEditPrice(false);
											fetchServices();
										}}
									>
										Save Price
									</div>
								</div>
								<div className={styles.priceContainer}>
									<div style={{ color: "#1C1C1C" }}>
										$
										<input
											className={styles.editPrice}
											value={tempPrice}
											onChange={(e) => setTempPrice(e.target.value)}
										/>
									</div>
									<div style={{ color: "#CCCCCC" }}>per pound</div>
								</div>
							</>
						) : (
							<>
								<div className={styles.laundryTitle}>
									<div
										className={`${styles.laundryFont} ${
											manageSelect && styles.greyed
										}`}
									>
										Laundry Service
									</div>
									<div
										className={`${styles.editButton} ${
											manageSelect && styles.greyed
										}`}
										onClick={() => {
											if (!manageSelect) {
												setTempPrice(laundryWeightPrice);
												setEditPrice(true);
												setShowLaundryArr(false);
											}
										}}
									>
										Edit Price
									</div>
								</div>
								<div
									className={`${styles.priceStyle} ${
										manageSelect && styles.greyed
									}`}
								>
									<div>{`$${laundryWeightPrice} Per Pound`}</div>
									<div
										className={`${styles.editButton} ${
											!manageSelect || manageSelect?.serviceType === "laundry"
												? null
												: styles.greyed
										}`}
										onClick={() => {
											setShowLaundryArr((curr) => !curr);
										}}
									>
										<KeyboardArrowDownIcon
											className={!showLaundryArr ? styles.rotated : ""}
										/>
									</div>
								</div>
							</>
						)}
						{showLaundryArr &&
							laundryArr.map((item, index) => {
								return (
									<div
										className={`${styles.categoryLabel} ${
											index !== laundryArr.length - 1 && styles.catBorderBottom
										}`}
										key={index}
									>
										<span
											className={
												manageSelect &&
												manageSelect.categoryName !== item.categoryName &&
												styles.greyed
											}
											style={{ width: "45%", wordWrap: "break-word" }}
										>
											{item.categoryName}
										</span>
										<div
											className={`${styles.options} ${
												manageSelect && styles.greyed
											}`}
										>
											{/* <div
												onClick={() => {
													// setEditName(item.categoryName);
													if (!manageSelect) {
														setEditCategoryName({
															categoryName: item.categoryName,
															categoryId: item._id,
															serviceType: item.serviceType,
														});
														setOpenEditName(true);
													}
												}}
											>
												Edit
											</div>
											<div
												onClick={() => {
													if (!manageSelect) {
														setHideCategory({
															categoryName: item.categoryName,
															categoryId: item._id,
															serviceType: item.serviceType,
														});
														setOpenHideCategory(true);
													}
												}}
											>
												Delete
											</div> */}
											<div
												onClick={() => {
													if (!manageSelect) {
														setManageSelect({
															categoryName: item.categoryName,
															categoryId: item._id,
															serviceType: item.serviceType,
														});
													}
												}}
											>
												Manage sub-category
											</div>
											<ArrowForwardRoundedIcon />
										</div>
									</div>
								);
							})}
					</div>
					{/* <div className={styles.card} style={{ marginBottom: "2%" }}>
						<div className={styles.laundryTitle}>
							<div
								className={`${styles.laundryFont} ${
									!manageSelect || manageSelect?.serviceType === "dryCleaning"
										? null
										: styles.greyed
								}`}
							>
								Dry Cleaning
							</div>
							<div
								className={`${styles.editButton} ${
									!manageSelect || manageSelect?.serviceType === "dryCleaning"
										? null
										: styles.greyed
								}`}
								onClick={() => {
									setShowCatArr((curr) => !curr);
									console.log("showCatArr: ", showCatArr);
									// if (!manageSelect) {
									// 	setTempPrice(laundryWeightPrice);
									// 	setEditPrice(true);
									// }
								}}
							>
								<KeyboardArrowDownIcon
									className={!showCatArr ? styles.rotated : ""}
								/>
							</div>
						</div>
						{showCatArr &&
							catArr.map((item, index) => {
								return (
									<div
										className={`${styles.categoryLabel} ${
											index !== catArr.length - 1 && styles.catBorderBottom
										}`}
										key={index}
									>
										<span
											className={
												manageSelect &&
												manageSelect.categoryName !== item.categoryName &&
												styles.greyed
											}
											style={{ width: "45%", wordWrap: "break-word" }}
										>
											{item.categoryName}
										</span>
										<div
											className={`${styles.options} ${
												manageSelect && styles.greyed
											}`}
										>
											<div
												onClick={() => {
													// setEditName(item.categoryName);
													if (!manageSelect) {
														setEditCategoryName({
															categoryName: item.categoryName,
															categoryId: item._id,
															serviceType: item.serviceType,
														});
														setOpenEditName(true);
													}
												}}
											>
												Edit
											</div>
											<div
												onClick={() => {
													if (!manageSelect) {
														setHideCategory({
															categoryName: item.categoryName,
															categoryId: item._id,
															serviceType: item.serviceType,
														});
														setOpenHideCategory(true);
													}
												}}
											>
												Delete
											</div>
											<div
												onClick={() => {
													if (!manageSelect) {
														setManageSelect({
															categoryName: item.categoryName,
															categoryId: item._id,
															serviceType: item.serviceType,
														});
													}
												}}
											>
												Manage sub-category
											</div>
											<ArrowForwardRoundedIcon />
										</div>
									</div>
								);
							})}
					</div> */}
					<div className={styles.card} style={{ marginBottom: "2%" }}>
						<div className={styles.laundryTitle}>
							<div
								className={`${styles.laundryFont} ${
									!manageSelect || manageSelect?.serviceType === "dryCleaning"
										? null
										: styles.greyed
								}`}
							>
								Dry Cleaning
							</div>
							<div
								className={`${styles.editButton} ${
									!manageSelect || manageSelect?.serviceType === "dryCleaning"
										? null
										: styles.greyed
								}`}
								onClick={() => {
									setShowDryArr((curr) => !curr);
									// if (!manageSelect) {
									// 	setTempPrice(laundryWeightPrice);
									// 	setEditPrice(true);
									// }
								}}
							>
								<KeyboardArrowDownIcon
									className={!showDryArr ? styles.rotated : ""}
								/>
							</div>
						</div>
						{showDryArr &&
							dryArr.map((item, index) => {
								return (
									<div
										className={`${styles.categoryLabel} ${
											index !== dryArr.length - 1 && styles.catBorderBottom
										}`}
										key={index}
									>
										<span
											className={
												manageSelect &&
												manageSelect.categoryName !== item.categoryName &&
												styles.greyed
											}
											style={{ width: "45%", wordWrap: "break-word" }}
										>
											{item.categoryName}
										</span>
										<div
											className={`${styles.options} ${
												manageSelect && styles.greyed
											}`}
										>
											<div
												onClick={() => {
													// setEditName(item.categoryName);
													if (!manageSelect) {
														setEditCategoryName({
															categoryName: item.categoryName,
															categoryId: item._id,
															serviceType: item.serviceType,
														});
														setOpenEditName(true);
													}
												}}
											>
												Edit
											</div>
											<div
												onClick={() => {
													if (!manageSelect) {
														setHideCategory({
															categoryName: item.categoryName,
															categoryId: item._id,
															serviceType: item.serviceType,
														});
														setOpenHideCategory(true);
													}
												}}
											>
												Delete
											</div>
											<div
												onClick={() => {
													if (!manageSelect) {
														setManageSelect({
															categoryName: item.categoryName,
															categoryId: item._id,
															serviceType: item.serviceType,
														});
													}
												}}
											>
												Manage sub-category
											</div>
											<ArrowForwardRoundedIcon />
										</div>
									</div>
								);
							})}
					</div>
					{/* <div
						style={{
							width: "stretch",
							overflowY: "auto",
							display: "flex",
							flexDirection: "column",
							gap: "1rem",
						}}
						className={styles.catArrList}
					>
						{catArr.map((item, index) => {
							if (!item.isHide) {
								return (
									<div key={index} className={styles.card}>
										<div className={styles.categoryLabel}>
											<span
												className={
													manageSelect &&
													manageSelect.categoryName !== item.categoryName &&
													styles.greyed
												}
												style={{ width: "45%", wordWrap: "break-word" }}
											>
												{item.categoryName}
											</span>
											<div
												className={`${styles.options} ${
													manageSelect && styles.greyed
												}`}
											>
												<div
													onClick={() => {
														// setEditName(item.categoryName);
														if (!manageSelect) {
															setEditCategoryName({
																categoryName: item.categoryName,
																categoryId: item._id,
															});
															setOpenEditName(true);
														}
													}}
												>
													Edit
												</div>
												<div
													onClick={() => {
														if (!manageSelect) {
															setHideCategory({
																categoryName: item.categoryName,
																categoryId: item._id,
															});
															setOpenHideCategory(true);
														}
													}}
												>
													Delete
												</div>
												<div
													onClick={() => {
														if (!manageSelect) {
															setManageSelect({
																categoryName: item.categoryName,
																categoryId: item._id,
															});
														}
													}}
												>
													Manage Dry Cleaning
												</div>
												<ArrowForwardRoundedIcon />
											</div>
										</div>
									</div>
								);
							}
						})}
					</div> */}
				</div>
				<div className={styles.card} style={{ width: "50%" }}>
					{!manageSelect ? (
						<div className={styles.emptySub}>
							<div
								style={{
									fontWeight: "500",
									fontSize: "18px",
									lineHeight: "28px",
									color: "#AEAEAE",
								}}
							>
								Sub-categories
							</div>
							<div className={styles.watermark}>
								<NoteAltOutlinedIcon
									sx={{
										width: "40%",
										height: "auto",
										"-webkit-transform": "scaleX(-1)",
										transform: "scaleX(-1)",
										color: "#F2F2F2",
									}}
								/>
								<div
									style={{
										width: "260px",
										textAlign: "center",
										fontWeight: "400",
										fontSize: "14px",
										lineHeight: "20px",
										color: "#1C1C1C",
									}}
								>
									Click on Manage Sub-category to manage or add your
									sub-categories
								</div>
							</div>
						</div>
					) : (
						<>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<div
									style={{
										fontWeight: "500",
										fontSize: "18px",
										lineHeight: "28px",
										color: "#1C1C1C",
										wordBreak: "break-word",
									}}
								>
									{`${
										manageSelect ? manageSelect.categoryName : ""
									} Sub-categories`}
								</div>
								<div
									style={{
										fontWeight: "500",
										fontSize: "14px",
										lineHeight: "28px",
										color: "#0993CF",
										textDecoration: "underline",
										cursor: "pointer",
									}}
									onClick={() => {
										setOpenAddSub(true);
									}}
								>
									Add new sub-category
								</div>
							</div>
							{subCatLoading ? (
								<div
									style={{
										height: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<div style={{ height: "10%", aspectRatio: "1" }}>
										<Loader />
									</div>
								</div>
							) : (
								<div className={styles.subCatMain}>
									<div className={styles.subCatContainer}>
										<div className={styles.subCatList}>
											{!newSubArr.length ? (
												<>No Sub-categories...</>
											) : (
												<>
													{newSubArr?.map((item, index) => {
														return (
															<div key={index} className={styles.subCat}>
																<div
																	style={{
																		width: "70%",
																		paddingLeft: "2%",
																	}}
																>
																	<NameEdit
																		index={index}
																		value={item.clothType}
																		handleStringChange={handleStringChange}
																	/>
																</div>
																<div className={styles.priceDel}>
																	{manageSelect.serviceType !== "laundry" && (
																		<>
																			$
																			<div
																				style={{
																					padding: "0 0 0 2%",
																					margin: "0 10% 0 0",
																					display: "flex",
																				}}
																			>
																				<input
																					className={styles.subCatInputPrice}
																					value={item.clothPrice}
																					onChange={(e) =>
																						handlePriceChange(
																							index,
																							e.target.value
																						)
																					}
																				/>
																			</div>
																		</>
																	)}
																	<div
																		style={{
																			cursor: "pointer",
																			textDecoration: "underline",
																		}}
																		onClick={() => {
																			setOpenHideSub(true);
																			setHideSubCategory({
																				categoryId: manageSelect.categoryId,
																				categoryName: manageSelect.categoryName,
																				subCategoryName: item.clothType,
																			});
																		}}
																	>
																		Delete
																	</div>
																</div>
																{/* {manageSelect.serviceType !== "laundry" && (
																	<div className={styles.priceDel}>
																		$
																		<div
																			style={{
																				padding: "0 0 0 2%",
																				margin: "0 10% 0 0",
																				display: "flex",
																			}}
																		>
																			<input
																				className={styles.subCatInputPrice}
																				value={item.clothPrice}
																				onChange={(e) =>
																					handlePriceChange(
																						index,
																						e.target.value
																					)
																				}
																			/>
																		</div>
																		<div
																			style={{
																				cursor: "pointer",
																				textDecoration: "underline",
																			}}
																			onClick={() => {
																				setOpenHideSub(true);
																				setHideSubCategory({
																					categoryId: manageSelect.categoryId,
																					categoryName:
																						manageSelect.categoryName,
																					subCategoryName: item.clothType,
																				});
																			}}
																		>
																			Delete
																		</div>
																	</div>
																)} */}
															</div>
														);
													})}
												</>
											)}
										</div>
									</div>
									<div
										style={{
											justifySelf: "flex-end",
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "center",
											gap: "2%",
										}}
									>
										<button
											style={{
												backgroundColor: "#FFFFFF",
												color: "#848484",
												border: "1px solid #cccccc",
											}}
											className={styles.button}
											onClick={() => setManageSelect(null)}
										>
											Cancel
										</button>
										<button
											style={{
												backgroundColor: "#0993cf",
												color: "#ffffff",
												border: "none",
											}}
											className={styles.button}
											onClick={() => handleSubCatChange()}
										>
											Save Changes
										</button>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</div>
			{openEditName && (
				<EditCategoryName
					oldCategory={editCategoryName}
					modal={openEditName}
					setModal={setOpenEditName}
					handleFetchData={fetchServices}
				/>
			)}
			{openAddService && (
				<AddServiceCategory
					modal={openAddService}
					setModal={setOpenAddService}
					handleFetchData={fetchServices}
				/>
			)}
			{openAddSub && (
				<AddSubCategory
					modal={openAddSub}
					setModal={setOpenAddSub}
					handleFetchData={fetchServices}
					handleFetchSubData={fetchSubcategory}
					selectCategory={manageSelect}
				/>
			)}
			{openHideCategory && (
				<HideCategory
					oldCategory={hideCategory}
					modal={openHideCategory}
					setModal={setOpenHideCategory}
					handleFetchData={fetchServices}
				/>
			)}
			{openHideSub && (
				<HideSubCategory
					data={hideSubCategory}
					modal={openHideSub}
					setModal={setOpenHideSub}
					handleFetchData={fetchServices}
					handleFetchSubData={fetchSubcategory}
				/>
			)}
		</div>
	);
};
export default Service;
