import styles from "./admin.module.css";
import Loader from "../../components/ui/Loader/Loader";
import { useState, useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import { withStyles } from "@mui/styles";
import {
	blockAdmins,
	fetchAdmins,
	searchAdmins,
	statusAdmins,
} from "../../utils/api/utilAPI";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import Add from "../../components/form/Admin/Add";
import { statusMap, statusColor } from "../../utils/constants"; // fix this!
import { Outlet, useLocation } from "react-router-dom";
import Loading_2 from "../../assets/Loading_2.gif";
import { Avatar, CircularProgress } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const Admin = (props) => {
	const navigate = useNavigate();
	const location = useLocation();

	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	const selector = useSelector((state) => state.authReducer.user);
	const pageSelector = useSelector((state) => state.pageReducer.pageSize);

	// const [totalOrders, setTotalOrders] = useState(0);
	// const [isFirst, setIsFirst] = useState(true);

	const [adminSearch, setAdminSearch] = useState("");
	const [filter, setFilter] = useState("Status: All");

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(15);
	const [status, setStatus] = useState("all");
	const [totalPages, setTotalPages] = useState(1);

	const [tableData, setTableData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [openAssign, setOpenAssign] = useState(false);
	const [riderOrder, setRiderOrder] = useState(null);

	// const [socketAuth, setSocketAuth] = useState(false);

	const [loadedImages, setLoadedImages] = useState([]);

	const [add, setAdd] = useState(false);

	useEffect(() => {
		const userData = JSON.parse(localStorage.getItem("userData"));
		console.log({ selector, userData });
	}, []);

	const handleImageLoad = (index) => {
		setLoadedImages((prevLoadedImages) => [...prevLoadedImages, index]);
	};

	const makeTable = (data, totalCount) => {
		console.log({ data, totalCount });
		setTotalPages(Math.ceil(totalCount / pageSize) || 1);
		let newTable = [];
		data.map((item, index) => {
			newTable.push(item);
		});
		setTableData(newTable);
	};

	const handleSearch = () => {
		setIsLoading(true);
		setStatus("all");
		setPage(1);
		setPageSize(15);
		if (adminSearch === "") {
			allSubAdmins(1, 15);
		} else {
			searchSubAdmins(1, 15);
		}
	};

	const handleStatus = (val) => {
		setIsLoading(true);
		setPage(1);
		setPageSize(15);
		setAdminSearch("");
		if (val === "all") {
			allSubAdmins(1, 15);
		} else {
			statusSubAdmins(val, 1, 15);
		}
	};

	const handleBlock = (adminId, isBlocked) => {
		setIsLoading(true);
		blockAdmins({ adminId, isBlocked })
			.then((res) => {
				console.log("BLOCK ADMIN: ", res);
			})
			.finally(() => {
				// just hit the same API endpoint again
				if (adminSearch === "" && status === "all") {
					allSubAdmins();
				} else if (adminSearch !== "") {
					searchSubAdmins();
				} else {
					statusSubAdmins();
				}
			});
	};

	// const setOrderStatus = (A, B) => {
	// 	// only being used to change between 7 and 8. no one other than Admin sees this change...
	// 	statusAdmins({ orderId: A, orderStatus: B }).then((res) => {
	// 		const updatedTable = tableData.map((oldObj) => {
	// 			return oldObj.orderID !== res?.data?.data?.orderId
	// 				? oldObj
	// 				: orderRow(res?.data?.data);
	// 		});
	// 		setTableData(updatedTable);
	// 	});
	// };

	// useEffect(() => {
	// 	console.log("SOCKET TURNED ON");

	// 	function onSocketAuth() {
	// 		setSocketAuth(true);
	// 	}

	// 	function onSocketOrders() {
	// 		fetchAdmins();
	// 		console.log("SOCKET TRIGGERED");
	// 	}

	// 	// socket.on("auth", onSocketAuth);
	// 	socket.on("ordersUpdate", onSocketOrders);

	// 	return () => {
	// 		// socket.off("auth", onSocketAuth);
	// 		socket.off("ordersUpdate", onSocketOrders);
	// 	};
	// }, []);

	useEffect(() => {
		// if (!location.pathname.includes("order_detail")) {
		// 	setIsLoading(true);
		// 	allSubAdmins();
		// }
		setIsLoading(true);
		if (adminSearch === "" && status === "all") {
			allSubAdmins();
		} else if (adminSearch !== "") {
			searchSubAdmins();
		} else {
			statusSubAdmins();
		}
	}, [page, pageSize]);

	const allSubAdmins = (p = page, pS = pageSize) => {
		fetchAdmins({
			page: p,
			pageSize: pS,
		})
			.then((res) => {
				// if (res && res?.status != 401) {
				// 	makeTable(res?.data, res?.data?.length);
				// } else if (res?.status === 401) {
				// 	localStorage.removeItem("userData");
				// }
				if (res && res?.status !== 401) {
					makeTable(res?.data?.data, res?.data?.data?.length);
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const searchSubAdmins = (p = page, pS = pageSize) => {
		searchAdmins({ name: adminSearch, page: p, pageSize: pS })
			.then((res) => {
				makeTable(res?.data?.data);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const statusSubAdmins = (s = status, p = page, pS = pageSize) => {
		statusAdmins({ status: s, page: p, pageSize: pS })
			.then((res) => {
				if (res && res?.status != 401) {
					makeTable(res?.data?.data?.adminData, res?.data?.data?.totalAdmin);
				} else {
					// localStorage.removeItem("userData");
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const labels = ["Name", "Email", "Phone No.", "Status"];

	return (
		<div className={styles.fullPage}>
			<div className={styles.card}>
				<div className={styles.header}>
					<>Admins</>
					<input
						className={styles.orderInput}
						type="text"
						placeholder="Search by name"
						value={adminSearch}
						onKeyUp={(e) => {
							if (e.key === "Enter") {
								handleSearch();
							}
						}}
						onChange={(e) => {
							setAdminSearch(e.target.value);
						}}
					/>
					<button
						className={styles.searchButton}
						onClick={() => {
							handleSearch();
						}}
					>
						Search
					</button>
					<AddCircleIcon
						sx={{ color: "#0993cf", cursor: "pointer" }}
						height={40}
						width={40}
						onClick={() => setAdd(true)}
					/>
					<select
						value={status}
						onChange={(e) => {
							setStatus(e.target.value);
							handleStatus(e.target.value);
							// statusAdmins({ status: e.target.value })
							// 	.then((res) => {
							// 		if (res && res?.status != 401) {
							// 			makeTable(
							// 				res?.data?.data?.adminData,
							// 				res?.data?.data?.totalAdmin
							// 			);
							// 		} else {
							// 			// localStorage.removeItem("userData");
							// 		}
							// 	})
							// 	.finally(() => {
							// 		setIsLoading(false);
							// 	});
						}}
						className={styles.dropdown}
						style={{ color: `${statusColor[status]}` }}
					>
						<option style={{ color: "black" }} key={"all"} value={"all"}>
							All
						</option>
						<option
							style={{ color: "#0993CF" }}
							key={"unblocked"}
							value={"unblocked"}
						>
							Unblocked
						</option>
						<option
							style={{ color: "#E73F3F" }}
							key={"blocked"}
							value={"blocked"}
						>
							Blocked
						</option>
						{/* {statusMap.map((item, index) => {
								if (index != 2) {
									return (
										<option
											style={{ color: `${statusColor[index]}` }}
											key={index}
											value={index}
										>
											{item}
										</option>
									);
								}
							})} */}
					</select>
				</div>
				{isLoading ? (
					<div
						style={{
							width: "100%",
							height: "8rem",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<CircularProgress />
					</div>
				) : (
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								{labels.map((label, index) => (
									<TableCell
										key={index}
										style={{
											backgroundColor: "#F2F2F2",
											fontFamily: "Inter Tight",
											fontWeight: 500,
											fontSize: pageSelector ? "0.675rem" : "0.55rem",
											lineHeight: pageSelector ? "20px" : "10px",
											color: "#848484",
											border: "1px solid #CCCCCC",
										}}
									>
										{label}
									</TableCell>
								))}
							</TableHead>
							<TableBody>
								{tableData?.map((item, index) => (
									<TableRow
										key={index}
										hover={true}
										sx={{
											cursor: "pointer",
											".MuiTableCell-root": {
												fontFamily: "Inter Tight",
												fontWeight: 400,
												fontSize: pageSelector ? "0.675rem" : "0.55rem",
												lineHeight: pageSelector ? "20px" : "10px",
												color: "#1C1C1C",
												border: "1px solid #F2F2F2",
											},
										}}
									>
										<TableCell
											sx={{
												width: "20%",
											}}
										>
											<div
												style={{
													display: "flex",
													alignItems: "center",
												}}
											>
												<Avatar
													src={item.profileImage}
													sx={{
														height: "28px",
														width: "28px",
														fontSize: "14px",
														marginRight: "0.5rem",
													}}
												>
													{`${item.firstname[0].toUpperCase()}${item.lastname[0].toUpperCase()}`}
												</Avatar>
												<span
													style={{ display: "block", textAlign: "center" }}
												>{`${item.firstname} ${item.lastname}`}</span>
											</div>
										</TableCell>
										<TableCell>{item.email.mail}</TableCell>
										<TableCell>
											<>{`${item.country_code}-${item.phone}`}</>
										</TableCell>
										{/* <TableCell>
											<select
												value={status}
												onChange={(e) => setStatus(e.target.value)}
												className={styles.dropdown}
												style={{ color: `${statusColor[status]}` }}
											>
												{statusMap.map((item, index) => {
													if (index != 2) {
														return (
															<option
																style={{ color: `${statusColor[index]}` }}
																key={index}
																value={index}
															>
																{item}
															</option>
														);
													}
												})}
											</select>
											<button
												className={styles.searchButton}
												onClick={() => {
													blockAdmins({ id: item._id, isBlocked: item.status });
												}}
											>
												Change Status
											</button>
										</TableCell> */}
										<TableCell
											onClick={(e) => {
												e.stopPropagation();
											}}
										>
											<select
												className={styles.dropdownStatus}
												// style={{ color: `${statusColor[row.orderStatus]}` }}
												value={item?.isBlocked}
												onChange={(e) => handleBlock(item._id, e.target.value)}
											>
												<option
													value={false}
													// style={{ color: `${statusColor[7]}` }}
												>
													Unblocked
												</option>
												<option
													value={true}
													// style={{ color: `${statusColor[8]}` }}
												>
													Blocked
												</option>
											</select>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}

				<div className={styles.pagination_container}>
					<Select
						IconComponent={KeyboardArrowDownIcon}
						displayEmpty
						value={pageSize}
						onChange={(e) => {
							setPage(1);
							setPageSize(e.target.value);
						}}
						sx={{
							width: "4rem",
							maxHeight: pageSelector ? "2.5rem" : "2rem",
							borderRadius: "8px",
							fontFamily: "Inter Tight",
							fontWeight: "500",
							fontSize: "0.675rem",
							lineHeight: pageSelector ? "20px" : "10px",
							color: "#848484",
							".MuiInputBase-input": { paddingRight: "0" },
							".MuiSelect-icon": { color: "#0993cf" },
						}}
					>
						<MenuItem value={12}>12</MenuItem>
						<MenuItem value={15}>15</MenuItem>
						<MenuItem value={20}>20</MenuItem>
					</Select>
					<Pagination
						sx={{
							fontFamily: "Inter Tight",
							fontWeight: "500",
							fontSize: pageSelector ? "0.675rem" : "0.55rem",
							lineHeight: pageSelector ? "20px" : "10px",
							color: "#848484",
						}}
						page={page}
						onChange={(e, p) => {
							setPage(p);
						}}
						count={totalPages}
						variant="outlined"
						shape="rounded"
					/>
				</div>
				{add && (
					<Add
						modal={add}
						setModal={setAdd}
						handleFetchData={() => {
							// if sub admin is pre approved once add is executed (and will show up on the table immediately), then take the admin to the first page of the sub admin list
							// allSubAdmins(1, 15);
							// else don't do anything because the page we're on is okay
							// if (adminSearch === "" && status === "all") {
							// 	allSubAdmins();
							// } else if (adminSearch !== "") {
							// 	searchSubAdmins();
							// } else {
							// 	statusSubAdmins();
							// }
						}}
					/>
				)}
			</div>
		</div>
	);
};
export default Admin;
