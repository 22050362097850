import { useImageCropContext } from "../../../../utils/providers/ImageCropProvider";
import styles from "./cropper.module.css";
// import icons

export const ZoomSlider = ({ className }) => {
	const {
		zoom,
		setZoom,
		handleZoomIn,
		handleZoomOut,
		max_zoom,
		min_zoom,
		zoom_step,
	} = useImageCropContext();

	return (
		<div className={styles.slider}>
			<button onClick={handleZoomOut}>-</button>
			<input
				type="range"
				name="volju"
				min={min_zoom}
				max={max_zoom}
				step={zoom_step}
				value={zoom}
				onChange={(e) => {
					setZoom(Number(e.target.value));
				}}
			/>
			<button onClick={handleZoomIn}>+</button>
		</div>
	);
};

export const RotationSlider = ({ className }) => {
	const {
		rotation,
		setRotation,
		max_rotation,
		min_rotation,
		rotation_step,
		handleRotateAntiCw,
		handleRotateCw,
	} = useImageCropContext();

	return (
		<div className={styles.slider}>
			<button onClick={handleRotateAntiCw}>-</button>
			<input
				type="range"
				name="volju"
				min={min_rotation}
				max={max_rotation}
				step={rotation_step}
				value={rotation}
				onChange={(e) => {
					setRotation(Number(e.target.value));
				}}
			/>
			<button onClick={handleRotateCw}>+</button>
		</div>
	);
};
