import { useState, useEffect, useRef } from "react";
import styles from "./chat.module.css";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Box, Button } from "@mui/material";
import default_pfp from "../../assets/default_pfp.png";
import Loading_2 from "../../assets/Loading_2.gif";
import { AnimatePresence, motion } from "framer-motion";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import {
	roomsCollection,
	picsCollection,
} from "../../utils/firestore/firebase-config";
import {
	getDocs,
	addDoc,
	serverTimestamp,
	collection,
	doc,
	onSnapshot,
	query,
	where,
	orderBy,
	getDoc,
	updateDoc,
} from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { getTimeSince } from "../../utils/functions";
import CircleIcon from "@mui/icons-material/Circle";
import { sendChatNotif } from "../../utils/api/utilAPI";

function formatDate(date) {
	const options = {
		weekday: "short",
		month: "short",
		day: "numeric",
		year: "numeric",
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	};

	const formatter = new Intl.DateTimeFormat("en-US", options);
	return formatter.format(date);
}

const Chat = () => {
	const location = useLocation();

	const [userRooms, setUserRooms] = useState([]);
	const [riderRooms, setRiderRooms] = useState([]);

	const [animate, setAnimate] = useState(false);
	const [currChat, setCurrChat] = useState(
		location.state ? { ...location.state } : null
	);
	const [roomType, setRoomType] = useState(
		!location || location?.state?.with === "user" ? false : true
	); //false = users | true = riders

	const [currMessages, setCurrMessages] = useState(null);

	const [chatDraft, setChatDraft] = useState("");

	const [openRoom, setOpenRoom] = useState(null);

	const [profileCache, setProfileCache] = useState(null);

	const [search, setSearch] = useState("");

	const [loadedImages, setLoadedImages] = useState([]);

	const chatBodyRef = useRef();

	const scrollToBottom = () => {
		chatBodyRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		setCurrChat(location.state);
	}, [location.state]);

	useEffect(() => {
		scrollToBottom();
	}, [currMessages]);

	const handleImageLoad = (index) => {
		setLoadedImages((prevLoadedImages) => [...prevLoadedImages, index]);
	};

	// snapShot lifecycle for list of rooms of a particular type (roomType)
	useEffect(() => {
		// queries
		const user_room_query = query(
			roomsCollection,
			where("type", "==", "UA"),
			orderBy("lastMessage.createdAt", "desc")
		);
		const rider_room_query = query(
			roomsCollection,
			where("type", "==", "AR"),
			orderBy("lastMessage.createdAt", "desc")
		);
		const profile_query = query(picsCollection);

		// for profile pictures
		const profile_lifecycle = onSnapshot(profile_query, (querySnap) => {
			const profile_temp = {};
			querySnap.forEach((doc) => {
				profile_temp[doc.id] = {
					image: doc.data().image,
					name: doc.data().name,
				};
			});
			setProfileCache(profile_temp);
		});

		// lifecycles
		const user_room_lifecycle = onSnapshot(user_room_query, (querySnap) => {
			const user_temp = [];
			querySnap.forEach((doc) => {
				user_temp.push({
					id: doc.id,
					...doc.data(),
				});
			});
			user_temp.map((item, index) => {
				item.imgLoading = true;
			});
			setUserRooms(user_temp);
		});
		const rider_room_lifecycle = onSnapshot(rider_room_query, (querySnap) => {
			const rider_temp = [];
			querySnap.forEach((doc) => {
				rider_temp.push({
					id: doc.id,
					...doc.data(),
				});
			});
			setRiderRooms(rider_temp);
		});

		return () => {
			user_room_lifecycle();
			rider_room_lifecycle();
			profile_lifecycle();
		};
	}, []);

	useEffect(() => {
		// everytime snapshots are run, userRooms and riderRooms have to be updated!
		// parse userRooms

		let tempUserRooms = userRooms;
		tempUserRooms.forEach((item, index, arr) => {
			if (
				profileCache.hasOwnProperty(item?.userDetails?.user?.id) &&
				profileCache[item?.userDetails?.user?.id]?.name
			) {
				arr[index].userDetails.user.fullName =
					profileCache[item?.userDetails?.user?.id]?.name;
			}
		});
		setUserRooms(tempUserRooms);

		// parse riderRooms
		let tempRider = riderRooms;
		tempRider.forEach((item, index, arr) => {
			if (
				profileCache.hasOwnProperty(item?.userDetails?.rider?.id) &&
				profileCache[item?.userDetails?.rider?.id]?.name
			) {
				arr[index].userDetails.rider.fullName =
					profileCache[item?.userDetails?.rider?.id]?.name;
			}
		});
		setRiderRooms(tempRider);
	}, [profileCache]);

	// snapShot lifecycle for list of messages of a particular room (currChat)
	useEffect(() => {
		const fetchOrCreateChatCollection = async () => {
			if (!currChat) return;
			const roomQuery = query(
				roomsCollection,
				where("type", "==", currChat.with === "user" ? "UA" : "AR"),
				where(
					currChat.with === "user" ? "orderId" : "riderId",
					"==",
					currChat.with === "user" ? currChat.orderID : currChat.riderID
				)
			);

			const roomSnapshot = await getDocs(roomQuery); // should only ever get one document (the specific room)

			const roomRef = await (async function () {
				//roomRef from currChat
				if (roomSnapshot.empty) {
					let roomTemplate = {
						createdAt: serverTimestamp(),
						lastMessage: {
							read: false,
							createdAt: serverTimestamp(),
						},
						orderId: currChat.orderID,
						users: [currChat.userObj._id, "admin"],
						userDetails: {
							user: {
								fullName: currChat.userObj.fullName,
								email: currChat.userObj.email,
								id: currChat.userObj._id,
							},
							admin: {
								fullName: "Admin",
								email: "admin@resourcifi.com",
								id: "admin",
							},
						},
						type: "UA", // admin will ever only create a chat room with user
					};
					return await addDoc(roomsCollection, roomTemplate).then(
						async (res) => {
							return await getDoc(res);
						}
					);
				} else {
					const existingRoomID = roomSnapshot.docs[0].id; // if multiple documents satisfy roomQuery?
					return await getDoc(doc(roomsCollection, existingRoomID));
				}
			})();

			// currChat -> openRoom
			setOpenRoom({
				roomRef: roomRef.ref,
				roomID: roomRef.id,
				createdAt: roomRef.data().createdAt.toDate(),
				refID:
					currChat.with === "user"
						? roomRef.data().orderId
						: roomRef.data().userDetails.rider.email,
				type: currChat.with === "user" ? "UA" : "AR",
				userName:
					currChat.with === "user"
						? roomRef.data().userDetails.user.fullName
						: roomRef.data().userDetails.rider.fullName,
				// for display picture unique id refer to <img> tags
				userID:
					currChat.with === "user"
						? roomRef.data().userDetails.user.id
						: roomRef.data().userDetails.rider.id,
			});
		};

		fetchOrCreateChatCollection();
	}, [currChat]);

	useEffect(() => {
		if (!openRoom) return;
		const messagesQuery = query(
			collection(doc(roomsCollection, openRoom.roomID), "messages"),
			orderBy("createdAt", "asc")
		);

		const unsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
			const messagesData = [];
			querySnapshot.forEach((doc) => {
				messagesData.push({
					id: doc.id,
					...doc.data(),
				});
			});

			if (!currMessages || currMessages.id !== openRoom.roomID) {
				setCurrMessages({
					id: openRoom.roomID,
					messages: messagesData,
				});
			} else if (currMessages.id === openRoom.roomID) {
				setCurrMessages((curr) => ({
					...curr,
					messages: messagesData,
				}));
			}

			if (openRoom.roomRef) {
				// Check if roomRef is defined
				updateDoc(openRoom.roomRef, {
					"lastMessage.read": true,
				});
			}
		});

		return () => unsubscribe();
	}, [openRoom]);

	useEffect(() => {
		// Reset the animate state after the animation completes
		const timeoutId = setTimeout(() => {
			setAnimate(false);
		}, 300); // Adjust the duration to match your transition duration

		return () => clearTimeout(timeoutId);
	}, [animate]);

	// implement a text cache

	const sendText = () => {
		if (chatDraft === "") return;
		if (!openRoom) return;

		const roomRef = doc(roomsCollection, openRoom.roomID);
		const messagesCollection = collection(roomRef, "messages");

		const newMessage = {
			createdAt: serverTimestamp(),
			message: chatDraft,
			messageType: "text", // image
			sentBy: "admin@resourcifi.com",
			sentTo:
				currChat.with === "user" ? currChat.userObj._id : currChat.riderID,
			adminRead: true,
			[currChat.with === "user" ? "userRead" : "riderRead"]: false,
		};

		addDoc(messagesCollection, newMessage)
			.then((docRef) => {
				return getDoc(docRef);
			})
			.then((docSnapshot) => {
				if (docSnapshot.exists()) {
					const data = docSnapshot.data();
					updateDoc(roomRef, {
						["lastMessage"]: {
							createdAt: data.createdAt,
							read: true,
						},
					});
				}
			})
			.finally(() => {
				setChatDraft("");
			});

		sendChatNotif({
			type: currChat.with === "user",
			id: currChat.with === "user" ? currChat.userObj._id : currChat.riderID,
			body: newMessage.message,
			orderId: currChat.with === "user" && openRoom.refID,
		});
	};

	// if attempting chat with uninitialized order + user
	//	create document in firestore
	// else fetch document from firestore
	// maybe implement a front end edge case that shows Create Chat if orderID exists but not in firestore linear search of chats

	const printList = (data_arr) => {
		return (
			<>
				{data_arr?.map((item, index) => {
					if (
						search === "" ||
						(roomType
							? item.userDetails?.rider?.fullName.includes(search)
							: item.userDetails?.user?.fullName.includes(search) ||
							  item.orderId?.includes(search))
					) {
						return (
							<div
								key={index}
								onClick={() => {
									if (item.type === "UA") {
										setCurrChat({
											orderID: item.orderId,
											with: "user",
											userObj: {
												email: item.userDetails.user.email,
												fullName: item.userDetails.user.fullName,
												_id: item.userDetails.user.id,
											},
											// this is different from the Chat! button in order details. please check it!
										});
									} else if (item.type === "AR") {
										setCurrChat({
											riderID: item.riderId,
											with: "rider",
										});
									}
								}}
								className={styles.room_row}
							>
								<div
									style={{
										flex: "1",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<>
										<img
											src={
												loadedImages.includes(
													item.type === "UA"
														? item.userDetails?.user?.id
														: item.userDetails?.rider?.id
												)
													? profileCache[
															item.type === "UA"
																? item.userDetails?.user?.id
																: item.userDetails?.rider?.id
													  ]?.image
														? profileCache[
																item.type === "UA"
																	? item.userDetails?.user?.id
																	: item.userDetails?.rider?.id
														  ]?.image
														: default_pfp
													: Loading_2
											}
											alt=""
											style={{
												borderRadius: "50%",
												width: "40px",
												height: "40px",
											}}
											onLoad={() =>
												handleImageLoad(
													item.type === "UA"
														? item.userDetails?.user?.id
														: item.userDetails?.rider?.id
												)
											}
										/>
									</>
								</div>
								<div className={styles.room_row_name}>
									<div className={styles.name}>
										<span
											style={{
												fontWeight: `${!item.lastMessage.read ? "bold" : ""}`,
											}}
										>
											{item.type === "UA"
												? item.userDetails.user?.fullName
												: item.userDetails.rider?.fullName}
										</span>
										{!item.lastMessage.read && (
											<CircleIcon
												sx={{
													fontSize: 10,
													marginLeft: "0.25rem",
													color: "#0993cf",
												}}
											/>
										)}
									</div>
									{item.type === "UA" && (
										<div
											className={styles.order}
										>{`Order id: ${item.orderId}`}</div>
									)}
								</div>
								<div className={styles.latest_time}>
									<span>
										{getTimeSince(item?.lastMessage?.createdAt?.toDate())}
									</span>
								</div>
							</div>
						);
					}
				})}
			</>
		);
	};

	return (
		<div className={styles.fullPage}>
			<div className={styles.header}>
				<span>Chat</span>
			</div>
			<div className={styles.body}>
				<div
					style={{ flex: "1", aspectRatio: "0.5", width: "40%" }}
					className={styles.card}
				>
					<Box
						sx={{
							margin: "3%",
						}}
					>
						<OutlinedInput
							sx={{
								borderRadius: "8px",
								height: "40px",
							}}
							startAdornment={
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							}
							fullWidth
							placeholder="Search users and order id"
							onChange={(e) => {
								setSearch(e.target.value);
							}}
						/>
					</Box>
					<div
						className={`${styles.type_selector} ${
							roomType ? styles.active : ""
						}`}
					>
						<div
							className={styles.type_button}
							onClick={() => {
								setRoomType(false);
								setAnimate(true);
							}}
						>
							Users
						</div>
						<div
							className={styles.type_button}
							onClick={() => {
								setRoomType(true);
								setAnimate(true);
							}}
						>
							Riders
						</div>
					</div>
					<div className={styles.chatMain}>
						<AnimatePresence>
							{!roomType && (
								<motion.div
									key="userRoom"
									initial={{
										opacity: 0,
										position: "relative",
										x: "-100%",
										width: 0,
									}}
									animate={{ opacity: 1, x: 0, width: "100%" }}
									exit={{ opacity: 0, x: "-100%", width: 0 }}
									transition={{ ease: "easeInOut", duration: 0.3 }}
									className={styles.chatSelector}
								>
									{printList(userRooms)}
								</motion.div>
							)}
						</AnimatePresence>
						<AnimatePresence>
							{roomType && (
								<motion.div
									key="riderRoom"
									initial={{
										opacity: 0,
										position: "relative",
										x: "100%",
										width: 0,
									}}
									animate={{ opacity: 1, x: 0, width: "100%" }}
									exit={{ opacity: 0, x: "100%", width: 0 }}
									transition={{ ease: "easeInOut", duration: 0.3 }}
									className={styles.chatSelector}
								>
									{printList(riderRooms)}
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				</div>
				<div style={{ flex: "2", width: "60%" }} className={styles.card}>
					{openRoom ? (
						<div className={styles.chat}>
							<div className={styles.chat_head}>
								<div className={styles.chat_head_img}>
									<img
										src={
											loadedImages.includes(openRoom.userID)
												? profileCache[openRoom.userID]?.image
													? profileCache[openRoom.userID]?.image
													: default_pfp
												: Loading_2
										}
										alt=""
										style={{
											borderRadius: "50%",
											width: "40px",
											height: "40px",
											backgroundColor: "white",
										}}
										onLoad={() => handleImageLoad(openRoom.userID)}
									/>
								</div>
								<div className={styles.chat_head_details}>
									<div className={styles.chat_head_name}>
										{openRoom?.userName}
									</div>
									<div className={styles.chat_head_order}>
										{openRoom.type === "UA"
											? `Order id: ${openRoom.refID}`
											: `${openRoom.refID}`}
									</div>
								</div>
								<div className={styles.chat_head_time}>
									<div>{formatDate(openRoom.createdAt)}</div>
								</div>
							</div>
							<div className={styles.chat_body}>
								{currMessages?.messages.map((item, index) => {
									return (
										<div
											key={index}
											style={{ display: "flex" }}
											className={`${
												item.sentBy === "admin@resourcifi.com"
													? styles.right_message
													: ""
											}`}
											ref={
												index === currMessages.messages.length - 1
													? chatBodyRef
													: null
											}
										>
											<div
												className={`${styles.chatBubble} ${
													item.sentBy === "admin@resourcifi.com"
														? styles.adminBubble
														: styles.otherBubble
												}`}
											>
												{item.message}
											</div>
										</div>
									);
								})}
							</div>
							<div className={styles.chat_input}>
								<OutlinedInput
									multiline
									maxRows={6}
									value={chatDraft}
									onChange={(e) => setChatDraft(e.target.value)}
									placeholder="Type your message"
									notched={false}
									sx={{
										width: "85%",
										".Mui-focused": {
											borderColor: "transparent",
										},
										"&:hover fieldset": {
											borderColor: "transparent",
										},
										"& fieldset": {
											borderColor: "transparent",
										},
									}}
									InputProps={{
										disableUnderline: true,
										style: {
											boxShadow: "none",
										},
									}}
								/>
								<Button
									sx={{ borderRadius: "8px", padding: "8px 16px 8px 16px" }}
									variant="contained"
									onClick={() => sendText()}
								>
									Send
								</Button>
							</div>
						</div>
					) : (
						<div className={styles.empty_chat}>
							<ChatBubbleIcon
								sx={{
									width: "20%",
									height: "auto",
									color: "#F2F2F2",
								}}
							/>
							<div>Click one of the rooms to start chatting!</div>
							<div>
								You can find the rooms of users in the Users tab, and the rooms
								of riders in the Riders tab.
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default Chat;
