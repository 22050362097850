import styles from "./welcome.module.css";
import STARClean from "../../assets/STARCleanLogo1.png";
import F1 from "../../assets/Frame 8.png";
import F2 from "../../assets/Frame 9.png";
import F3 from "../../assets/Frame 10.png";
import F4 from "../../assets/Frame 11.png";
import F5 from "../../assets/Frame 12.png";
import F6 from "../../assets/Frame 13.png";

const WelcomeTemplate = ({ inputComp }) => {
	return (
		<div style={{ width: "100%", height: "100vh", display: "flex" }}>
			<div className={styles.inputSide}>
				{/* <div style={{position: "absolute", top: "1rem", left: "1rem"}}>STARClean</div> */}
				<img
					src={STARClean}
					alt="STARClean"
					style={{ position: "absolute", top: "1rem", left: "1rem" }}
				/>
				<div style={{ maxWidth: "50%" }}>{inputComp}</div>
			</div>
			<div className={styles.adSide}>
				<div className={styles.adImages}>
					<div className={styles.imagerow}>
						<img src={F1} className={styles.testimage} />
						<img src={F2} className={styles.testimage} />
						<img src={F3} className={styles.testimage} />
					</div>
					<div className={styles.imagerow}>
						<img src={F4} className={styles.testimage} />
						<img src={F5} className={styles.testimage} />
						<img src={F6} className={styles.testimage} />
					</div>
				</div>
				<div className={styles.adText}>
					<div className={styles.freshness}>
						Freshness delivered to your doorstep.
					</div>
					<div className={styles.experience}>
						Experience the convenience of our innovative laundry app, where a
						world of fresh, clean clothes awaits you at your doorstep.
					</div>
				</div>
			</div>
		</div>
	);
};
export default WelcomeTemplate;
