import { useState, useEffect } from "react";
import styles from "./update.module.css";
import Loader from "../../components/ui/Loader/Loader";
import Loading_2 from "../../assets/Loading_2.gif";
import default_pfp from "../../assets/default_pfp.png";
import { getRequests, searchRequest } from "../../utils/api/utilAPI";
import { addressPrinter, printUpdateValue } from "../../utils/functions";
import UpdateModal from "../../components/ui/Update/UpdateModal";
import { getFullName } from "../../utils/functions";

const Update = () => {
	const [loading, setLoading] = useState(true);

	const [search, setSearch] = useState("");

	const [displayArr, setDisplayArr] = useState(null);

	const [openUpModal, setOpenUpModal] = useState(false);
	const [currReq, setCurrReq] = useState(null);

	const [loadedImages, setLoadedImages] = useState([]);

	const handleImageLoad = (index) => {
		setLoadedImages((prevLoadedImages) => [...prevLoadedImages, index]);
	};

	useEffect(() => {
		return fetchRequests();
	}, []);

	// confirm with Azlan
	const reqTitle = {
		isVerifiedRider: "Rider Registration Status",
		email: "Email Address",
		yearOfExperience: "Years Of Experience",
		address: "Address",
		name: "Name",
		phone: "Mobile Number",
	};

	const fetchRequests = () => {
		setLoading(true);
		getRequests()
			.then((res) => {
				setDisplayArr([...res?.data?.data]);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchSearch = (val) => {
		searchRequest({ name: val }).then((res) => {
			setDisplayArr([...res?.data?.data]);
		});
	};

	return (
		<>
			{loading ? (
				<div
					style={{
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<div style={{ height: "10%", aspectRatio: "1" }}>
						<Loader />
					</div>
				</div>
			) : (
				<div className={styles.fullPage}>
					<div className={styles.header}>
						<span>Riders Update Requests</span>
						<input
							type="search"
							className={styles.search}
							placeholder="Search riders"
							value={search}
							onChange={(e) => {
								setSearch(e.target.value);
								fetchSearch(e.target.value);
							}}
						/>
					</div>
					<div className={styles.body}>
						{displayArr.length > 0 ? (
							<>
								{displayArr.map((riderReq, index) => {
									return (
										<div key={index} className={styles.card}>
											<div className={styles.cardHeader}>
												<div className={styles.imageWrap}>
													<img
														src={
															loadedImages.includes(index)
																? riderReq.rider.profileImage
																	? riderReq.rider.profileImage
																	: default_pfp
																: Loading_2
														}
														alt="missing image"
														style={{
															width: "100%",
															height: "100%",
															objectFit: "cover",
															borderRadius: "50%",
														}}
														onLoad={() => handleImageLoad(index)}
													/>
												</div>
												<div className={styles.identify}>
													<div
														className={styles.reqStyle}
														style={{ padding: "0", paddingBottom: "1rem" }}
													>
														<div className={styles.pair}>
															<span style={{ color: "#848484" }}>Name</span>
															<span>{getFullName(riderReq.rider)}</span>
														</div>
													</div>
													<div className={styles.reqStyle}>
														<div className={styles.pair}>
															<span style={{ color: "#848484" }}>
																Mobile Number
															</span>
															<span>
																{riderReq.rider.country_code +
																	"-" +
																	riderReq.rider.phone.mobileNumber}
															</span>
														</div>
													</div>
												</div>
											</div>
											<div className={styles.cardBody}>
												{riderReq.requestItems.map((req, index) => {
													return (
														<div key={index} className={styles.reqStyle}>
															<div className={styles.pair}>
																<span style={{ color: "#848484" }}>
																	{reqTitle[req.requestName]}
																</span>
																<span>
																	{printUpdateValue(
																		req.requestName,
																		req.data.newDetails
																	)}
																</span>
															</div>
															<div
																style={{
																	color: "#0993CF",
																	textDecoration: "underline",
																	cursor: "pointer",
																}}
																onClick={() => {
																	setCurrReq({
																		requestName: req.requestName,
																		newDetails: req.data.newDetails,
																		olderDetails: req.data.olderDetails,
																		rider: riderReq.rider._id,
																		requestId: req._id,
																	});
																	setOpenUpModal(true);
																}}
															>
																Update Request
															</div>
														</div>
													);
												})}
											</div>
										</div>
									);
								})}
							</>
						) : (
							<>No Requests...</>
						)}
					</div>
					<UpdateModal
						// setReload={setIsLoading}
						handleFetchData={fetchRequests}
						reqDetails={currReq}
						modal={openUpModal}
						setModal={setOpenUpModal}
					/>
				</div>
			)}
		</>
	);
};

export default Update;
