import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getOrderByID } from "../../utils/api/utilAPI";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Select, Skeleton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";
import styles from "./orderdetails.module.css";
import AssignRider from "../../components/form/Orders/AssignRider";
import ChangeStatus from "../../components/form/Orders/ChangeStatus";
import { statusMap } from "../../utils/constants";
import Loader from "../../components/ui/Loader/Loader";
import {
	addressPrinter,
	getFullName,
	orderRow,
	printName,
} from "../../utils/functions";
import default_pfp from "../../assets/default_pfp.png";
import Loading_2 from "../../assets/Loading_2.gif";
import StarCount from "../../components/ui/StarCount/StarCount";

const OrderDetails = () => {
	const navigate = useNavigate();
	const params = useParams();

	const [loading, setLoading] = useState(true);
	const [orderData, setOrderData] = useState(null);

	const printMenu = () => {};

	const statusDesc = ["Pick-up request"];

	const [openAssign, setOpenAssign] = useState(false);
	const [openStatus, setOpenStatus] = useState(false);

	const [riderOrder, setRiderOrder] = useState(null);

	const [loadedImage, setLoadedImage] = useState(false);

	const handleImageLoad = () => {
		setLoadedImage(true);
	};

	const fetchOrder = () => {
		getOrderByID({ orderID: params.orderID })
			.then((res) => {
				if (res?.status) {
					setOrderData(orderRow(res?.data?.data[0]));
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchOrder();
	}, []);

	// data prep
	useEffect(() => {
		if (orderData !== null) {
			setLoading(false);
		}
	}, [orderData]);

	return loading ? (
		<div
			style={{
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div style={{ height: "10%", aspectRatio: "1" }}>
				<Loader />
			</div>
		</div>
	) : (
		<div className={styles.backGround}>
			<div className={styles.backbutton}>
				<ArrowBackRoundedIcon
					sx={{
						cursor: "pointer",
					}}
					onClick={() => {
						navigate(-1);
					}}
				/>
				Order id: {params.orderID}
			</div>
			<>
				<div style={{ display: "flex", flexDirection: "row", gap: "14px" }}>
					<div className={styles.card}>
						<div className={styles.cardHeader}>
							<div className={styles.cardTitle}>
								{orderData.serviceType === "laundry"
									? "Laundry"
									: "Dry Cleaning"}
							</div>
							<div className={styles.cardDate}>
								{moment(orderData?.createdAt).format("ll")}
							</div>
						</div>
						<div>
							<div className={styles.priceTitle}>Price Details</div>
							<table className={styles.priceTable}>
								<tr>
									{orderData.serviceType === "laundry" ? (
										<>
											<td>Weight of apparels</td>
											<td className={styles.priceVal}>
												<div style={{ display: "inline-block" }}>
													{orderData.itemsWeight}
												</div>
											</td>
										</>
									) : (
										<>
											<td>Items</td>
											<td className={styles.priceVal}>
												{orderData.itemsWeight}
											</td>
										</>
									)}
								</tr>
								<tr>
									<td>Subtotal</td>
									<td className={styles.priceVal}>
										{(orderData.serviceType === "dryCleaning" &&
											orderData.orderStatus < 2) ||
										(orderData.serviceType === "laundry" &&
											orderData.orderStatus < 6)
											? "Pending"
											: `$${orderData.subTotal.toFixed(2)}`}
									</td>
								</tr>
								<tr>
									<td>Rider Tip</td>
									<td className={styles.priceVal}>
										{`$${orderData.riderTip.toFixed(2)}`}
									</td>
								</tr>
								<tr>
									<td>Tax</td>
									<td className={styles.priceVal}>
										{(orderData.serviceType === "dryCleaning" &&
											orderData.orderStatus < 2) ||
										(orderData.serviceType === "laundry" &&
											orderData.orderStatus < 6)
											? "Pending"
											: `$${orderData.tax.toFixed(2)}`}
									</td>
								</tr>
							</table>
							<div className={styles.total}>
								<div>Total</div>
								<div>{orderData.amount}</div>
							</div>
							<div className={styles.items}>Items</div>
							<table className={styles.itemsTable}>
								{orderData?.orderItems.map((item, index) => {
									return (
										<tr key={index}>
											<td>{`${item.clothType} (${item.categoryName})`}</td>
											<td className={styles.priceVal}>{item.orderCount}</td>
										</tr>
									);
								})}
							</table>
							<div className={styles.pickup}>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "0.25rem",
									}}
								>
									<span>Pickup date and time</span>
									<span className={styles.pickupVal}>
										{moment(orderData.pickUpDate).format(
											"MMMM DD, YYYY, hh:mm A"
										)}
									</span>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "0.25rem",
									}}
								>
									<span>Delivery date and time</span>
									<span className={styles.pickupVal}>
										{orderData.deliveryDate
											? moment(orderData.deliveryDate).format(
													"MMMM DD, YYYY, hh:mm A"
											  )
											: "Pending"}
									</span>
								</div>
							</div>
							<div className={styles.addressBar}>
								<div
									style={{ display: "flex", justifyContent: "space-between" }}
								>
									{(function () {
										const addressType =
											orderData.orderDeliveryAddress?.addressType;
										const getColor = () => {
											switch (addressType) {
												case "Home":
													return "#6ECABF";
												case "home":
													return "#6ECABF";
												case "Office":
													return "black";
												case "office":
													return "black";
												default:
													return "grey";
											}
										};
										return (
											<div
												className={styles.addressTag}
												style={{
													backgroundColor: getColor(),
												}}
											>
												{addressType
													? addressType.charAt(0).toUpperCase() +
													  addressType.slice(1)
													: "Default"}
											</div>
										);
									})()}
									<div
										className={styles.chatButton}
										onClick={() => {
											navigate("/chat", {
												state: {
													orderID: params.orderID,
													userObj: orderData.clientObj,
													with: "user",
												},
											});
										}}
									>
										Chat
									</div>
								</div>
								<div className={styles.userInfo}>
									{printName(orderData.orderDeliveryAddress.fullName)}
								</div>
								<div className={styles.userContact}>{orderData.contact}</div>
								<div className={styles.userAddress}>
									{addressPrinter(orderData.orderDeliveryAddress)}
								</div>
							</div>
						</div>
					</div>
					<div style={{ width: "50%" }}>
						<div className={styles.orderActions}>
							<div className={styles.cardTitle} style={{ color: "#1c1c1c" }}>
								Order Actions
							</div>
							<div className={styles.actionTitle}>Status</div>
							<div
								className={styles.statusDropdown}
								style={
									orderData.orderStatus === 7 || orderData.orderStatus === 8
										? {
												color: "#0993cf",
												cursor: "pointer",
										  }
										: {
												color: "#848484",
										  }
								}
								onClick={() => {
									if (
										orderData.orderStatus === 7 ||
										orderData.orderStatus === 8
									)
										setOpenStatus(true);
								}}
							>
								{`${statusMap[orderData.orderStatus]}`}
								{orderData.orderStatus === 7 || orderData.orderStatus === 8 ? (
									<KeyboardArrowDownIcon style={{ color: "#0993CF" }} />
								) : null}
							</div>
							<div className={styles.actionTitle}>Rider</div>

							{typeof orderData.assignRider === "object" ? (
								<div className={styles.notAssign}>
									<div className={styles.riderDetails}>
										<img
											style={{
												borderRadius: "50%",
												width: "20px",
												height: "20px",
											}}
											src={
												loadedImage
													? orderData.assignRider.profileImage
														? orderData.assignRider.profileImage
														: default_pfp
													: Loading_2
											}
											alt="Assigned Rider"
											onLoad={handleImageLoad}
										/>
										{getFullName(orderData.assignRider)}
									</div>
									<StarCount count={orderData.orderRating} />
								</div>
							) : (
								<div
									className={styles.assign}
									style={{
										cursor: orderData.assignRider ? "pointer" : "default",
										color: orderData.assignRider ? "#0993CF" : "#848484",
										textDecoration: orderData.assignRider ? "underline" : "",
									}}
									onClick={() => {
										if (
											typeof orderData.assignRider === "boolean" &&
											orderData.assignRider
										) {
											setRiderOrder({
												orderID: orderData.orderID,
												orderStatus: orderData.orderStatus,
											});
											setOpenAssign(true);
										}
									}}
								>
									{orderData.assignRider || orderData.orderStatus === 7
										? "Assign Rider"
										: "Deleted Rider"}
								</div>
							)}
						</div>
					</div>
				</div>
			</>
			<AssignRider
				orderDetails={riderOrder}
				modal={openAssign}
				setModal={setOpenAssign}
				handleFetchData={fetchOrder}
			/>
			<ChangeStatus
				orderID={params.orderID}
				modal={openStatus}
				setModal={setOpenStatus}
				handleFetchData={fetchOrder}
			/>
		</div>
	);
};

export default OrderDetails;
