import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { userDetails } from "../../utils/api/utilAPI";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Select, Skeleton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";
import styles from "./userdetails.module.css";
import AssignRider from "../../components/form/Orders/AssignRider";
import ChangeStatus from "../../components/form/Orders/ChangeStatus";
import { statusMap } from "../../utils/constants";
import Loader from "../../components/ui/Loader/Loader";
import { addressPrinter, getFullName } from "../../utils/functions";
import default_pfp from "../../assets/default_pfp.png";
import Loading_2 from "../../assets/Loading_2.gif";
import StarCount from "../../components/ui/StarCount/StarCount";

const OrderDetails = () => {
	const navigate = useNavigate();
	const params = useParams();

	const [loading, setLoading] = useState(false);
	const [userData, setUserData] = useState(null);
	const [loadedImage, setLoadedImage] = useState(false);

	const handleImageLoad = () => {
		setLoadedImage(true);
	};

	const fetchUser = () => {
		userDetails({ userId: params.userId })
			.then((res) => {
				console.log("res: ", res);
				if (res?.status) {
					setUserData(res?.data?.data);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setLoading(true);
		fetchUser();
	}, []);

	// data prep
	// useEffect(() => {
	// 	if (userData !== null) {
	// 		setLoading(false);
	// 	}
	// }, [userData]);

	return loading ? (
		<div
			style={{
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div style={{ height: "10%", aspectRatio: "1" }}>
				<Loader />
			</div>
		</div>
	) : (
		<div className={styles.backGround}>
			<div className={styles.backbutton}>
				<ArrowBackRoundedIcon
					sx={{
						cursor: "pointer",
					}}
					onClick={() => {
						navigate(-1);
					}}
				/>
			</div>
			<div className={styles.card}>
				<div className={styles.imageDetail}>
					<div
						style={{
							width: "7.5rem",
							height: "7.5rem",
							flexShrink: "0",
						}}
					>
						<img
							src={
								loadedImage
									? userData?.profileImage
										? userData?.profileImage
										: default_pfp
									: Loading_2
							}
							alt=""
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover", // Maintain aspect ratio and cover the container
								borderRadius: "50%",
							}}
							onLoad={() => handleImageLoad()}
						/>
					</div>
					<div className={styles.nameNumber}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								paddingBottom: "1rem",
								gap: "0.25rem",
								alignSelf: "stretch",
							}}
						>
							<span className={styles.subHeading}>Name</span>
							<div className={styles.altValue}>
								<div>
									<span className={styles.subHeadingValue}>
										{`${userData?.firstname} ${userData?.lastname}`}
									</span>
								</div>
								<div className={styles.verifiedBlock}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "0.2rem",
											color: "#848484",
										}}
									>
										{userData?.isDeleted ? <>Active</> : null}
									</div>
								</div>
							</div>
						</div>
						<div className={styles.numberStyle}>
							<span className={styles.subHeading}>Mobile Number</span>
							<span className={styles.subHeadingValue}>
								{`${userData?.country_code}-${userData?.phone.mobileNumber}`}
							</span>
						</div>
					</div>
				</div>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<div className={styles.otherDetail}>
						<span className={styles.subHeading}>Email Address</span>
						<span className={styles.subHeadingValue}>
							{userData?.email?.mail}
						</span>
					</div>
					<div className={styles.otherDetail}>
						<span className={styles.subHeading}>Orders</span>
						<span className={styles.subHeadingValue}>
							{userData?.orderCount}
						</span>
					</div>
					<div className={styles.otherDetail}>
						<span className={styles.subHeading}>Address</span>
						<span className={styles.subHeadingValue}>
							{addressPrinter(userData?.addresses[0])}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderDetails;
