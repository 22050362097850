import React, { useState, useEffect } from "react";
import { Modal, Backdrop, Fade, Button, Radio } from "@mui/material";
import styles from "./timeoutpop.module.css";
import { getRiders, assignRider } from "../../../utils/api/utilAPI";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useDispatch, useSelector } from "react-redux";
import {
	sessionTimeout,
	logoutSuccess,
} from "../../../utils/redux/features/authSlice";
import { turnFalse } from "../../../utils/redux/features/popUpSlice";

const AssignModal = (props) => {
	// const selector = useSelector((state) => state.authReducer.user);
	const popUpSelector = useSelector((state) => state.popUpReducer.bool);
	const dispatch = useDispatch();
	const handleLogout = () => {
		dispatch(logoutSuccess());
		dispatch(turnFalse());
		localStorage.setItem("userData", null);
		window.location.reload();
	};

	const handleClose = () => {
		handleLogout();
		props.setModal(false);
	};

	return (
		<div>
			<Modal
				open={props.modal}
				onClose={handleClose}
				closeAfterTransition
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Fade in={props.modal}>
					<div className={styles.fullModal}>
						<div className={styles.header}>
							<div>Session Timeout</div>
							<CloseRoundedIcon onClick={handleClose} />
						</div>
						<div className={styles.body}>
							<div className={styles.message}>SESSION TIMEOUT!</div>
							<button className={styles.submitButton} onClick={handleClose}>
								Close
							</button>
						</div>
					</div>
				</Fade>
			</Modal>
		</div>
	);
};

export default AssignModal;
