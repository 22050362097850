import { createSlice } from "@reduxjs/toolkit";

const popUpSlice = createSlice({
	name: "popUp",
	initialState: {
		bool: false,
	},
	reducers: {
		turnTrue: (state) => {
			state.bool = true;
		},
		turnFalse: (state) => {
			state.bool = false;
		},
	},
});

export const { turnTrue, turnFalse } = popUpSlice.actions;

export default popUpSlice.reducer;
