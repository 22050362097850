//Auth Constants
export const LOGIN_URL = "admin/login";
export const FORGET_PASSWORD = "admin/forgotPassword";
export const VERIFY_OTP = "admin/verifyOtp";
export const RESET_PASSWORD_URL = "admin/resetPassword";
export const LOGOUT_URL = "admin/logout";
export const DASHBOARD_URL = "admin/dashboard";
export const ENABLE_NOTIFICATION = "admin/enableNotification";

// ORDERS
export const ORDER_TABLE = "admin/getAllOrder";
export const ORDER_BY_ID = "admin/getOrderById";
export const SEARCH_ORDER = "admin/searchOrder";
export const GET_RIDERS = "/admin/rider/getAllRider";
export const ASSIGN_RIDER = "admin/assignRiderToOrder";
export const SET_STATUS = "admin/changeOrderStatusByID";
export const GET_VERIFIED_RIDERS = "admin/getAllVerifiedRider";
export const SEARCH_VERIFIED_RIDERS = "admin/searchVerifiedRider";

// SERVICES
export const GET_SERVICE_DATA = "admin/services/getServiceData";
export const GET_CATEGORY_DATA = "admin/services/getCategoryData";
export const EDIT_CATEGORY_NAME = "admin/services/editCategoryName";
export const HIDE_CATEGORY = "admin/services/hideCategory";
export const GET_SUBCATEGORY = "admin/services/getSubCategoryData";
export const EDIT_LAUNDRY_PRICE = "admin/services/editLaundryPrice";
export const ADD_SERVICE_CATEGORY = "admin/services/addServiceCategory";
export const ADD_SUBCATEGORY = "admin/services/addSubCategory";
export const HIDE_SUBCATEGORY = "admin/services/hideSubCategory";
export const EDIT_SUBCATEGORY = "admin/services/editSubCategory";

// RIDERS
export const GET_ALL_RIDER = "admin/rider/getAllRider";
export const RIDER_BY_STATUS = "admin/rider/getRiderByStatus";
export const SEARCH_RIDER = "admin/rider/searchRider";
export const BLOCKED_RIDER = "admin/rider/blockedRider";

//UPDATE REQUESTS
export const GET_ALL_REQUESTS = "admin/updateReq/getAllRequest";
export const CONFIRM_REQUEST = "admin/updateReq/editRiderDetails";
export const SEARCH_REQUEST = "admin/updateReq/searchRequest";

//PROFILE
export const CHANGE_PASSWORD = "admin/changePassword";
export const EDIT_PROFILE = "admin/editProfile";

// CHAT
export const SEND_NOTIFICATION = "admin/sendNotification";
export const RIDER_NOTIFICATION = "rider/sendNotification";

// ADMIN
export const FETCH_SUBADMIN = "admin/subadmin/getAllSubAdmin";
export const SEARCH_SUBADMIN = "admin/subadmin/searchAdmin";
export const STATUS_SUBADMIN = "admin/subadmin/getAdminByStatus";
export const BLOCK_SUBADMIN = "admin/subadmin/blockedAdmin";
export const ADD_SUBADMIN = "admin/addAdmin";
// export const ADD_SUBADMIN = "admin/addUser";
// export const PASSWORD_SUBADMIN = "admin/subadmin/setPasswordForNewUSer";
export const PASSWORD_SUBADMIN = "admin/setPasswordForNewUSer";

// USERS
export const FETCH_ACTIVE = "admin/getAllActiveUser";
export const FETCH_INACTIVE = "admin/getAllInactiveUser";
export const USER_BY_ID = "admin/getUserById";
export const SEARCH_USER = "admin/searchUser";
