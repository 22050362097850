import { useState, useEffect } from "react";
import styles from "./dashboardcomp.module.css";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Skeleton } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { dashboardGraph } from "../../../utils/api/utilAPI";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { months, days } from "../../../utils/constants";
import { useSelector } from "react-redux";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const OrdersComp = (props) => {
	const { backEndData } = props;

	const pageSelector = useSelector((state) => state.pageReducer.pageSize);

	const [totalOrders, setTotalOrders] = useState(backEndData?.totalOrder);
	const [filter, setFilter] = useState("yearly");
	const [loading, setLoading] = useState(true);
	const [finalData, setFinalData] = useState(null);
	const [compiled, setCompiled] = useState(null);
	const [maxOrders, setMaxOrders] = useState(0); // this doesn't represent the actual data, just used to style the chart

	useEffect(() => {
		//loading is already set true
		makeTable(backEndData, filter);
	}, []);

	const handleFilter = (queryVal) => {
		setLoading(true);
		setFilter(queryVal);
		dashboardGraph({
			orderQuery: queryVal,
		}).then((res) => {
			if (res && res?.status != 401) {
				setTotalOrders(res?.data?.data?.totalOrder);
				makeTable(res?.data?.data?.orderData, queryVal);
			} else {
				localStorage.removeItem("userData");
			}
		});
	};

	const makeTable = (rawData, currFilter) => {
		const isYearly = currFilter === "yearly";
		let completed = Array(isYearly ? 12 : 31).fill(0);
		let canceled = Array(isYearly ? 12 : 31).fill(0);
		let completedOrders = 0,
			canceledOrders = 0;

		if (rawData != null) {
			rawData.orderGraphData.forEach((item) => {
				let domainVal = isYearly
					? moment(item.createdAt, "YYYY-MM-DD").month()
					: moment(item.createdAt, "YYYY-MM-DD").date();
				let countVal = item.count;
				if (item.orderStatus === "cancel") {
					canceled[domainVal] += countVal;
					canceledOrders += countVal;
				} else {
					completed[domainVal] += countVal;
					completedOrders += countVal;
				}
			});
		}

		let tempMax = completedOrders + canceledOrders;
		setMaxOrders(tempMax <= 5 ? 5 : tempMax);

		setFinalData({
			completed,
			canceled,
			completedOrders,
			canceledOrders,
		});

		setCompiled({
			labels: isYearly ? months : days,
			datasets: [
				{
					label: "Completed",
					data: completed,
					backgroundColor: "#A1E0A3",
					barPercentage: 0.4,
					borderSkipped: "bottom", // Curved border only on the top
					order: 2,
				},
				{
					label: "Canceled",
					data: canceled,
					backgroundColor: "#EC9999",
					barPercentage: 0.4,
					borderSkipped: "bottom", // Curved border only on the top
					order: 1, // Ensure "Completed" is drawn behind "Canceled", // Draw "Canceled" on top of "Completed"
				},
			],
		});
		setLoading(false); // ??? this doesn't help you realize that right? you need makeTable to be async for this to make even a glimmer of sense. of course you need a setLoading(false) but without async, you're only proving you're not async with reality
	};

	const options = {
		responsive: true,
		devicePixelRatio: 4,
		plugins: {
			legend: {
				display: false, // Hide the legend
			},
			title: {
				display: false, // Hide the title
			},
		},
		elements: {
			bar: {
				borderRadius: 8,
			},
		},
		scales: {
			x: {
				border: {
					display: false,
				},
				stacked: true,
				grid: {
					display: false,
				},
				ticks: {
					font: {
						family: "Inter Tight, sans-serif",
						size: pageSelector ? 14 : 10,
						weight: 400,
					},
				},
			},
			y: {
				border: {
					display: false,
				},
				ticks: {
					maxTicksLimit: 6,
					font: {
						family: "Inter Tight, sans-serif",
						size: pageSelector ? 14 : 10,
						weigth: 400,
					},
				},
				beginAtZero: true,
				suggestedMax: maxOrders,
			},
		},
	};

	return (
		<>
			<div className={styles.header}>
				<div className={styles.cardTitle}>Orders</div>
				<Select
					variant="outlined"
					sx={{
						borderRadius: "8px",
						color: "#848484",
						height: pageSelector ? "1.5rem" : "1.25rem",
						textAlign: "left",
						fontSize: pageSelector ? "0.75rem" : "0.6rem",
						fontWeight: "400",
					}}
					value={filter}
					onChange={(e) => {
						handleFilter(e.target.value);
					}}
					IconComponent={KeyboardArrowDownIcon}
				>
					<MenuItem value={"yearly"}>Yearly</MenuItem>
					<MenuItem value={"monthly"}>Monthly</MenuItem>
				</Select>
			</div>
			{!loading ? (
				<>
					<div className={styles.second}>
						<h1>{totalOrders}</h1>
						<div className={styles.segregate}>
							<div className={styles.valuePair}>
								<div style={{ display: "flex", gap: "5px" }}>
									<CircleIcon sx={{ width: "10%", color: "#A1E0A3" }} />
									<h2>Completed</h2>
								</div>
								<div>
									<h1>{finalData?.completedOrders}</h1>
								</div>
							</div>
							<div className={styles.valuePair}>
								<div style={{ display: "flex", gap: "5px" }}>
									<CircleIcon sx={{ width: "10%", color: "#EC9999" }} />
									<h2>Canceled</h2>
								</div>
								<div>
									<h1>{finalData?.canceledOrders}</h1>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.third}>
						<Bar className="line-graph" options={options} data={compiled} />
					</div>
				</>
			) : (
				<Skeleton variant="rounded" width={"100%"} height={"85%"} />
			)}
		</>
	);
};
export default OrdersComp;
