import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import pageReducer from "./features/pageSlice";
import popUpReducer from "./features/popUpSlice";

export const store = configureStore({
	reducer: {
		authReducer,
		pageReducer,
		popUpReducer,
	},
});
