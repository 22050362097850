import { readFile } from "../../../utils/functions";
import { useImageCropContext } from "../../../utils/providers/ImageCropProvider";
import Cropper from "../Profile/cropper/Cropper";
import { RotationSlider, ZoomSlider } from "../Profile/cropper/Sliders";
import { Modal, Backdrop, Fade, Button, Radio } from "@mui/material";
import styles from "./profile.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import editImg from "../../../assets/editImg.png";

const ImageCropModalContent = ({ modal, handleDone, handleClose }) => {
	const { setImage } = useImageCropContext();

	const handleFileChange = async ({ target: { files } }) => {
		const file = files && files[0];
		const imageDataUrl = await readFile(file);
		setImage(imageDataUrl);
	};

	return (
		<Modal
			open={modal}
			onClose={handleClose}
			closeAfterTransition
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Fade in={modal}>
				<div className={styles.fullModal}>
					<div className={styles.header}>
						<div>Edit Profile Picture</div>
						<CloseRoundedIcon
							style={{ cursor: "pointer" }}
							onClick={handleClose}
						/>
					</div>
					<div className={styles.body}>
						<div className={styles.cropContainer}>
							<Cropper />
							<input
								type="file"
								multiple
								onChange={handleFileChange}
								className={styles.hidden}
								id="cropInput"
								accept="image/*"
								display="none"
							/>
							<label htmlFor="cropInput" className={styles.imageLabel}>
								<img src={editImg} className={styles.imgIcon} />
							</label>
						</div>
						{/*figure out how to style the two components below*/}
						<div
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<ZoomSlider />
							<RotationSlider />
						</div>
					</div>
					<div className={styles.footer}>
						<button
							// className={`${styles.submitButton} ${
							// 	loading && styles.hiddenButton
							// }`}
							// onClick={() => {
							// 	if (!loading) handleClose();
							// }}
							className={`${styles.button} ${styles.cancel}`}
							onClick={handleClose}
						>
							Cancel
						</button>
						<button
							// className={`${styles.submitButton} ${
							// 	loading && styles.hiddenButton
							// }`}
							// onClick={() => {
							// 	if (!loading) handleDone();
							// }}
							className={`${styles.button} ${styles.submit}`}
							onClick={handleDone}
						>
							Upload
						</button>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default ImageCropModalContent;
