// URL CONSTANTS
export const LANDING_PATH = "/";
export const LOGIN_PATH = "/login";
export const SOCIAl_SIGNUP_PATH = "/social-signup";
export const TNC_PATH = "/termsandconditions";
export const PRIVACY_PATH = "/privacy";
export const CONTACTUS_PATH = "/contactUs";
export const USERS_PATH = "/user";
export const SIGNUP_PATH = "/signup";
export const TWO_STEP_CHOICE_PATH = "/signup/two-step-choice";
export const TWO_STEP_VERIFY_PATH = "/signup/two-step-verify";
export const TIMELINE_PATH = "/timeline";
export const PROFILE_EVENT_DETAILS_PATH = "/profile/event-details/:id";
export const PROFILE_EVENT_DETAILS_NO_ID_PATH = "/profile/event-details/";
export const FORGOT_PASSWORD_PATH = "/forgot";
export const PROFILE_PATH = "/profile";
export const PROFILE_EDIT_PATH = "/profile/edit";

// LOCAL STORAGE CONSTANTS
export const SORT_BY = "sortBy";

export const statusMap = [
	"All",
	"Pickup Requested", // Order Started for laundry (payment not made)
	"Pickup Requested", // Order Placed for dry cleaning (payment has been made)
	"Pickup Rider Assigned", // waiting for Rider to start pick up
	"Out for Pick-up", // rider on the way to user
	"Payment Pending", // Weighing -> Payment Request Sent (Laundry | skip for dry cleaning)
	"Enroute to Facility", // user has paid for laundry and rider is leaving / has left
	"Arrived at Facility", // will be set by rider when they drop clothes at facility
	"In Progress", // can assign rider whenever (admin will want to manually ensure to assign when order is ready)
	"Delivery Rider Assigned", // rider now has to tap start delivery
	"Out for Delivery", // rider on the way to user
	"Completed", // rider will update
	"Canceled",
];

export const statusColor = [
	"#1C1C1C",
	"#0993CF",
	"#0993CF",
	"#068C7C",
	"#712CC9",
	"#FB6613",
	"#712CC9",
	"#0DA800",
	"#D99C00",
	"#068C7C",
	"#712CC9",
	"#0DA800",
	"#E73F3F",
];

export const months = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"June",
	"July",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

export const days = Array.from({ length: 31 }, (_, index) => index + 1);
