import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Pie } from "react-chartjs-2";
import styles from "./dashboardcomp.module.css";
import { useSelector } from "react-redux";
import { dashboardGraph } from "../../../utils/api/utilAPI";
import CircleIcon from "@mui/icons-material/Circle";
import { Skeleton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const PieChart = ({ title, backEndData }) => {
	const pageSelector = useSelector((state) => state.pageReducer.pageSize);

	const [filter, setFilter] = useState("yearly");
	const [loading, setLoading] = useState(true);
	const [pieChartData, setPieChartData] = useState({});

	const [largeVal, setLargeVal] = useState(0);
	const [firstVal, setFirstVal] = useState(0);
	const [secondVal, setSecondVal] = useState(0);

	useEffect(() => {
		prepForGraph(backEndData).finally(() => setLoading(false));
	}, []);

	async function prepForGraph(bigData) {
		if (!bigData) return;
		let tempLarge = 0,
			tempFirst = 0,
			tempSecond = 0;
		bigData?.map((data) => {
			if (title === "Users") {
				switch (data.userType) {
					case "user":
						tempFirst += data.count;
						break;
					case "rider":
						tempSecond += data.count;
						break;
				}
			} else {
				switch (data.serviceType) {
					case "laundry":
						tempFirst += data.count;
						break;
					case "dryCleaning":
						tempSecond += data.count;
						break;
				}
			}
		});
		tempLarge = tempFirst + tempSecond;
		setLargeVal(tempLarge);
		setFirstVal(tempFirst);
		setSecondVal(tempSecond);

		setPieChartData({
			labels: bigData?.map((data) =>
				title === "Users"
					? data.userType === "user"
						? "Client"
						: "Rider"
					: data.serviceType === "dryCleaning"
					? "Dry Cleaning"
					: "Laundry"
			),
			datasets: [
				{
					data: bigData?.map((data) => data.count),
					backgroundColor: bigData?.map((data) => {
						if (title === "Users") {
							switch (data.userType) {
								case "user":
									return "#79E0C7";
								case "rider":
									return "#EFA3F1";
								default:
									return "#999";
							}
						} else {
							switch (data.serviceType) {
								case "laundry":
									return "#0993CF";
								case "dryCleaning":
									return "#8354D1";
								default:
									return "#999";
							}
						}
					}),
				},
			],
		});
	}

	const handleFilter = (queryVal) => {
		setLoading(true);
		setFilter(queryVal);
		const name = title === "Users" ? "userChartQuery" : "orderChartQuery";
		dashboardGraph({
			[name]: queryVal,
		}).then((res) => {
			if (res?.status != 401) {
				if (title === "Users") {
					prepForGraph(res?.data?.data?.userChartData).finally(() => {
						setLoading(false);
					});
				} else {
					prepForGraph(res?.data?.data?.orderChartData).finally(() =>
						setLoading(false)
					);
				}
			}
		});
	};

	const options = {
		responsive: true,
		maintainAspectRatio: true,
		plugins: {
			legend: {
				display: false,
			},
		},
	};

	return (
		<>
			<div className={styles.header}>
				<div className={styles.cardTitle}>{title}</div>
				<Select
					variant="outlined"
					sx={{
						width: pageSelector ? "5.25rem" : "4.75rem",
						borderRadius: "8px",
						color: "#848484",
						height: pageSelector ? "1.5rem" : "1.25rem",
						textAlign: "left",
						fontSize: pageSelector ? "0.75rem" : "0.6rem",
						fontWeight: "400",
					}}
					value={filter}
					onChange={(e) => handleFilter(e.target.value)}
					IconComponent={KeyboardArrowDownIcon}
				>
					<MenuItem value={"yearly"}>Yearly</MenuItem>
					<MenuItem value={"monthly"}>Monthly</MenuItem>
				</Select>
			</div>
			<div className={styles.body}>
				<div className={styles.secondAltText}>
					<div className={styles.pieBigVal}>{largeVal}</div>
					<div className={styles.pieSmallVal}>
						<div className={`${styles.valuePair}`}>
							<div style={{ display: "flex", gap: "5px" }}>
								<CircleIcon
									sx={{
										width: "0.5rem",
										color: title === "Users" ? "#79E0C7" : "#0993CF",
									}}
								/>
								<h2>{title === "Users" ? "Client" : "Laundry"}</h2>
							</div>
							<h1>{firstVal}</h1>
						</div>
						<div className={`${styles.valuePair}`}>
							<div style={{ display: "flex", gap: "5px" }}>
								<CircleIcon
									sx={{
										width: "0.5rem",
										color: title === "Users" ? "#EFA3F1" : "#8354D1",
									}}
								/>
								<h2>
									{title === "Users"
										? "Rider"
										: pageSelector
										? "Dry Cleaning"
										: "Dry Cl."}
								</h2>
							</div>
							<h1>{secondVal}</h1>
						</div>
					</div>
				</div>
				<div className={styles.pieContainer}>
					{loading ? (
						<Skeleton variant="rounded" width={"100%"} height={"85%"} />
					) : (
						<Pie className="pie-graph" data={pieChartData} options={options} />
					)}
				</div>
			</div>
		</>
	);
};
export default PieChart;
