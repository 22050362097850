import styles from "./dashboard.module.css";
import Loader from "../../components/ui/Loader/Loader";
import OrdersComp from "../../components/ui/Dashboard/OrdersComp";
import RevenueComp from "../../components/ui/Dashboard/RevenueComp";
import PieChart from "../../components/ui/Dashboard/PieChart";
import TableComp from "../../components/ui/Dashboard/TableComp";
import { useState, useEffect } from "react";
import { dashboardGraph } from "../../utils/api/utilAPI";
import { sessionTimeout } from "../../utils/redux/features/authSlice";

const Dashboard = () => {
	const [allData, setAllData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	// for now, making only one api call and sending respective data to all components

	useEffect(() => {
		// make API call
		dashboardGraph({
			orderQuery: "yearly",
			revenueQuery: "yearly",
			userChartQuery: "yearly",
			orderChartQuery: "yearly",
		}).then((res) => {
			// if (res && res?.status !== 401) {
			// 	setAllData(res?.data?.data);
			// } else {
			// 	setTimeoutPop(true); // will need to make this a redux state and use it in all components with API calls... or maybe just in the wrapped functions themselves
			// 	sessionTimeout();
			// 	setAllData(null);
			// }
			setAllData(res?.data?.data);
			setIsLoading(false);
		});
		// .finally(() => {
		// 	setIsLoading(false);
		// });
	}, []);

	return isLoading ? (
		<div
			style={{
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div style={{ height: "10%", aspectRatio: "1" }}>
				<Loader />
			</div>
		</div>
	) : (
		<div className={styles.dashboardMain}>
			{allData && (
				<>
					<div className={styles.graphs}>
						<div className={styles.card}>
							<OrdersComp backEndData={allData?.orderData} />
						</div>
						<div className={styles.card}>
							<RevenueComp backEndData={allData?.revenueData} />
						</div>
						<div className={styles.pieCharts}>
							<div className={styles.pieCard}>
								<PieChart
									title={"Users"}
									backEndData={allData?.userChartData}
								/>
							</div>
							<div className={styles.halfCard}>
								{/* <PieChart title={"Orders"} backEndData={allData?.orderChartData} /> */}
								<div>Lifetime Orders</div>
								<div>{allData?.orderChartData[2]?.count}</div>
							</div>
						</div>
					</div>
					<div className={styles.recentTable}>
						<TableComp backEndData={allData?.orderTableData} />
					</div>
				</>
			)}
		</div>
	);
};
export default Dashboard;
