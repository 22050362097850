import { io } from "socket.io-client";
let { REACT_APP_BASE_URL } = process.env;

// "undefined" means the URL will be computed from the `window.location` object
// const URL = "http://3.88.185.24:8000";
const URL = "34.230.1.68:8000";

export const socket = io(URL, {
	transports: ["websocket"],
});
