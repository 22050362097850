import { useEffect } from "react";
import "./assets/App.module.css";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import WelcomeComp from "./pages/Welcome/WelcomeComp";
import Reset from "./pages/Welcome/Reset";

import LinkExpired from "./pages/Welcome/LinkExpired";
import Forgot from "./pages/Welcome/Forgot";
import Main from "./pages/Main/Main";
import Dashboard from "./pages/Dashboard/Dashboard";
import Orders from "./pages/Orders/Orders";
import OrderDetails from "./pages/OrderDetails/OrderDetails";
import Service from "./pages/Service/Service";
import Riders from "./pages/Riders/Riders";
import Chat from "./pages/Chat/Chat";
import Update from "./pages/Update/Update";
import Verify from "./pages/Welcome/Verify";
import Profile from "./pages/Profile/Profile";
import ImageCropProvider from "./utils/providers/ImageCropProvider";
import { pageUpdate } from "./utils/redux/features/pageSlice";
import { useSelector, useDispatch } from "react-redux";
import { onMessageListener } from "./utils/firestore/firebase-config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { getFullName } from "./utils/functions";
import Admin from "./pages/Admin/Admin";
import User from "./pages/User/User";
import NewAdmin from "./pages/Welcome/NewAdmin";
import UserDetails from "./pages/UserDetails/UserDetails";
import Registered from "./pages/Welcome/Registered";

function App() {
	const selector = useSelector((state) => state.authReducer.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const orderToast = (val) => {
		toast.info(
			() => (
				<div
					onClick={() => {
						if (val.chatObj.data.type === "newMessage") {
							navigate("/chat", {
								state: {
									[val.chatObj.data.with === "user" ? "orderID" : "riderID"]:
										val.chatObj.data.with === "user"
											? val.chatObj.data.orderId
											: val.chatObj.data.riderID,
									with: val.chatObj.data.with,
									userObj: {
										...val.chatObj.data.userObj,
										fullName: getFullName(val.chatObj.data.userObj),
									},
								},
							});
						}
					}}
				>
					<span style={{ fontWeight: "bold", fontSize: "0.875rem" }}>
						{val.title}
					</span>
					<br />
					<span style={{ fontSize: "0.8rem" }}>{val.body}</span>
					<br />
					{val.chatObj.data.type === "newMessage" && (
						<>
							<span
								style={{
									fontSize: "0.725rem",
									fontStyle: "italic",
									fontWeight: "light",
								}}
							>
								Click to view
							</span>
						</>
					)}
				</div>
			),
			{
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				transition: Flip,
			}
		);
	};

	useEffect(() => {
		const unsubscribe = onMessageListener((payload) => {
			orderToast({
				body: payload.notification.body,
				title: payload.notification.title,
				chatObj: JSON.parse(payload.data?.payload),
			});
		});

		return () => {
			unsubscribe();
		};
	}, []);

	useEffect(() => {
		const handleResize = () => {
			const windowWidth = window.innerWidth;
			const newFontVal = windowWidth <= 1240 ? false : true;
			dispatch(pageUpdate(newFontVal));
			localStorage.setItem(
				"pageSize",
				JSON.stringify({
					pageSize: newFontVal,
				})
			);
		};

		// Listen for window resize events
		window.addEventListener("resize", handleResize);

		// Initial call to set fontVal based on window width
		handleResize();

		// Cleanup function to remove the event listener when component unmounts
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<>
			<ToastContainer newestOnTop />
			{selector?.accessToken ? (
				<Routes>
					<Route exact path="/" element={<Main />}>
						<Route path="dashboard" element={<Dashboard />} />
						<Route path="orders" element={<Orders />}>
							<Route path="order_detail/:orderID" element={<OrderDetails />} />
						</Route>
						<Route path="users" element={<User />}>
							<Route path="user_detail/:userId" element={<UserDetails />} />
						</Route>
						{selector?.userType === "superAdmin" && (
							<Route path="admin" element={<Admin />} />
						)}
						{/* <Route path="users" element={<User />} /> */}
						<Route path="service" element={<Service />} />
						<Route path="riders" element={<Riders />} />
						<Route path="chat" element={<Chat />} />
						<Route
							path=""
							exact
							element={<Navigate replace to="/dashboard" />}
						/>
						<Route
							path="*"
							exact
							element={<Navigate replace to="/dashboard" />}
						/>
						<Route path="update" element={<Update />} />
						<Route
							path="profile"
							element={
								<ImageCropProvider>
									<Profile />
								</ImageCropProvider>
							}
						/>
					</Route>
				</Routes>
			) : (
				<Routes>
					<Route exact path="/" element={<WelcomeComp />} />
					<Route exact path="/forgot" element={<Forgot />} />
					<Route exact path="/reset" element={<Reset />} />
					<Route exact path="/register" element={<NewAdmin />} />
					<Route exact path="/verify" element={<Verify />} />
					<Route exact path="/expired" element={<LinkExpired />} />
					<Route exact path="/registered" element={<Registered />} />
					<Route path="" exact element={<Navigate replace to="/" />} />
					<Route path="*" exact element={<Navigate replace to="/" />} />
				</Routes>
			)}
		</>
	);
}

export default App;
