import React, { useState, useEffect } from "react";
import { Modal, Backdrop, Fade, Button, Radio } from "@mui/material";
import styles from "./ordersmodal.module.css";
import { setStatus } from "../../../utils/api/utilAPI";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { statusMap } from "../../../utils/constants";

const AssignModal = (props) => {
	const [statusSelect, setStatusSelect] = useState(null);

	const handleStatusSubmit = () => {
		setStatus({
			orderId: props.orderID,
			orderStatus: statusSelect,
		}).then((res) => {
			props.handleFetchData();
		});
		handleClose();
	};

	const handleClose = () => {
		setStatusSelect(null);
		props.setModal(false);
	};

	return (
		<div>
			<Modal
				open={props.modal}
				onClose={handleClose}
				closeAfterTransition
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Fade in={props.modal}>
					<div className={styles.fullModal}>
						<div className={styles.header}>
							<div>Change Status</div>
							<CloseRoundedIcon
								sx={{ cursor: "pointer" }}
								onClick={handleClose}
							/>
						</div>
						<div className={styles.body}>
							<div className={styles.bodyFirst}>
								<div className={styles.riders}>
									<div className={styles.riderTag} key={7}>
										<div className={styles.riderIN}>
											<div>{`${statusMap[7]}`}</div>
										</div>
										<Radio
											checked={statusSelect === 7}
											onChange={(e) => {
												setStatusSelect(7);
											}}
											value={7}
											name="radio-buttons"
										/>
									</div>
									<div className={styles.riderTag} key={8}>
										<div className={styles.riderIN}>
											<div>{`${statusMap[8]}`}</div>
										</div>
										<Radio
											checked={statusSelect === 8}
											onChange={(e) => {
												setStatusSelect(8);
											}}
											value={8}
											name="radio-buttons"
										/>
									</div>
								</div>
							</div>
							<div className={styles.submitButtonDiv}>
								<button
									className={styles.submitButton}
									onClick={handleStatusSubmit}
								>
									Change Status
								</button>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		</div>
	);
};

export default AssignModal;
