import { useState, useEffect } from "react";
import styles from "./dashboardcomp.module.css";
import moment from "moment/moment";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { dashboardGraph } from "../../../utils/api/utilAPI";
import { useSelector } from "react-redux";
import CircleIcon from "@mui/icons-material/Circle";
import { Skeleton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { months, days } from "../../../utils/constants";

const RevenueComp = (props) => {
	let {
		backEndData, //revenueData
	} = props;

	const pageSelector = useSelector((state) => state.pageReducer.pageSize);

	const [filter, setFilter] = useState("yearly");
	const [loading, setLoading] = useState(true);
	const [temp, setTemp] = useState(null);
	const [graphData, setGraphData] = useState(null);
	const [maxRevenue, setMaxRevenue] = useState(0); // this doesn't represent the actual data, just used to style the chart

	useEffect(() => {
		// first use will always be from backEndData
		makeTable(backEndData, filter);
	}, []);

	const makeTable = (rawData, currFilter) => {
		if (!rawData) return;
		const isYearly = currFilter === "yearly";
		let laundry = Array(isYearly ? 12 : 31).fill(0);
		let dryCleaning = Array(isYearly ? 12 : 31).fill(0);
		let both = Array(isYearly ? 12 : 31).fill(0);
		let laundryOrders = 0;
		let dryCleaningOrders = 0;
		let bothOrders = 0;
		let laundryRev = rawData[0]?.totalRevenue || 0;
		let dryCleaningRev = rawData[1]?.totalRevenue || 0;
		let bothRev = rawData[1]?.totalRevenue || 0;
		let tempMax = 0;

		// dryCleaning
		rawData[0]?.data?.forEach((item) => {
			let domainVal = isYearly
				? moment(item.createdAt, "YYYY-MM-DD").month()
				: moment(item.createdAt, "YYYY-MM-DD").date(); // double check whether this returns month 0-indexed
			let revenueVal = item.revenue;
			tempMax = revenueVal > tempMax ? revenueVal : tempMax;
			dryCleaning[domainVal] += revenueVal;
			dryCleaningOrders += item.count;
			both[domainVal] += revenueVal;
			bothOrders += item.count;
		});

		// laundry
		rawData[1]?.data?.forEach((item) => {
			let domainVal = isYearly
				? moment(item.createdAt, "YYYY-MM-DD").month()
				: moment(item.createdAt, "YYYY-MM-DD").date();
			let revenueVal = item.revenue;
			tempMax = revenueVal > tempMax ? revenueVal : tempMax;
			laundry[domainVal] += revenueVal;
			laundryOrders += item.count;
			both[domainVal] += revenueVal;
			bothOrders += item.count;
		});

		setTemp({
			laundry,
			dryCleaning,
			laundryRev,
			dryCleaningRev,
			laundryOrders,
			dryCleaningOrders,
			both,
			bothOrders,
			bothRev,
		});
		setMaxRevenue(tempMax === 0 ? 100 : tempMax * 1.05);

		setGraphData({
			labels: isYearly ? months : days,
			datasets: [
				// {
				// 	label: "Laundry",
				// 	data: laundry,
				// 	borderColor: "#0993CF",
				// 	backgroundColor: "rgba(9, 147, 207, 0.2)", // Use rgba for transparency
				// 	fill: true,
				// 	borderWidth: 2,
				// 	pointRadius: 0,
				// },
				// {
				// 	label: "Dry Cleaning",
				// 	data: dryCleaning,
				// 	borderColor: "#8354D1",
				// 	backgroundColor: "rgba(131, 84, 209, 0.2)", // Use rgba for transparency
				// 	fill: true,
				// 	borderWidth: 2,
				// 	pointRadius: 0,
				// },
				{
					label: "Orders",
					data: both,
					borderColor: "#8354D1",
					backgroundColor: "rgba(131, 84, 209, 0.2)", // Use rgba for transparency
					fill: true,
					borderWidth: 2,
					pointRadius: 0,
				},
			],
		});

		setLoading(false);
	};

	const handleFilter = (queryVal) => {
		setLoading(true);
		setFilter(queryVal);
		dashboardGraph({
			revenueQuery: queryVal,
		})
			.then((res) => {
				if (res && res?.status != 401) {
					makeTable(res?.data?.data?.revenueData, queryVal);
				} else {
					localStorage.removeItem("userData");
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	// Chart.js options
	const options = {
		showAllTooltips: true, // Show tooltips on hover
		plugins: {
			legend: {
				display: false, // Hide the legend
			},
			title: {
				display: false, // Hide the title
			},
		},
		tension: 0.5,
		scales: {
			x: {
				border: {
					display: false,
				},
				stacked: true,
				grid: {
					display: false,
				},
				axis: {
					display: false,
				},
				ticks: {
					font: {
						family: "Inter Tight, sans-serif",
						size: pageSelector ? 14 : 10,
						weight: 400,
					},
				},
			},
			y: {
				border: {
					display: false,
				},
				axis: {
					display: false,
				},
				ticks: {
					maxTicksLimit: 6,
					font: {
						family: "Inter Tight, sans-serif",
						size: pageSelector ? 14 : 10,
						weigth: 400,
					},
				},
				beginAtZero: true,
				suggestedMax: maxRevenue, // graph will show maximum revenue * 1.05 if max revenue is > 0
			},
		},
	};

	return (
		<>
			<div className={styles.header}>
				<div className={styles.cardTitle}> Revenue </div>
				<Select
					sx={{
						width: pageSelector ? "5.25rem" : "4.75rem",
						borderRadius: "8px",
						color: "#848484",
						height: pageSelector ? "1.5rem" : "1.25rem",
						textAlign: "left",
						fontSize: pageSelector ? "0.75rem" : "0.6rem",
						fontWeight: "400",
					}}
					variant="outlined"
					value={filter}
					onChange={(e) => handleFilter(e.target.value)}
					IconComponent={KeyboardArrowDownIcon}
				>
					<MenuItem value={"yearly"}>Yearly</MenuItem>
					<MenuItem value={"monthly"}>Monthly</MenuItem>
				</Select>
			</div>
			{!loading ? (
				<>
					<div className={styles.second}>
						<h1>${temp?.laundryRev + temp?.dryCleaningRev}</h1>
						<div className={styles.segregate}>
							<div className={styles.valuePair}>
								{/* <div style={{ display: "flex", gap: "5px" }}>
									<CircleIcon sx={{ width: "10%", color: "#0993CF" }} />
									<h2>Laundry</h2>
								</div>
								<div>
									<h1>{temp?.laundryOrders}</h1>
								</div> */}
							</div>
							<div className={styles.valuePair}>
								{/* <div style={{ display: "flex", gap: "5px" }}>
									<CircleIcon sx={{ width: "10%", color: "#8354D1" }} />
									<h2>Dry Cleaning</h2>
								</div>
								<div>
									<h1>{temp?.dryCleaningOrders}</h1>
								</div> */}
							</div>
						</div>
					</div>
					<Line className="line-graph" data={graphData} options={options} />
				</>
			) : (
				<Skeleton variant="rounded" width={"100%"} height={"85%"} />
			)}
		</>
	);
};
export default RevenueComp;
