// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
	getFirestore,
	query,
	getDocs,
	collection,
	where,
	addDoc,
} from "firebase/firestore";
import {
	getAuth,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	createUserWithEmailAndPassword, // rider and user app devs might need this
	signOut,
} from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { enableNotification } from "../api/authAPI";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
let { REACT_APP_API_KEY, REACT_APP_DATABASE_URL, REACT_APP_APP_ID } =
	process.env;
const firebaseConfig = {
	apiKey: REACT_APP_API_KEY,
	authDomain: "laundryapp-1d370.firebaseapp.com",
	databaseURL: REACT_APP_DATABASE_URL,
	projectId: "laundryapp-1d370",
	storageBucket: "laundryapp-1d370.appspot.com",
	messagingSenderId: "848244382955",
	appId: REACT_APP_APP_ID,
	measurementId: "G-W4N7VZWW90",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const roomsCollection = collection(db, "rooms");
export const picsCollection = collection(db, "profilePic");

export const logInFirebase = (email, password) => {
	// integrate into login page
	signInWithEmailAndPassword(auth, email, password);
};
export const logOutFirebase = () => {
	// integrate into log out button on profile section
	signOut(auth);
};
export const messaging = getMessaging(app);

const setDeviceToken = (permission) => {
	if (permission === "granted") {
		getToken(messaging, {
			vapidKey:
				"BH5i87HYt81DVsMclxeRc8C72hHIigprzNl_dvBhJFMHCc0YVJy0z1JE9i-xr_QgypxKg_aa_vGMdBQY3Os3dbY",
		}).then((currentToken) => {
			if (currentToken) {
				localStorage.setItem("FIREBASE_TOKEN", currentToken);
				const localUser = JSON.parse(localStorage.getItem("userData"));
				if (localUser !== null) {
					enableNotification({
						device_token: currentToken,
					});
				}
			}
		});
	}
};

export function requestPermission() {
	Notification.requestPermission().then((permission) => {
		setDeviceToken(permission);
	});
}
requestPermission();

const newSw = await navigator.serviceWorker.register(
	"/firebase-messaging-sw.js"
);
export const onMessageListener = (callback) => {
	return new Promise((resolve) => {
		const unsubscribe = onMessage(messaging, (payload) => {
			resolve(payload);
			if (typeof callback === "function") {
				callback(payload);
			}
		});

		return unsubscribe;
	});
};
