import { useState } from "react";
import styles from "./welcome.module.css";
import WelcomeTemplate from "./WelcomeTemplate";
import { Button, Box, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { verify_otp } from "../../utils/api/authAPI";

const Verify = () => {
	const location = useLocation();
	let { state } = location;
	const [otp, setotp] = useState("");
	const navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		// need to implement this in authAction and use redux toolkit
		verify_otp({ email: state.email, otp });
		navigate("/reset", { state: { email: state.email } });
	};

	return (
		<WelcomeTemplate
			inputComp={
				<div className={styles.welcomeComp}>
					<div className={styles.topText}>
						<div className={styles.welcome}>Enter OTP</div>
					</div>
					<form onSubmit={handleSubmit} className={styles.formMain}>
						<Box
							sx={{
								height: "56px",
								width: "361px",
								maxWidth: "100%",
							}}
						>
							<TextField
								InputProps={{ style: { borderRadius: "8px" } }}
								fullWidth
								label="OTP"
								id="otp"
								value={otp}
								onChange={(e) => {
									setotp(e.target.value);
								}}
							/>
						</Box>
						<Button
							type="submit"
							sx={{
								height: "56px",
								width: "361px",
								maxWidth: "100%",
								backgroundColor: "#0993CF",
								marginTop: "5%",
								textTransform: "none",
								fontFamily: "Inter Tight, sans-serif",
								fontWeight: "700",
								lineHeight: "24px",
								borderRadius: "8px",
							}}
							variant="contained"
						>
							Verify OTP
						</Button>
					</form>
				</div>
			}
		/>
	);
};
export default Verify;
