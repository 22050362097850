import React, { useState, useEffect } from "react";
import { Modal, Fade, Radio } from "@mui/material";
import styles from "./add.module.css";
import { addAdmin } from "../../../utils/api/utilAPI";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Loader from "../../ui/Loader/Loader";
import { getFullName } from "../../../utils/functions";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { toast } from "react-toastify";

const AssignModal = (props) => {
	const [loading, setLoading] = useState(false);

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");

	const [firstNameError, setFirstNameError] = useState(false);
	const [lastNameError, setLastNameError] = useState(false);
	const [emailError, setEmailError] = useState(0);
	const [phoneError, setPhoneError] = useState(false);

	// const [phoneNumber, setPhoneNumber] = useState("");
	const [country, setCountry] = useState("");

	const handlePhoneChange = (value) => {
		if (value) {
			const phoneNumberObj = parsePhoneNumberFromString(value);
			if (phoneNumberObj) {
				setCountry(`+${phoneNumberObj.countryCallingCode}`);
				setPhone(phoneNumberObj.nationalNumber);
			} else {
				setCountry("");
				setPhone("");
			}
		} else {
			setCountry("");
			setPhone("");
		}
	};

	// const [phoneNumber, setPhoneNumber] = useState("");
	// const [country, setCountry] = useState("");

	// const handlePhoneChange = (value) => {
	// 	setPhoneNumber(value); // full value

	// 	if (value) {
	// 		const phoneNumberObj = parsePhoneNumberFromString(value);
	// 		if (phoneNumberObj) {
	// 			setCountry(phoneNumberObj.country);
	// 			setPhone(phoneNumberObj.nationalNumber);
	// 		} else {
	// 			setCountry("");
	// 			setPhone("");
	// 		}
	// 	} else {
	// 		setCountry("");
	// 		setPhone("");
	// 	}
	// };
	// const fetchRider = () => {
	// 	setLoading(true);
	// 	getAllVerifiedRider()
	// 		.then((res) => {
	// 			setRiderArr(res?.data?.data);
	// 		})
	// 		.finally(() => {
	// 			setLoading(false);
	// 		});
	// };

	// const searchRider = (val) => {
	// 	setLoading(true);
	// 	searchVerifiedRider({ searchRider: val })
	// 		.then((res) => {
	// 			setRiderArr(res?.data?.data);
	// 		})
	// 		.finally(() => {
	// 			setLoading(false);
	// 		});
	// };

	const validation = () => {
		let isValid = true;

		// Check if first name is not empty
		if (!firstName.trim()) {
			setFirstNameError(true);
			setTimeout(() => setFirstNameError(false), 5000);
			isValid = false;
		}

		// Check if last name is not empty
		if (!lastName.trim()) {
			setLastNameError(true);
			setTimeout(() => setLastNameError(false), 5000);
			isValid = false;
		}

		// Check if email is not empty and is valid
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!email.trim()) {
			setEmailError(1);
			setTimeout(() => setEmailError(0), 5000);
			isValid = false;
		} else if (!emailPattern.test(email)) {
			setEmailError(2);
			setTimeout(() => setEmailError(0), 5000);
			isValid = false;
		}

		// Check if phone is not empty
		if (!phone.trim()) {
			setPhoneError(true);
			setTimeout(() => setPhoneError(false), 5000);
			isValid = false;
		}

		return isValid;
	};

	const postAdmin = () => {
		setLoading(true);
		let success = false;
		addAdmin({
			email,
			firstname: firstName,
			lastname: lastName,
			phone,
			country_code: country,
		})
			.then((res) => {
				props.handleFetchData();
				toast.success("Admin registered successfully!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				success = true;
			})
			.catch((err) => {
				console.log({ err }, "ZOHEB");

				toast.error(err.response.data.message, {
					position: toast.POSITION.TOP_RIGHT,
				});

				success = false;
			})
			.finally(() => {
				setLoading(false);
			});
		return success;
	};

	// useEffect(() => {
	// 	if (props.modal === true) {
	// 		fetchRider();
	// 	}
	// }, [props.modal]);

	const handleAdminSubmit = () => {
		if (validation()) {
			if (postAdmin()) handleClose();
		}
	};

	const handleClose = () => {
		props.setModal(false);
	};

	return (
		<Modal
			open={props.modal}
			onClose={handleClose}
			closeAfterTransition
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Fade in={props.modal}>
				<div className={styles.fullModal}>
					<div className={styles.header}>
						<div>Create Sub-Admin</div>
						<CloseRoundedIcon
							style={{ cursor: "pointer" }}
							onClick={handleClose}
						/>
					</div>
					<div className={styles.body}>
						<div className={styles.inputDiv}>
							<p>First Name</p>
							<input
								className={styles.riderInput}
								type="text"
								placeholder="Enter First Name"
								value={firstName}
								onChange={(e) => {
									setFirstName(e.target.value);
								}}
							/>
							{firstNameError && (
								<span className={styles.error}>Mandatory field*</span>
							)}
						</div>
						<div className={styles.inputDiv}>
							<p>Last Name</p>
							<input
								className={styles.riderInput}
								type="text"
								placeholder="Enter Last Name"
								value={lastName}
								onChange={(e) => {
									setLastName(e.target.value);
								}}
							/>
							{lastNameError && (
								<span className={styles.error}>Mandatory field*</span>
							)}
						</div>
						<div className={styles.inputDiv}>
							<p>Email</p>
							<input
								className={styles.riderInput}
								type="text"
								placeholder="Enter Email"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>
							{emailError === 1 && (
								<span className={styles.error}>Mandatory field*</span>
							)}
							{emailError === 2 && (
								<span className={styles.error}>Please enter valid email*</span>
							)}
						</div>
						<div className={styles.inputDiv}>
							{/* <p>Country Code</p>
							<input
								className={styles.riderInput}
								type="text"
								placeholder="Enter Phone Number"
								value={country}
								onChange={(e) => {
									setCountry(e.target.value);
								}}
							/> */}
							<PhoneInput
								value={country + phone}
								onChange={handlePhoneChange}
								international
								defaultCountry="US"
								className={styles.phoneNumberInputContainer}
								inputClassName={styles.phoneNumberInput}
							/>
							{phoneError && (
								<span className={styles.error}>Mandatory field*</span>
							)}
						</div>
						{/* <div className={styles.inputDiv}>
							<p>Phone Number</p>
							<input
								className={styles.riderInput}
								type="text"
								placeholder="Enter Phone Number"
								value={phone}
								onChange={(e) => {
									setPhone(e.target.value);
								}}
							/>
						</div> */}
						{/* <div className={styles.riders}>
							{loading ? (
								<div
									style={{
										height: "5rem",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<div style={{ height: "100%", aspectRatio: "1" }}>
										<Loader />
									</div>
								</div>
							) : (
								<>
									{riderArr?.map((item, index) => {
										return (
											<div
												className={styles.riderTag}
												key={index}
												onClick={() => {
													setRiderSelect({ index, item });
												}}
											>
												<div className={styles.riderIN}>
													<img
														className={styles.riderIMG}
														src={
															item.profileImage
																? item.profileImage
																: default_pfp
														}
														alt="Rider Profile Picture"
													/>
													<div>{getFullName(item)}</div>
												</div>
												<Radio
													checked={riderSelect?.index === index}
													onChange={(e) => {
														setRiderSelect({ index, item });
													}}
													value={{ index, item }}
													name="radio-buttons"
												/>
											</div>
										);
									})}
								</>
							)}
						</div> */}
						<div className={styles.submitButtonDiv}>
							<button
								className={`${styles.submitButton}`}
								onClick={() => {
									if (!loading) handleAdminSubmit();
								}}
							>
								{loading ? (
									<div
										style={{
											width: "2rem",
											aspectRatio: "1",
										}}
									>
										<Loader />
									</div>
								) : (
									<>Send Email</>
								)}
							</button>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default AssignModal;
