import styles from "./welcome.module.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import WelcomeTemplate from "./WelcomeTemplate";
import checkIMG from "../../assets/check_circle.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { forgotPassword } from "../../utils/api/authAPI";

const Forgot = () => {
	let location = useLocation();
	let { state } = location;

	// to navigate to OTP verification
	const navigate = useNavigate();

	const [linkSent, setLinkSent] = useState(false);

	return (
		<WelcomeTemplate
			inputComp={
				linkSent ? (
					<>
						<div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
							<div className={styles.forgotPasswordTitle}>Link Sent</div>
							<CheckCircleIcon style={{ color: "#0993CF" }} />
						</div>
						<div className={styles.forgotMessage}>
							Link sent to your {state.email} email address.
						</div>
					</>
				) : (
					<>
						<div className={styles.forgotPasswordTitle}>Forgot password</div>
						<div className={styles.forgotMessage}>
							We have sent a password reset link to your {state.email} email
							address.
						</div>
						<Button
							sx={{
								height: "56px",
								width: "361px",
								maxWidth: "100%",
								backgroundColor: "#0993CF",
								marginTop: "5%",
								textTransform: "none",
								fontFamily: "Inter Tight, sans-serif",
								fontWeight: "700",
								lineHeight: "24px",
								borderRadius: "8px",
							}}
							variant="contained"
							onClick={() => {
								setLinkSent(true);
								forgotPassword({ email: state.email });
								setTimeout(() => {
									navigate("/", { state: { email: state?.email } });
								}, 3000);
							}}
						>
							Send password reset link
						</Button>
					</>
				)
			}
		/>
	);
};
export default Forgot;
