import styles from "./profile.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Loader from "../../components/ui/Loader/Loader";
import { removeExtraSpaces } from "../../utils/functions"; // is this really necessary?
import { changePassword, editProfile } from "../../utils/api/utilAPI";
import { useSelector, useDispatch } from "react-redux";
import { FormControl, InputLabel } from "@mui/material";
import editImg from "../../assets/editImg.png";
import { useImageCropContext } from "../../utils/providers/ImageCropProvider";
import ImageCropModalContent from "../../components/form/Profile/ImageCropModalContent";
import { readFile } from "../../utils/functions";
import { toast } from "react-toastify";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
	isStrongPassword,
	prepareFormData,
	isValidName,
} from "../../utils/functions";
import default_pfp from "../../assets/default_pfp.png";
import { loginSuccess } from "../../utils/redux/features/authSlice";

const Profile = () => {
	const { getProcessedImage, setImage, resetStates } = useImageCropContext();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const selector = useSelector((state) => state.authReducer.user);
	const [loading, setLoading] = useState(false);

	const [editPro, setEditPro] = useState(false);
	const [loadPro, setLoadPro] = useState(false);
	const [editPass, setEditPass] = useState(false);
	const [loadPass, setLoadPass] = useState(false);

	const [firstName, setFirstName] = useState(selector.firstname);
	const [lastName, setLastName] = useState(selector.lastname);
	const [newFull, setNewFull] = useState(
		`${selector.firstname} ${selector.lastname}`
	);
	const [newFullError, setNewFullError] = useState(false);
	const [pfp, setPFP] = useState(selector.profileImage);
	const [pfpFile, setPFPFile] = useState(null);

	const [current, setCurrent] = useState("");
	const [showCurr, setShowCurr] = useState(false);
	const [currError, setCurrError] = useState(false);

	const [newFirst, setNewFirst] = useState("");
	const [showFirst, setShowFirst] = useState(false);
	const [firstError, setFirstError] = useState(false);

	const [newSecond, setNewSecond] = useState("");
	const [showSecond, setShowSecond] = useState(false);
	const [secondError, setSecondError] = useState(false);

	const [openModal, setOpenModal] = useState(false);

	const toastHandler = (type) => {
		// 1. wrong current password
		// 2. newFirst !== newSecond
		// 3. newFirst && newSecond don't follow regex requirement
		// 4. password updated successfuly
		// 5. full name incorrect format

		if (type === 1) {
			toast.error("Incorrect password!", {
				position: toast.POSITION.TOP_RIGHT,
			});
			setCurrError(true);
			setTimeout(() => {
				setCurrError(false);
			}, 3000);
		} else if (type === 2 || type === 3) {
			setFirstError(true);
			setSecondError(true);
			setTimeout(() => {
				setFirstError(false);
				setSecondError(false);
			}, 3000);

			if (type === 2) {
				toast.error("Please make sure your passwords match.", {
					position: toast.POSITION.TOP_RIGHT,
				});
			} else if (type === 3) {
				toast.error(
					() => (
						<div>
							Passwords must follow the below rules:
							<br />
							- Length between 8 to 36 characters
							<br />
							- Include one letter
							<br />
							- Include one digit
							<br />- Include one special character
						</div>
					),
					{
						position: toast.POSITION.TOP_RIGHT,
					}
				);
			}
		} else if (type === 4) {
			toast.success("Password updated successfully!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else if (type === 5) {
			toast.error(
				"Please ensure that your full name consists of only a first name and a last name.",
				{
					position: toast.POSITION.TOP_RIGHT,
				}
			);
			setNewFullError(true);
			setTimeout(() => setNewFullError(false), 3000);
		}
	};

	const handleUpdatePassword = () => {
		if (newFirst !== newSecond) toastHandler(2);
		else if (!isStrongPassword(newFirst)) toastHandler(3);
		else {
			setLoadPass(true);
			changePassword({ currentPassword: current, newPassword: newFirst })
				.then((res) => {
					if (!res.status) {
						toastHandler(1);
					} else {
						setEditPass(false);
						toastHandler(4);
						setCurrent("");
						setNewFirst("");
						setNewSecond("");
					}
				})
				.finally(() => setLoadPass(false));
		}
	};

	const handleDone = async () => {
		const avatar = await getProcessedImage();
		setPFPFile(avatar);
		setPFP(window.URL.createObjectURL(avatar));
		resetStates();
		setOpenModal(false);
	};

	const handleFileChange = async ({ target: { files } }) => {
		const file = files && files[0];
		if (file) {
			const imageDataUrl = await readFile(file);
			setImage(imageDataUrl);
			setOpenModal(true);
		}
	};

	const handleEditProfile = () => {
		const corrected = removeExtraSpaces(newFull);
		if (!isValidName(corrected)) {
			toastHandler(5);
			return;
		}

		setLoadPro(true);

		const formData = prepareFormData(corrected, pfpFile);

		editProfile(formData)
			.then((res) => {
				dispatch(
					loginSuccess({
						email: res?.data?.data?.email,
						accessToken: selector.accessToken,
						firstname: res?.data?.data?.firstname,
						lastname: res?.data?.data?.lastname,
						profileImage: res?.data?.data?.profileImage,
					})
				);
				localStorage.setItem(
					"userData",
					JSON.stringify({
						email: res?.data?.data?.email?.mail,
						accessToken: selector.accessToken,
						firstname: res?.data?.data?.firstname,
						lastname: res?.data?.data?.lastname,
						profileImage: res?.data?.data?.profileImage,
					})
				);
				setFirstName(res?.data?.data?.firstname);
				setLastName(res?.data?.data?.lastname);
				setEditPro(false);
			})
			.finally(() => {
				setLoadPro(false);
			});
	};

	return loading ? (
		<div
			style={{
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div style={{ height: "10%", aspectRatio: "1" }}>
				<Loader />
			</div>
		</div>
	) : (
		<div className={styles.fullPage}>
			<div className={styles.header}>
				<ArrowBackRoundedIcon
					fontSize="small"
					sx={{
						cursor: "pointer",
					}}
					onClick={() => {
						navigate(-1);
					}}
				/>
				Profile
			</div>
			<div className={styles.body}>
				<div className={styles.details}>
					<div className={styles.card}>
						<div className={styles.rowTitle}>
							<span className={styles.titleFont}>Profile Details</span>
							{!editPro && (
								<span
									className={styles.editButton}
									onClick={() => setEditPro(true)}
								>
									Edit Profile
								</span>
							)}
						</div>
						<div
							style={{
								borderBottom: "1px solid #f2f2f2",
								padding: "1rem 0",
							}}
						>
							<div>
								{editPro ? (
									<>
										<input
											type="file"
											onChange={handleFileChange}
											className={styles.hidden}
											id="avatarInput"
											accept="image/*"
											display="none"
										/>
										<label htmlFor="avatarInput" className={styles.imageLabel}>
											<img
												style={{
													position: "relative",
													top: "0",
													left: "0",
													borderRadius: "50%",
												}}
												src={pfp ? pfp : default_pfp}
												height={192}
												width={192}
												alt=""
											/>
											<img src={editImg} className={styles.imgIcon} />
										</label>
									</>
								) : (
									<>
										<img
											style={{ borderRadius: "50%" }}
											src={pfp ? pfp : default_pfp}
											height={192}
											width={192}
											alt=""
										/>
									</>
								)}
							</div>
						</div>
						<div className={styles.nameEmail}>
							<div className={styles.pair} style={{ width: "50%" }}>
								{editPro ? (
									<FormControl
										className={newFullError ? styles.shake : ""}
										sx={{ flex: "1" }}
										variant="outlined"
									>
										<InputLabel htmlFor="outlined-adornment-password">
											Enter full name
										</InputLabel>
										<OutlinedInput
											label="Enter full name"
											sx={{
												borderRadius: "8px",
											}}
											id="full-name"
											type="text"
											fullWidth
											value={newFull}
											onChange={(e) => {
												setNewFull(e.target.value);
											}}
										/>
									</FormControl>
								) : (
									<>
										<span className={styles.valueTitle}>Name</span>
										<span>{`${firstName} ${lastName}`}</span>
									</>
								)}
							</div>
							<div className={styles.pair} style={{ width: "50%" }}>
								<span className={styles.valueTitle}>Email Address</span>
								<span>{selector.email}</span>
							</div>
						</div>
						{editPro && (
							<div className={styles.submitContainer}>
								<button
									onClick={() => {
										setPFPFile(null);
										setPFP(selector.profileImage);
										setEditPro(false);
									}}
									className={`${styles.button} ${styles.cancel}`}
								>
									Cancel
								</button>
								<button
									onClick={handleEditProfile}
									className={`${styles.button} ${styles.submit}`}
								>
									{loadPro ? (
										<div
											style={{
												height: "100%",
												aspectRatio: "1",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<Loader />
										</div>
									) : (
										<>Save</>
									)}
								</button>
							</div>
						)}
					</div>
				</div>
				<div className={styles.settings}>
					<div
						className={styles.card}
						style={{ justifyContent: "space-between" }}
					>
						<div className={styles.rowTitle}>
							<span className={styles.titleFont}>Account Settings</span>
							{editPass ? null : (
								<span
									className={styles.editButton}
									onClick={() => {
										setEditPass(true);
									}}
								>
									Update password
								</span>
							)}
						</div>
						<div style={{ paddingTop: "1rem" }}>
							{editPass ? (
								<>
									<div className={styles.passFirst}>
										<FormControl
											className={currError ? styles.shake : ""}
											sx={{ flex: "1" }}
											variant="outlined"
										>
											<InputLabel htmlFor="outlined-adornment-password">
												Current password
											</InputLabel>
											<OutlinedInput
												label="Current password"
												sx={{
													borderRadius: "8px",
												}}
												id="current-password"
												type={showCurr ? "text" : "password"}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={() => setShowCurr((curr) => !curr)}
															onMouseDown={(e) => e.preventDefault()}
															edge="end"
														>
															{showCurr ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
												fullWidth
												value={current}
												onChange={(e) => {
													setCurrent(e.target.value);
												}}
											/>
										</FormControl>
										<FormControl
											className={firstError ? styles.shake : ""}
											sx={{ flex: "1" }}
											variant="outlined"
										>
											<InputLabel htmlFor="outlined-adornment-password">
												New password
											</InputLabel>
											<OutlinedInput
												label="New password"
												sx={{
													borderRadius: "8px",
												}}
												id="new-password"
												type={showFirst ? "text" : "password"}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={() => setShowFirst((curr) => !curr)}
															onMouseDown={(e) => e.preventDefault()}
															edge="end"
														>
															{showFirst ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
												fullWidth
												value={newFirst}
												onChange={(e) => {
													setNewFirst(e.target.value);
												}}
											/>
										</FormControl>
									</div>
									<div className={styles.passFirst}>
										<FormControl
											className={secondError ? styles.shake : ""}
											sx={{ flex: "0.5" }}
											variant="outlined"
										>
											<InputLabel htmlFor="outlined-adornment-password">
												Confirm new password
											</InputLabel>
											<OutlinedInput
												label="Confirm new password"
												sx={{
													borderRadius: "8px",
												}}
												id="confirm-new-password"
												type={showSecond ? "text" : "password"}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={() => setShowSecond((curr) => !curr)}
															onMouseDown={(e) => e.preventDefault()}
															edge="end"
														>
															{showSecond ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
												fullWidth
												value={newSecond}
												onChange={(e) => {
													setNewSecond(e.target.value);
												}}
											/>
										</FormControl>
										<div />
									</div>
									<div className={styles.submitContainer}>
										<button
											onClick={() => {
												setCurrent("");
												setNewFirst("");
												setNewSecond("");
												setEditPass(false);
											}}
											className={`${styles.button} ${styles.cancel}`}
										>
											Cancel
										</button>
										<button
											onClick={handleUpdatePassword}
											className={`${styles.button} ${styles.submit}`}
										>
											{loadPass ? (
												<div
													style={{
														height: "100%",
														aspectRatio: "1",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}
												>
													<Loader />
												</div>
											) : (
												<>Save</>
											)}
										</button>
									</div>
								</>
							) : (
								<div className={styles.pair}>
									<span className={styles.valueTitle}>Password</span>
									<span>**********</span>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<ImageCropModalContent
				modal={openModal}
				handleDone={handleDone}
				handleClose={() => setOpenModal(false)}
			/>
		</div>
	);
};
export default Profile;
