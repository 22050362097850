import EasyCropper from "react-easy-crop";
import { useImageCropContext } from "../../../../utils/providers/ImageCropProvider";

const Cropper = () => {
	const {
		image,
		zoom,
		setZoom,
		rotation,
		setRotation,
		crop,
		setCrop,
		onCropComplete,
	} = useImageCropContext();

	return (
		<EasyCropper
			image={image || undefined}
			crop={crop}
			zoom={zoom}
			rotation={rotation}
			cropShape="round"
			aspect={1}
			onCropChange={setCrop}
			onCropComplete={onCropComplete}
			onZoomChange={setZoom}
			setRotation={setRotation}
			showGrid={false}
			cropSize={{ width: 185, height: 185 }}
			style={{
				containerStyle: {
					position: "relative",
					height: "15rem",
					width: "15rem",
				},
			}}
		/>
	);
};

export default Cropper;
