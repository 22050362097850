import React, { useState } from "react";
import { Modal, Fade } from "@mui/material";
import styles from "./servicesmodal.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { editCategoryName } from "../../../utils/api/utilAPI";

const EditCategoryName = (props) => {
	const [newName, setNewName] = useState("");

	const handleNameSubmit = () => {
		editCategoryName({
			// serviceType: props.oldCategory.serviceType,
			serviceType: "dryCleaning",
			categoryId: props.oldCategory.categoryId,
			oldCategoryName: props.oldCategory.categoryName,
			newCategoryName: newName.trim(),
		}).finally(() => {
			props.handleFetchData();
		});
		handleClose();
	};

	const handleClose = () => {
		props.setModal(false);
	};

	return (
		<div>
			<Modal
				open={props.modal}
				onClose={handleClose}
				closeAfterTransition
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Fade in={props.modal}>
					<div className={styles.fullModal}>
						<div className={styles.header}>
							<div>Edit Service Category</div>
							<CloseRoundedIcon
								sx={{ cursor: "pointer" }}
								onClick={handleClose}
							/>
						</div>
						<div className={styles.body}>
							<div className={styles.bodyFirst}>Edit name</div>
							<input
								className={styles.inputBar}
								type="text"
								placeholder="Enter service name"
								value={newName}
								onChange={(e) => {
									setNewName(e.target.value);
								}}
							/>
							<div
								style={{
									marginTop: "4%",
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									gap: "2%",
								}}
							>
								<button
									className={styles.button}
									style={{
										backgroundColor: "#FFFFFF",
										color: "#848484",
										border: "1px solid #cccccc",
									}}
									onClick={handleClose}
								>
									Cancel
								</button>
								<button
									className={styles.button}
									style={{
										backgroundColor: "#0993cf",
										color: "#ffffff",
										border: "none",
									}}
									onClick={handleNameSubmit}
								>
									Save changes
								</button>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		</div>
	);
};

export default EditCategoryName;
