import styles from "./starcount.module.css";
import gold_star from "../../../assets/gold_star.svg";
import grey_star from "../../../assets/grey_star.svg";

const StarCount = ({ count }) => {
	const totalStars = Array.from({ length: 5 });
	return (
		<div className={styles.row}>
			{totalStars.map((_, index) => {
				// If index is less than count, display gold_star, otherwise display grey_star
				return index < count ? (
					<img
						key={index}
						src={gold_star}
						alt="gold star"
						className={styles.star}
					/>
				) : (
					<img
						key={index}
						src={grey_star}
						alt="grey star"
						className={styles.star}
					/>
				);
			})}
		</div>
	);
};
export default StarCount;
