import { createSlice } from "@reduxjs/toolkit";

const pageSize = JSON.parse(localStorage.getItem("pageSize"));

const pageSlice = createSlice({
	name: "page",
	initialState: {
		pageSize: pageSize?.pageSize,
	},
	reducers: {
		pageUpdate: (state, action) => {
			state.pageSize = action.payload;
		},
	},
});

export const { pageUpdate } = pageSlice.actions;

export default pageSlice.reducer;
