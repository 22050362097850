import styles from "./updatemodal.module.css";
import React, { useState, useEffect } from "react";
import { Modal, Backdrop, Fade, Button, Radio } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { printUpdateValue } from "../../../utils/functions";
import { confirmRequest } from "../../../utils/api/utilAPI";

const UpdateModal = (props) => {
	const nameArr = {
		email: "Email Address",
		phone: "Phone Number",
		yearOfExperience: "Year(s) of Experience",
		name: "Name",
		isVerifiedRider: "Rider Registration Status",
		address: "Address",
	};

	const handleClose = () => {
		props.setModal(false);
	};

	const postUpdate = () => {
		handleClose();
		confirmRequest({
			rider: props.reqDetails?.rider,
			requestId: props.reqDetails?.requestId,
			requestName: props.reqDetails?.requestName,
			requestStatus: "new",
		}).then((res) => {
			props.handleFetchData();
		});
	};

	return (
		<div>
			<Modal
				open={props.modal}
				onClose={handleClose}
				closeAfterTransition
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Fade in={props.modal}>
					<div className={styles.fullModal}>
						{props.reqDetails && (
							<>
								<div className={styles.header}>
									<div>Update Request</div>
									<CloseRoundedIcon
										style={{ cursor: "pointer" }}
										onClick={handleClose}
									/>
								</div>
								<div className={styles.body}>
									<div className={styles.pair}>
										<span style={{ color: "#848484" }}>
											{`Old ${nameArr[props.reqDetails?.requestName]}`}
										</span>
										<span>
											{printUpdateValue(
												props.reqDetails?.requestName,
												props.reqDetails?.olderDetails
											)}
										</span>
									</div>
									<div className={styles.pair}>
										<span style={{ color: "#848484" }}>
											{`New ${nameArr[props.reqDetails?.requestName]}`}
										</span>
										<span style={{ color: "#0993CF" }}>
											{printUpdateValue(
												props.reqDetails?.requestName,
												props.reqDetails?.newDetails
											)}
										</span>
									</div>
									<div className={styles.submitButtonDiv}>
										<button
											className={`${styles.button} ${styles.cancelButton}`}
											onClick={handleClose}
										>
											Cancel
										</button>
										<button
											className={`${styles.button} ${styles.submitButton}`}
											onClick={postUpdate}
										>
											Confirm
										</button>
									</div>
								</div>
							</>
						)}
					</div>
				</Fade>
			</Modal>
		</div>
	);
};

export default UpdateModal;
