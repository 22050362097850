let { REACT_APP_BASE_URL } = process.env;

let API_URL = REACT_APP_BASE_URL + "/api/";
// let API_URL = "http://3.88.185.24:8000/api/";
let ADMIN_API_URL = "";
let AUTHORIZATION_HEADER = "authorization";
let AUTHTOKEN = "STARClean";
let DEVICE_TOKEN = "device_token";
let DEVICE_TOKEN_HEADER = "device_token";
// let IMAGE_BASE_URL = "https://d4pu8dswj8fj3.cloudfront.net/";
export {
	API_URL,
	ADMIN_API_URL,
	AUTHORIZATION_HEADER,
	AUTHTOKEN,
	DEVICE_TOKEN,
	DEVICE_TOKEN_HEADER,
	// IMAGE_BASE_URL,
};
