import styles from "./welcome.module.css";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WelcomeTemplate from "./WelcomeTemplate";
import CancelIcon from "@mui/icons-material/Cancel";

const LinkExpired = () => {
	let location = useLocation();
	let { state } = location;

	// to navigate to OTP verification
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			navigate("/", { state: { email: state?.email } });
		}, 5000);
	}, []);

	return (
		<WelcomeTemplate
			inputComp={
				<>
					<div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
						<div className={styles.forgotPasswordTitle}>Already Registered</div>
						<CancelIcon style={{ color: "#E73F3F" }} />
					</div>
					<div className={styles.forgotMessage}>
						Admin already registered. Please login. Redirecting...
					</div>
				</>
			}
		/>
	);
};
export default LinkExpired;
