import React, { useState, useEffect, forwardRef } from "react";
import Popper from "@mui/material/Popper";
import { Modal, Backdrop, Fade, Button, Radio } from "@mui/material";
import styles from "./profile.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { editCategoryName } from "../../../utils/api/utilAPI";
import { useDispatch, useSelector } from "react-redux";
import {
	loginRequest,
	loginSuccess,
	updateProfileData,
	sessionTimeout,
	logoutSuccess,
} from "../../../utils/redux/features/authSlice";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../utils/api/authAPI";

const Profile = forwardRef((props, ref) => {
	const navigate = useNavigate();
	const selector = useSelector((state) => state.authReducer.user);
	const pageSelector = useSelector((state) => state.pageReducer.pageSize);
	const dispatch = useDispatch();
	const handleLogout = () => {
		logout().finally(() => {
			dispatch(logoutSuccess());
			localStorage.setItem("userData", null);
			localStorage.removeItem("FIREBASE_TOKEN");
		});
	};

	const handleProfile = () => {
		navigate("/profile");
		props.setOpen(false);
	};

	const [fontVal, setFontVal] = useState(20);

	return (
		<Popper {...props} ref={ref}>
			<Fade in={props.open} timeout={500}>
				<div className={styles.fullModal}>
					<div
						className={styles.button}
						style={{ borderBottom: "1px solid #F2F2F2" }}
						onClick={handleProfile}
					>
						<PersonIcon
							sx={{ color: "#0993CF", fontSize: pageSelector ? 15 : 25 }}
						/>
						Profile
					</div>
					<div className={styles.button} onClick={handleLogout}>
						<LogoutIcon
							sx={{ color: "#E73F3F", fontSize: pageSelector ? 15 : 25 }}
						/>
						Logout
					</div>
				</div>
			</Fade>
		</Popper>
	);
});
export default Profile;
