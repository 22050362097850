import { useState, useEffect } from "react";
import CreateIcon from "@mui/icons-material/Create";
import styles from "./nameedit.module.css";

const NameEdit = ({ index, value, handleStringChange }) => {
	const [editable, setEditable] = useState(false);

	return (
		<>
			{editable ? (
				<div className={styles.subCatEdit}>
					<div style={{ color: "#1C1C1C" }}>
						<input
							className={styles.subCatInput}
							value={value}
							onChange={(e) => handleStringChange(index, e.target.value)}
						/>
					</div>
					<div
						onClick={() => setEditable(false)}
						style={{ color: "#0993CF", cursor: "pointer" }}
					>
						Save
					</div>
				</div>
			) : (
				<div className={styles.subCatName}>
					<div style={{ marginRight: "2rem" }}>{value}</div>
					<div>
						<CreateIcon
							sx={{ width: "1rem", cursor: "pointer" }}
							onClick={() => setEditable(true)}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default NameEdit;
