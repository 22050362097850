import axios from "axios";
import { API_URL } from "../configs";
import { turnTrue } from "../redux/features/popUpSlice";
import { store } from "../redux/store";

const validError = [
	"Your email or password is wrong",
	"Your current password is incorrect. Please double-check and try again.",
	"No user found",
	"Category already exists", //if and when category already exists
	"", //if and when sub-category already exists
	"Your account has been blocked, please contact super admin for support!",
	"Joining link already sent successfully",
];

const fetchClient = () => {
	let instance = axios.create({
		baseURL: API_URL,
	});

	instance.interceptors.request.use((config) => {
		const user = JSON.parse(localStorage.getItem("userData"));
		if (user?.hasOwnProperty("accessToken")) {
			config.headers.authorization = user["accessToken"];
		}
		return config;
	});

	instance.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			console.log("ERROR IN AXIOS CONFIG", error);
			// handle log in and update password
			if (
				error.response.data.status !== 404 &&
				validError.indexOf(error.response.data.message) === -1
			) {
				// only possibility is that accessToken has expired
				store.dispatch(turnTrue());
				// localStorage.setItem("userData", null);
			} else {
				console.log("DON'T LOG OUT THE SESSION", error);
			}
			// Reject the promise to propagate the error
			return Promise.reject(error);
		}
	);

	return instance;
};

export default fetchClient();
