import React, { useState, useEffect } from "react";
import { Modal, Fade, Radio } from "@mui/material";
import styles from "./ordersmodal.module.css";
import {
	getAllVerifiedRider,
	searchVerifiedRider,
	assignRider,
} from "../../../utils/api/utilAPI";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import default_pfp from "../../../assets/default_pfp.png";
import Loader from "../../ui/Loader/Loader";
import { getFullName } from "../../../utils/functions";

const AssignModal = (props) => {
	const [loading, setLoading] = useState(true);

	const [riderSearch, setRiderSearch] = useState("");
	const [riderArr, setRiderArr] = useState([]);
	const [riderSelect, setRiderSelect] = useState(null);

	const fetchRider = () => {
		setLoading(true);
		getAllVerifiedRider()
			.then((res) => {
				setRiderArr(res?.data?.data);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const searchRider = (val) => {
		setLoading(true);
		searchVerifiedRider({ searchRider: val })
			.then((res) => {
				setRiderArr(res?.data?.data);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const postRider = () => {
		assignRider({
			orderId: props.orderDetails.orderID,
			riderId: riderSelect?.item?._id,
			orderStatus: props.orderDetails.orderStatus < 3 ? 3 : 9, // hoping we never receive anything other than just 1 or 2 or 8
		}).then((res) => {
			props.handleFetchData();
		});
	};

	useEffect(() => {
		if (props.modal === true) {
			fetchRider();
		}
	}, [props.modal]);

	const handleRiderSubmit = () => {
		postRider();
		handleClose();
	};

	const handleClose = () => {
		setRiderSelect(null);
		props.setModal(false);
	};

	return (
		<Modal
			open={props.modal}
			onClose={handleClose}
			closeAfterTransition
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Fade in={props.modal}>
				<div className={styles.fullModal}>
					<div className={styles.header}>
						<div>Assign Rider</div>
						<CloseRoundedIcon
							style={{ cursor: "pointer" }}
							onClick={handleClose}
						/>
					</div>
					<div className={styles.body}>
						<div className={styles.inputDiv}>
							<input
								className={styles.riderInput}
								type="text"
								placeholder="Search rider"
								value={riderSearch}
								onChange={(e) => {
									setRiderSearch(e.target.value);
									searchRider(e.target.value);
								}}
							/>
						</div>
						<div className={styles.riders}>
							{loading ? (
								<div
									style={{
										height: "5rem",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<div style={{ height: "100%", aspectRatio: "1" }}>
										<Loader />
									</div>
								</div>
							) : (
								<>
									{riderArr?.map((item, index) => {
										return (
											<div
												className={styles.riderTag}
												key={index}
												onClick={() => {
													setRiderSelect({ index, item });
												}}
											>
												<div className={styles.riderIN}>
													<img
														className={styles.riderIMG}
														src={
															item.profileImage
																? item.profileImage
																: default_pfp
														}
														alt="Rider Profile Picture"
													/>
													<div>{getFullName(item)}</div>
												</div>
												<Radio
													checked={riderSelect?.index === index}
													onChange={(e) => {
														setRiderSelect({ index, item });
													}}
													value={{ index, item }}
													name="radio-buttons"
												/>
											</div>
										);
									})}
								</>
							)}
						</div>
						<div className={styles.submitButtonDiv}>
							<button
								className={`${styles.submitButton} ${
									loading && styles.hiddenButton
								}`}
								onClick={() => {
									if (!loading) handleRiderSubmit();
								}}
							>
								Assign Rider
							</button>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default AssignModal;
