const Card = (props) => {
    let {
        isLoading
    } = props;

    return (
        <div style={{height: "-webkit-fill-available", backgroundColor: "#FFFFFF", borderRadius: "8px", padding: "32px"}}>
            {isLoading ? <>LOADING</> : props.children}
        </div>
    )
}
export default Card;