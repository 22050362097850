import { wrappedFetch, wrappedGet } from "./urlFuncs";
import {
	ORDER_TABLE,
	DASHBOARD_URL,
	ORDER_BY_ID,
	SEARCH_ORDER,
	GET_RIDERS,
	ASSIGN_RIDER,
	SET_STATUS,
	GET_CATEGORY_DATA,
	EDIT_CATEGORY_NAME,
	HIDE_CATEGORY,
	GET_SUBCATEGORY,
	ADD_SERVICE_CATEGORY,
	EDIT_LAUNDRY_PRICE,
	ADD_SUBCATEGORY,
	HIDE_SUBCATEGORY,
	EDIT_SUBCATEGORY,
	GET_ALL_RIDER,
	RIDER_BY_STATUS,
	SEARCH_RIDER,
	BLOCKED_RIDER,
	GET_ALL_REQUESTS,
	GET_VERIFIED_RIDERS,
	SEARCH_VERIFIED_RIDERS,
	CONFIRM_REQUEST,
	SEARCH_REQUEST,
	CHANGE_PASSWORD,
	EDIT_PROFILE,
	USER_NOTIFICATION,
	RIDER_NOTIFICATION,
	SEND_NOTIFICATION,
	FETCH_SUBADMIN,
	SEARCH_SUBADMIN,
	STATUS_SUBADMIN,
	BLOCK_SUBADMIN,
	ADD_SUBADMIN,
	PASSWORD_SUBADMIN,
	FETCH_ACTIVE,
	FETCH_INACTIVE,
	USER_BY_ID,
	SEARCH_USER,
} from "./urlConstants";
import { DEVICE_TOKEN } from "../configs";

export const dashboardGraph = (data) => {
	const { orderQuery, revenueQuery, userChartQuery, orderChartQuery } = data;
	return wrappedGet(DASHBOARD_URL, "GET", {
		orderQuery,
		revenueQuery,
		userChartQuery,
		orderChartQuery,
	});
};

export const ordersTable = (data) => {
	const { page, pageLimit, status } = data;

	return wrappedGet(ORDER_TABLE, "GET", {
		page,
		pageLimit,
		status,
	});
};

export const getOrderByID = (data) => {
	// orderID
	return wrappedGet(ORDER_BY_ID, "GET", { orderId: data.orderID });
};

export const searchOrder = (data) => {
	return wrappedGet(SEARCH_ORDER, "GET", { orderId: data.orderId });
};

export const getAllVerifiedRider = (data) => {
	return wrappedGet(GET_VERIFIED_RIDERS, "GET");
};

export const searchVerifiedRider = (data) => {
	return wrappedGet(SEARCH_VERIFIED_RIDERS, "GET", {
		riderName: data.searchRider,
	});
};

export const getRiders = () => {
	return wrappedGet(GET_RIDERS, "GET");
};

export const assignRider = (data) => {
	return wrappedFetch(ASSIGN_RIDER, "POST", data);
};

export const setStatus = (data) => {
	return wrappedFetch(SET_STATUS, "POST", data);
};

export const addServiceCategory = (data) => {
	return wrappedFetch(ADD_SERVICE_CATEGORY, "POST", { ...data });
};

export const editLaundryPrice = (data) => {
	return wrappedFetch(EDIT_LAUNDRY_PRICE, "POST", { ...data });
};

export const getCategoryData = (data) => {
	return wrappedGet(GET_CATEGORY_DATA, "GET");
};

export const editCategoryName = (data) => {
	return wrappedFetch(EDIT_CATEGORY_NAME, "POST", {
		...data,
	});
};

export const hideCategory = (data) => {
	return wrappedFetch(HIDE_CATEGORY, "POST", {
		...data,
	});
};

export const getSubCategoryData = (data) => {
	return wrappedGet(GET_SUBCATEGORY, "GET", { ...data });
};

export const addSubCategory = (data) => {
	return wrappedFetch(ADD_SUBCATEGORY, "POST", data);
};

export const hideSubCategory = (data) => {
	return wrappedFetch(HIDE_SUBCATEGORY, "POST", {
		isHide: true,
		...data,
	});
};

export const editSubCategory = (data) => {
	return wrappedFetch(EDIT_SUBCATEGORY, "POST", [...data]);
};

// Rider

export const getAllRider = () => {
	return wrappedGet(GET_ALL_RIDER, "GET");
};

export const getRiderByStatus = (data) => {
	//active or blocked
	return wrappedGet(RIDER_BY_STATUS, "GET", data);
};

export const searchRider = (data) => {
	return wrappedGet(SEARCH_RIDER, "GET", data);
};

export const blockedRider = (data) => {
	// riderId, isBlocked
	return wrappedFetch(BLOCKED_RIDER, "POST", { ...data });
};

//Update Requests
export const getRequests = () => {
	return wrappedGet(GET_ALL_REQUESTS, "GET");
};

export const confirmRequest = (data) => {
	//rider = _id
	//requestId
	//requestName
	//requestStatus (new or rejected)
	return wrappedFetch(CONFIRM_REQUEST, "POST", { ...data });
};

export const searchRequest = (data) => {
	// val is name in string
	return wrappedGet(SEARCH_REQUEST, "GET", { ...data });
};

export const changePassword = (data) => {
	// currentPassword, newPassword
	return wrappedFetch(CHANGE_PASSWORD, "POST", { ...data });
};

export const editProfile = (data) => {
	// fullname, firstname, lastname, profileImage
	return wrappedFetch(EDIT_PROFILE, "POST", data, {
		"Content-Type": "multipart/form-data",
	});
};

export const sendChatNotif = ({ type, id, body, orderId }) => {
	// type: true means user false means rider
	const params = {
		userId: id,
		title: "New Message",
		to: type ? "user" : "rider",
		body,
		// [type ? "orderId" : "to"]: refId,
	};
	if (type) params["orderId"] = orderId;
	return wrappedFetch(SEND_NOTIFICATION, "POST", {
		...params,
	});
};

export const fetchAdmins = (data) => {
	// page: number
	// pageSize: number
	return wrappedGet(FETCH_SUBADMIN, "GET", data);
};
export const searchAdmins = (data) => {
	// name: string
	// page: number
	// pageSize: number
	return wrappedGet(SEARCH_SUBADMIN, "GET", data);
};
export const statusAdmins = (data) => {
	// status: string (blocked, unblocked)
	// page: number
	// pageSize: number
	return wrappedGet(STATUS_SUBADMIN, "GET", data);
};
export const blockAdmins = (data) => {
	// adminId: string
	// isBlocked: boolean
	return wrappedFetch(BLOCK_SUBADMIN, "POST", data);
};
export const addAdmin = (data) => {
	// email, firstname, lastname
	return wrappedFetch(ADD_SUBADMIN, "POST", data);
};
export const passwordAdmins = (data) => {
	// email, password, otp
	// email & otp to be taken from the url params
	return wrappedFetch(PASSWORD_SUBADMIN, "POST", data);
};

// USERS
export const fetchActive = (data) => {
	return wrappedGet(FETCH_ACTIVE, "GET", data);
};
export const fetchInactive = (data) => {
	return wrappedGet(FETCH_INACTIVE, "GET", data);
};
export const userDetails = (data) => {
	// userId
	return wrappedGet(USER_BY_ID, "GET", data);
};
export const searchUser = (data) => {
	// userStatus (true is inactive), name
	return wrappedGet(SEARCH_USER, "GET", data);
};
