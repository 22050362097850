import { useState, useEffect } from "react";
import Card from "../Card";
import { Button } from "@mui/material";
import styles from "./dashboardcomp.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { getFullName, orderRow } from "../../../utils/functions";
import { statusMap, statusColor } from "../../../utils/constants";
import default_pfp from "../../../assets/default_pfp.png";
import Loading_2 from "../../../assets/Loading_2.gif";

const TableComp = (props) => {
	const { backEndData } = props;
	const [orderData, setOrderData] = useState([]);

	const [loadedImages, setLoadedImages] = useState([]);

	const handleImageLoad = (index) => {
		setLoadedImages((prevLoadedImages) => [...prevLoadedImages, index]);
	};

	useEffect(() => {
		let newTable = [];
		backEndData?.forEach((item, index) => {
			newTable.push(orderRow(item));
		});
		setOrderData(newTable);
	}, []);

	const navigate = useNavigate();

	const labels = [
		"Order id",
		// "Service Type",
		"Items/Weight",
		"Amount",
		"Date Booked",
		"Pick-up Date & Time",
		"Delivery Date & Time",
		"Client name",
		"Contact Number",
		"Assigned Rider",
		"Status",
	];

	return (
		<>
			<Card>
				<div className={styles.tableHeader}>
					<h1>Recent order requests</h1>
					<Button
						type="submit"
						sx={{
							textTransform: "none",
							fontFamily: "Inter Tight, sans-serif",
							fontWeight: "700",
							fontSize: "0.675rem",
							lineHeight: "20px",
							height: "28px",
							width: "81px",
							maxWidth: "100%",
							backgroundColor: "#0993CF",
							borderRadius: "8px",
						}}
						variant="contained"
						onClick={() => {
							navigate("/orders");
						}}
					>
						View all
					</Button>
				</div>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							{labels?.map((label, index) => (
								<TableCell
									key={index}
									sx={{
										border: "1px solid #CCCCCC",
										backgroundColor: "#F2F2F2",
										fontFamily: "Inter Tight",
										fontWeight: 500,
										fontSize: "0.675rem",
										lineHeight: "1.5",
										color: "#848484",
									}}
								>
									{label}
								</TableCell>
							))}
						</TableHead>
						<TableBody>
							{orderData?.map((row, index) => (
								<TableRow
									key={index}
									hover={true}
									sx={{
										cursor: "pointer",
										".MuiTableCell-root": {
											fontFamily: "Inter Tight",
											fontWeight: 400,
											fontSize: "0.675rem",
											lineHeight: "1.5",
											color: "#1C1C1C",
											border: "1px solid #F2F2F2",
										},
									}}
									onClick={() => {
										navigate(`/orders/order_detail/${row.orderID}`);
									}}
								>
									<TableCell sx={{ width: "12%" }}>{row.orderID}</TableCell>
									{/* <TableCell>
										{row.serviceType === "laundry" ? "Laundry" : "Dry Cleaning"}
									</TableCell> */}
									<TableCell>{row.itemsWeight}</TableCell>
									<TableCell>{row.amount}</TableCell>
									<TableCell>{row.dateBooked}</TableCell>
									<TableCell>{row.pickUpDate}</TableCell>
									<TableCell>{row.deliveryDate}</TableCell>
									<TableCell>{row.client}</TableCell>
									<TableCell>{row.contact}</TableCell>
									<TableCell>
										{typeof row.assignRider === "object" ? (
											<div className={styles.rider}>
												<div className={styles.riderImage}>
													<img
														style={{
															borderRadius: "50%",
															width: "100%",
															height: "100%",
															objectFit: "cover",
														}}
														src={
															loadedImages.includes(index)
																? row.assignRider.profileImage
																	? row.assignRider.profileImage
																	: default_pfp
																: Loading_2
														}
														alt="Rider Profile Picture"
														onLoad={() => {
															handleImageLoad(index);
														}}
													/>
												</div>
												{getFullName(row.assignRider)}
											</div>
										) : (
											<div
												style={{
													color: row.assignRider ? "#0993cf" : "#848484",
													textDecoration: row.assignRider
														? "underline"
														: "none",
												}}
											>
												{row.orderStatus === 11
													? "Deleted Rider"
													: "Assign Rider"}
											</div>
										)}
									</TableCell>
									<TableCell>
										<span style={{ color: `${statusColor[row.orderStatus]}` }}>
											{statusMap[row.orderStatus]}
										</span>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Card>
		</>
	);
};
export default TableComp;
