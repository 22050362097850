import { useState, useEffect } from "react";
import styles from "./riders.module.css";
import Loader from "../../components/ui/Loader/Loader";
import green_check from "../../assets/check_circle_green.png";
import {
	getAllRider,
	getRiderByStatus,
	searchRider,
	blockedRider,
} from "../../utils/api/utilAPI";
import default_pfp from "../../assets/default_pfp.png";
import Loading_2 from "../../assets/Loading_2.gif";
import { addressPrinter, getFullName } from "../../utils/functions";

const Riders = () => {
	const [isLoading, setIsLoading] = useState(true);

	const [riderArr, setRiderArr] = useState([]);

	const [status, setStatus] = useState("");
	const [search, setSearch] = useState("");

	const [loadedImages, setLoadedImages] = useState([]);

	const handleImageLoad = (index) => {
		setLoadedImages((prevLoadedImages) => [...prevLoadedImages, index]);
	};

	useEffect(() => {
		fetchRiders();
	}, []);

	const fetchRiders = () => {
		setIsLoading(true);
		getAllRider()
			.then((res) => {
				setRiderArr(res?.data?.data);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const fetchByStatus = (statusVal) => {
		setIsLoading(true);
		getRiderByStatus({ status: statusVal })
			.then((res) => {
				setRiderArr(res?.data?.data?.riderData);
			})
			.finally(() => {
				setSearch("");
				setIsLoading(false);
			});
	};

	const handleStatusChange = (value) => {
		setStatus(value);
		if (value !== "") fetchByStatus(value);
	};

	const handleChange = () => {
		setIsLoading(true);
		if (search.length === 0) fetchRiders();
		else {
			searchRider({ riderName: search })
				.then((res) => {
					setRiderArr(res?.data?.data);
				})
				.finally(() => {
					setStatus("");
					setIsLoading(false);
				});
		}
	};

	const blockHandle = (id, blockBool) => {
		setStatus("");
		setIsLoading(true);
		if (id) {
			blockedRider({ riderId: id, isBlocked: blockBool }).finally(() => {
				fetchRiders();
			});
		}
	};

	return isLoading ? (
		<div
			style={{
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div style={{ height: "10%", aspectRatio: "1" }}>
				<Loader />
			</div>
		</div>
	) : (
		<div className={styles.fullPage}>
			<div className={styles.header}>
				<div>Riders</div>
				<input
					placeholder="Search Rider by Name"
					className={styles.riderInput}
					type="text"
					value={search}
					onKeyUp={(e) => {
						if (e.key === "Enter") {
							handleChange();
						}
					}}
					onChange={(e) => {
						setSearch(e.target.value);
					}}
				/>
				<button className={styles.searchButton} onClick={handleChange}>
					Search
				</button>
				<div style={{ display: "flex", justifyContent: "right" }}>
					<select
						value={status}
						className={styles.selectContainer}
						onChange={(e) => handleStatusChange(e.target.value)}
					>
						<option value={"all"}>Status: All</option>
						<option value={"blocked"}>Status: Blocked</option>
					</select>
				</div>
			</div>
			<div className={styles.body}>
				{riderArr.length > 0 ? (
					riderArr.map((rider, index) => {
						if (
							(status !== "blocked" && !rider?.isBlocked) ||
							(status === "blocked" && rider?.isBlocked)
						) {
							return (
								<div key={index} className={styles.card}>
									<div className={styles.imageDetail}>
										<div
											style={{
												width: "7.5rem",
												height: "7.5rem",
												flexShrink: "0",
											}}
										>
											<img
												src={
													loadedImages.includes(index)
														? rider?.profileImage
															? rider?.profileImage
															: default_pfp
														: Loading_2
												}
												alt=""
												style={{
													width: "100%",
													height: "100%",
													objectFit: "cover", // Maintain aspect ratio and cover the container
													borderRadius: "50%",
												}}
												onLoad={() => handleImageLoad(index)}
											/>
										</div>
										<div className={styles.nameNumber}>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													paddingBottom: "1rem",
													gap: "0.25rem",
													alignSelf: "stretch",
												}}
											>
												<span className={styles.subHeading}>Name</span>
												<div className={styles.altValue}>
													<div>
														<span className={styles.subHeadingValue}>
															{getFullName(rider)}
														</span>
													</div>
													<div className={styles.verifiedBlock}>
														<div
															style={{
																display: "flex",
																alignItems: "center",
																gap: "0.2rem",
																color: "#848484",
															}}
														>
															{rider?.isVerifiedRider ? (
																<>
																	<img
																		className={styles.greenCheck}
																		src={green_check}
																		alt="green_check"
																	/>
																	Verified
																</>
															) : null}
														</div>
														<div
															style={{
																cursor: "pointer",
																textDecoration: "underline",
																color: `${
																	rider.isBlocked ? "#51B62E" : "#E73F3F"
																}`,
															}}
															onClick={() =>
																blockHandle(rider._id, !rider.isBlocked)
															}
														>
															{rider?.isBlocked ? "Unblock" : "Block"}
														</div>
													</div>
												</div>
											</div>
											<div className={styles.numberStyle}>
												<span className={styles.subHeading}>Mobile Number</span>
												<span className={styles.subHeadingValue}>
													{rider?.phone.mobileNumber}
												</span>
											</div>
										</div>
									</div>
									<div style={{ display: "flex", flexDirection: "column" }}>
										<div className={styles.otherDetail}>
											<span className={styles.subHeading}>Email Address</span>
											<span className={styles.subHeadingValue}>
												{rider?.email.mail}
											</span>
										</div>
										<div className={styles.otherDetail}>
											<span className={styles.subHeading}>
												Years of Experience
											</span>
											<span className={styles.subHeadingValue}>
												{rider?.yearOfExperience}
											</span>
										</div>
										<div className={styles.otherDetail}>
											<span className={styles.subHeading}>Address</span>
											<span className={styles.subHeadingValue}>
												{addressPrinter(rider?.address)}
											</span>
										</div>
									</div>
								</div>
							);
						}
					})
				) : (
					<>No record found...</>
				)}
			</div>
		</div>
	);
};
export default Riders;
