import { wrappedFetch, wrappedGet } from "./urlFuncs";
import {
	LOGIN_URL,
	LOGOUT_URL,
	FORGET_PASSWORD,
	VERIFY_OTP,
	RESET_PASSWORD_URL,
	ENABLE_NOTIFICATION,
} from "./urlConstants";

export const loginUser = (data) => {
	return wrappedFetch(LOGIN_URL, "POST", data);
};

export const forgotPassword = (data) => {
	return wrappedFetch(FORGET_PASSWORD, "POST", data);
};

export const resetPassword = (data) => {
	//token
	//password
	return wrappedFetch(RESET_PASSWORD_URL, "POST", { ...data });
};

export const verify_otp = (data) => {
	return wrappedFetch(VERIFY_OTP, "POST", data);
};

export const logout = () => {
	return wrappedGet(LOGOUT_URL, "GET");
};

export const enableNotification = (data) => {
	return wrappedFetch(ENABLE_NOTIFICATION, "POST", data);
};
