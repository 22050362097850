import styles from "./welcome.module.css";
import WelcomeTemplate from "./WelcomeTemplate";
import { useState, useEffect } from "react";
import { Button, Box } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../utils/api/authAPI";
import { LANDING_PATH } from "../../utils/constants";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast, ToastContainer } from "react-toastify";

// will receive token in the url
// send it and the user email (received from state on navigate())

const Reset = () => {
	const navigate = useNavigate();

	const queryParameters = new URLSearchParams(window.location.search);
	const tokenSearch = queryParameters.get("token");

	useEffect(() => {
		if (tokenSearch) setEditable(true);
	}, []);

	const [firstPass, setFirstPass] = useState("");
	const [showFirst, setShowFirst] = useState(false);

	const [secondPass, setSecondPass] = useState("");
	const [showSecond, setShowSecond] = useState(false);

	const [isError, setIsError] = useState(false);

	const [editable, setEditable] = useState(false);

	const handleSubmit = () => {
		if (firstPass !== "" && firstPass === secondPass) {
			resetPassword({
				token: tokenSearch,
				password: firstPass,
			}).then((res) => {
				toast.info("Password has been reset successfully!", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				setTimeout(() => {
					navigate(`/`);
				}, 5500);
			});
		} else {
			setIsError(true);
			setTimeout(() => {
				setIsError(false);
			}, 3000);
		}
	};

	return (
		<WelcomeTemplate
			inputComp={
				<div className={styles.resetDiv}>
					<div className={`${styles.welcome} ${styles.resetTitle}`}>
						Reset password
					</div>
					<div className={styles.resetMessage}>
						Enter your new password to get sign in to your account.
					</div>
					<form onSubmit={handleSubmit} className={styles.formMain}>
						<Box
							sx={{
								height: "3rem",
								width: "100%",
								maxWidth: "100%",
								marginBottom: "1rem",
							}}
							className={isError ? styles.shake : ""}
						>
							<OutlinedInput
								sx={{
									borderRadius: "8px",
								}}
								id="firstpassword"
								type={showFirst ? "text" : "password"}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => setShowFirst((curr) => !curr)}
											onMouseDown={(e) => e.preventDefault()}
											edge="end"
										>
											{showFirst ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
								fullWidth
								placeholder="New Password"
								value={firstPass}
								onChange={
									editable
										? (e) => {
												setFirstPass(e.target.value);
										  }
										: null
								}
								onCopy={(e) => {
									e.preventDefault();
								}}
								onPaste={(e) => {
									e.preventDefault();
								}}
							/>
						</Box>
						<Box
							sx={{
								height: "3rem",
								width: "100%",
								maxWidth: "100%",
								marginBottom: "2rem",
							}}
							className={isError ? styles.shake : ""}
						>
							<OutlinedInput
								sx={{
									borderRadius: "8px",
								}}
								id="secondpassword"
								type={showSecond ? "text" : "password"}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => setShowSecond((curr) => !curr)}
											onMouseDown={(e) => e.preventDefault()}
											edge="end"
										>
											{showSecond ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
								fullWidth
								placeholder="Confirm new password"
								value={secondPass}
								onChange={
									editable
										? (e) => {
												setSecondPass(e.target.value);
										  }
										: null
								}
								onCopy={(e) => {
									e.preventDefault();
								}}
								onPaste={(e) => {
									e.preventDefault();
								}}
							/>
						</Box>
						<Link to={LANDING_PATH}>
							<div className={styles.forgotPassword}>Login</div>
						</Link>
						<Button
							sx={{
								height: "3rem",
								width: "100%",
								maxWidth: "100%",
								backgroundColor: "#0993CF",
								marginTop: "5%",
								textTransform: "none",
								fontFamily: "Inter Tight, sans-serif",
								fontWeight: "700",
								lineHeight: "24px",
								borderRadius: "8px",
							}}
							variant="contained"
							onClick={handleSubmit}
						>
							Save password
						</Button>
					</form>
				</div>
			}
		/>
	);
};
export default Reset;
