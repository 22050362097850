import moment from "moment";

export function randomNumber(min, max) {
	return Math.random() * (max - min) + min;
}

export function getFullName(user) {
	if (!user) return "Deleted User";
	if (user.firstname) return `${user.firstname} ${user.lastname}`;
	if (user.firstName) return `${user.firstName} ${user.lastName}`;
}

export function isStrongPassword(password) {
	const regex =
		/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]:;<>,.?\/\\]).{8,36}$/;
	return regex.test(password);
}

export const addressPrinter = (data) => {
	// Check if data is not an object or is null
	if (typeof data !== "object" || data === null) {
		return ""; // Return an empty string if data is not valid
	}

	// Concatenate address lines, removing any falsy values (null, undefined, empty strings)
	const addressLine = [data.addressLine1, data.addressLine2]
		.filter(Boolean)
		.join(" ");

	// Exclude addressLine1 from address if it's identical to address
	const addressComponents = [
		data.address !== data.addressLine1 ? data.address : null, // Exclude addressLine1 if it's identical to address
		data.city,
		data.state,
		data.country,
		data.zipCode,
	];

	// Concatenate address components, removing any falsy values (null, undefined, empty strings)
	const address = addressComponents.filter(Boolean).join(", ");

	// Concatenate address line and address, trimming and adding a comma if necessary
	const fullAddress =
		addressLine.trim() + (addressLine ? ", " : "") + address.trim();

	return fullAddress; // Return the formatted full address
};

export const orderRow = (obj) => {
	return {
		orderID: obj.orderId,
		serviceType: obj.serviceType,
		itemsWeight:
			(obj.serviceType === "dryCleaning" && obj.orderStatus < 2) ||
			(obj.serviceType === "laundry" &&
				(obj.orderStatus < 6 || !obj.orderWeight))
				? "Pending"
				: obj.serviceType === "laundry"
				? `${obj.orderWeight} ${obj.orderWeight === 1 ? "Pound" : "Pounds"}`
				: `${obj.orderItemCount} ${
						obj.orderItemCount === 1 ? "item" : "items"
				  }`,
		subTotal: obj.orderAmount.subTotal,
		riderTip: obj.orderAmount.riderTip,
		tax: obj.orderAmount.tax,
		amount: ((amt) => {
			if (!amt) return "Pending";
			const subTotal = amt.subTotal ? amt.subTotal : 0;
			const tax = amt.tax ? amt.tax : 0;
			const riderTip = amt.riderTip ? amt.riderTip : 0;
			if (
				(obj.serviceType === "dryCleaning" && obj.orderStatus < 2) ||
				(obj.serviceType === "laundry" &&
					(obj.orderStatus < 6 || !obj.orderWeight))
			) {
				return "Pending";
			} else return `$${(subTotal + tax + riderTip).toFixed(2)}`;
		})(obj.orderAmount),
		orderItems: obj.orderItems,
		dateBooked: moment(obj.createdAt).format("ll"),
		pickUpDate: obj.pickUpDateAndTime
			? moment(obj.pickUpDateAndTime).format("MMMM DD, YYYY, hh:mm A")
			: "Pending",
		deliveryDate: obj.deliveryDateAndTime
			? moment(obj.deliveryDateAndTime).format("MMMM DD, YYYY, hh:mm A")
			: "Pending",
		client: getFullName(obj.user),
		// for chat creation
		clientObj: {
			email: obj.user?.email?.mail,
			fullName: getFullName(obj.user),
			_id: obj.user?._id,
		},
		contact: obj.orderDeliveryAddress
			? `+${obj.orderDeliveryAddress.mobileNumber}`
			: obj.user
			? `+${obj.user.phone.mobileNumber}`
			: "Deleted Contact",
		assignRider: obj.hasOwnProperty("assignRider")
			? obj.assignRider // show the assigned rider
			: obj.orderStatus === 1 || obj.orderStatus === 2 || obj.orderStatus === 8
			? true
			: false,
		orderStatus: obj.orderStatus,
		email: obj.user?.email?.mail,
		orderRating: obj.orderRating,
		orderDeliveryAddress: obj.orderDeliveryAddress,
		createdAt: obj.createdAt,
	};
};

export const printUpdateValue = (type, val) => {
	if (type === "address") return addressPrinter(val.address);
	else if (type === "name") return `${val.firstname} ${val.lastname}`;
	else if (type === "isVerifiedRider")
		return val.isVerifiedRider ? "Verified" : "Not Verified";
	else return val[type];
};

// image cropper ------------------------------------------------------------------------------------------------------------------------------

export const readFile = (file) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
};

export const createImage = (url) =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener("load", () => resolve(image));
		image.addEventListener("error", (error) => reject(error));
		image.setAttribute("crossOrigin", "anonymous");
		image.src = url;
	});

export function getRadianAngle(degreeValue) {
	return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
	const rotRad = getRadianAngle(rotation);

	return {
		width:
			Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
		height:
			Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
	};
}

export const getCroppedImg = async (
	imageSrc,
	pixelCrop = { x: 0, y: 0 },
	rotation = 0,
	flip = { horizontal: false, vertical: false }
) => {
	const image = await createImage(imageSrc);
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");

	if (!ctx) {
		return null;
	}

	const rotRad = getRadianAngle(rotation);

	// calculate bounding box of the rotated image
	const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
		image.width,
		image.height,
		rotation
	);

	// set canvas size to match the bounding box
	canvas.width = bBoxWidth;
	canvas.height = bBoxHeight;

	// translate canvas context to a central location to allow rotating and flipping around the center
	ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
	ctx.rotate(rotRad);
	ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
	ctx.translate(-image.width / 2, -image.height / 2);

	// draw rotated image
	ctx.drawImage(image, 0, 0);

	// croppedAreaPixels values are bounding box relative
	// extract the cropped image using these values
	const data = ctx.getImageData(
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height
	);

	// set canvas width to final desired crop size - this will clear existing context
	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;

	// paste generated rotate image at the top left corner
	ctx.putImageData(data, 0, 0);

	// As Base64 string
	// return canvas.toDataURL('image/jpeg');

	// As a blob
	return new Promise((resolve) => {
		canvas.toBlob((file) => {
			resolve({ file, url: URL.createObjectURL(file) });
		}, "image/jpeg");
	});
};

// image cropper ==============================================================================================================================

export const getTimeSince = (dateValue) => {
	const currentDate = new Date();
	const diffTime = Math.abs(currentDate - dateValue);
	const diffSeconds = Math.ceil(diffTime / 1000);
	const diffMinutes = Math.ceil(diffTime / (1000 * 60));
	const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
	if (diffSeconds < 60) {
		return "Less than a minute ago";
	} else if (diffMinutes < 60) {
		return `${diffMinutes} ${diffMinutes === 1 ? "minute" : "minutes"} ago`;
	} else if (diffHours < 24) {
		return `${diffHours} ${diffHours === 1 ? "hour" : "hours"} ago`;
	}
	return moment(dateValue).format("L");
};

export function prepareFormData(fullName, pfp) {
	const formData = new FormData();
	formData.append("fullname", fullName);
	formData.append("firstname", fullName.split(" ")[0]);
	formData.append("lastname", fullName.split(" ").pop());
	formData.append("profileImage", pfp);

	return formData;
}

export const isValidName = (str) => {
	// Regular expression to match first name followed by a space and then last name
	const regex = /^\w+\s\w+$/;
	return regex.test(str);
};

export const removeExtraSpaces = (str) => {
	return str.replace(/\s+$/, "");
};

export const isValidNumber = (input) => {
	// Regular expression to match numeric input with up to 2 significant digits
	const regex = /^[0-9]+(?:\.[0-9]{1,2})?$/;
	return regex.test(input);
};

export const printName = (input) => {
	let words = input.split(" ");

	for (let i = 0; i < words.length; i++) {
		let word = words[i];

		words[i] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	}

	return words.join(" ");
};
