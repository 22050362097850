import { createSlice, nanoid } from "@reduxjs/toolkit";
// recognize state and action as parameters of the reducers
const userObject = JSON.parse(localStorage.getItem("userData"));

const authSlice = createSlice({
	name: "auth",
	initialState: {
		user: userObject,
		loading: false,
		error: null,
	},
	reducers: {
		loginRequest: (state) => {
			state.loading = true;
			state.error = null;
		},
		loginSuccess: (state, action) => {
			let { email, accessToken, firstname, lastname, profileImage, userType } =
				action.payload;
			state.user = {
				email: email.mail,
				emailVerified: email.isVerified,
				accessToken,
				firstname,
				lastname,
				profileImage,
				userType,
			};
			state.loading = false;
			state.error = null;
		},

		updateProfileData: (state, action) => {
			state.user = action.payload;
		},
		sessionTimeout: (state, action) => {
			state.user = null;
		},
		logoutSuccess: (state) => {
			state.user = {
				accessToken: null,
			};
			state.loading = false;
			state.error = null;
		},
	},
});

export const {
	loginRequest,
	loginSuccess,
	updateProfileData,
	sessionTimeout,
	logoutSuccess,
} = authSlice.actions;

export default authSlice.reducer; // THIS IS THE DEFAULT EXPORT!
