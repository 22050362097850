import classes from "./Navbar.module.css";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InventoryIcon from "@mui/icons-material/Inventory";
import WashIcon from "@mui/icons-material/Wash";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import UpdateIcon from "@mui/icons-material/Update";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import STARClean from "../../assets/STARCleanLogo2.png";
import styles from "./Navbar.module.css";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PersonIcon from "@mui/icons-material/Person";

const Navbar = () => {
	const pageSelector = useSelector((state) => state.pageReducer.pageSize);
	const selector = useSelector((state) => state.authReducer.user);
	const testmenuItems = [
		{
			name: "Dashboard",
			path: "/dashboard",
			icon: <DashboardIcon sx={{ fontSize: pageSelector ? 25 : 15 }} />,
		},
		{
			name: "Orders",
			path: "/orders",
			icon: <InventoryIcon sx={{ fontSize: pageSelector ? 25 : 15 }} />,
		},
		{
			name: "Service Management",
			path: "/service",
			icon: <WashIcon sx={{ fontSize: pageSelector ? 25 : 15 }} />,
		},
		{
			name: "Riders",
			path: "/riders",
			icon: <DeliveryDiningIcon sx={{ fontSize: pageSelector ? 25 : 15 }} />,
		},
		{
			name: "Chat",
			path: "/chat",
			icon: <ChatBubbleIcon sx={{ fontSize: pageSelector ? 25 : 15 }} />,
		},
		{
			name: "Update Requests",
			path: "/update",
			icon: <UpdateIcon sx={{ fontSize: pageSelector ? 25 : 15 }} />,
		},
		selector?.userType === "superAdmin" && {
			name: "Admin Management",
			path: "/admin",
			icon: <SupervisorAccountIcon sx={{ fontSize: pageSelector ? 25 : 15 }} />,
		},
		{
			name: "User Management",
			path: "/users",
			icon: <PersonIcon sx={{ fontSize: pageSelector ? 25 : 15 }} />,
		},
	];
	const permittedMenu = testmenuItems.filter((item) => Boolean(item));

	const activeFunc = (match, location) => {
		// Check if the current path starts with the item's path
		return location.pathname.startsWith(match.path);
	};

	return (
		<div
			style={{ display: "flex", flexDirection: "column", overflow: "auto" }}
			className={styles.sidebar}
		>
			<div
				style={{ display: "flex", flexDirection: "row" }}
				className={styles.company_logo}
			>
				<img src={STARClean} alt="Company Logo" />
			</div>
			<div>
				<ul
					style={{ display: "flex", flexDirection: "column" }}
					className="flex-col"
				>
					{permittedMenu.map((item) => (
						<NavLink
							key={item?.name}
							to={`${item.path}`}
							isActive={(match, location) => activeFunc(match, location)}
							className={({ isActive }) => (isActive ? classes.active : "")}
						>
							<div className={styles.menu_item}>
								<li className={styles.menu_list}>
									<div className={styles.icon}>{item.icon}</div>
									<span className={styles.text}>{item.name}</span>
								</li>
							</div>
						</NavLink>
					))}
				</ul>
			</div>
		</div>
	);
};
export default Navbar;
